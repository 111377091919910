import React, { useState } from "react";
import AlertDialog, { IAlertDialogProps } from "../../shared/components/CommonDialog/AlertDialog";
import { getProducts } from "../helpers/apiHelper";
import { ConstrainMode, DetailsList, IColumn, SelectionMode, Spinner } from "@fluentui/react";
import classNames from "./App.module.scss";
import { DataForm, FieldType } from "../../shared/components/DataForm";

export interface IProductLookupDialogProps extends IAlertDialogProps {}

export const ProductLookupDialog = (props: IProductLookupDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<any[]>();
  const [error, setError] = useState<string>();
  const [filters, setFilters] = useState<object>({});

  const onFieldValueChange = (fieldName: string, newValue: any) => {
    let newFilters = { ...filters };

    if (newValue === undefined || newValue.trim() === "") {
      delete newFilters[fieldName];
    } else {
      newFilters[fieldName] = newValue;
    }

    setFilters(newFilters);
  };

  const onSearchClick = () => {
    setError(undefined);
    setProducts(undefined);
    setLoading(true);

    getProducts({ ...filters, requestedRows: 10000 })
      .then((result: any) => setProducts(result?.products))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  return (
    <AlertDialog {...props} headerText="Product Lookup" hidden={false} minWidth={"80vw"}>
      <div className={classNames.searchForm}>
        <DataForm fields={getSearchFields(onSearchClick)} onFieldValueChange={onFieldValueChange} />
      </div>
      {loading && <Spinner className={classNames.spinner} label="Loading data..." />}
      {!loading && error && <div className={classNames.error}>{error}</div>}
      {!loading && !error && !!products?.length && (
        <div className={classNames.listPane} data-is-scrollable="true">
          <DetailsList
            className={classNames.productList}
            compact={true}
            columns={columns}
            items={products}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained}
          />
        </div>
      )}
      {!loading && !error && !products?.length && <div className={classNames.infoText}>No product is found.</div>}
    </AlertDialog>
  );
};

export default ProductLookupDialog;

const getSearchFields = (onSearchClick) => [
  {
    fieldName: "partNumber",
    label: "Part Number",
    width: 160,
  },
  {
    fieldName: "upc",
    label: "UPC",
    width: 160,
  },
  {
    fieldName: "itemName",
    label: "Description",
    width: 300,
  },
  {
    fieldName: "search",
    fieldType: FieldType.button,
    value: "Search",
    onClick: onSearchClick,
    fieldProps: {
      primary: true,
    },
  },
];

const columns: IColumn[] = [
  { key: "PartNumber", fieldName: "PartNumber", name: "Part Number", minWidth: 120, maxWidth: 140, isResizable: true },
  {
    key: "UniversalProductCode",
    fieldName: "UniversalProductCode",
    name: "UPC",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },
  { key: "ItemName", fieldName: "ItemName", name: "Name", minWidth: 200, isResizable: true },
  {
    key: "ProductFamilySKID",
    fieldName: "ProductFamilySKID",
    name: "Family",
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
  },
  {
    key: "LicenseTypeCodeSKID",
    fieldName: "LicenseTypeCodeSKID",
    name: "License Type",
    minWidth: 80,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "ProductLanguageCodeSKID",
    fieldName: "ProductLanguageCodeSKID",
    name: "Language",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
  },
  {
    key: "ProductDistTypeCodeSKID",
    fieldName: "ProductDistTypeCodeSKID",
    name: "Distribution Type",
    minWidth: 100,
    maxWidth: 140,
    isResizable: true,
  },
  { key: "VersionName", fieldName: "VersionName", name: "Version", minWidth: 60, maxWidth: 100, isResizable: true },
  {
    key: "USBasePrice",
    fieldName: "USBasePrice",
    name: "US Base Price",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  { key: "JANCode", fieldName: "JANCode", name: "JAN Code", minWidth: 80, maxWidth: 140, isResizable: true },
];
