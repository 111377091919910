import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ErrorCorrectionDialog, { IErrorCorrectionDialogProps } from "./ErrorCorrectionDialog";
import classNames from "./ErrorCorrection.module.scss";
import { DataForm } from "../../../shared/components/DataForm";
import {
  DetailsList,
  PrimaryButton,
  Spinner,
  SelectionMode,
  ConstrainMode,
  Selection,
  SearchBox,
} from "@fluentui/react";
import { AppContext } from "../../app/App";
import { getProducts } from "../../helpers/apiHelper";
import { formatNumber } from "../../../shared/helpers/miscHelper";
import { getDataColumns, getFilteredItems, getProductTranslationTypeField } from "./ErrorCorrection.helper";
import { IApiError } from "../../../shared/helpers/apiHelper";

const targetFieldName = "PartNumber";

export enum ProductTranslationType {
  SKU = 2,
  ProductID = 1,
  UPC = 3,
}

export interface IProducts {
  products: any[];
  totalCount: any[];
}

export interface IProductErrorDialogProps extends IErrorCorrectionDialogProps {
  onValueSelect: (values: object) => void;
}

export const ProductErrorDialog = (props: IProductErrorDialogProps) => {
  const { selectedErrorRecord, onValueSelect, onDismiss } = props;
  const [filters, setFilters] = useState<object>({});
  const [productsData, setProductsData] = useState<IProducts>();
  const [loadingProducts, setLoadingProducts] = useState<boolean>();
  const [error, setError] = useState<IApiError>();
  const [selectedProduct, setSelectedProduct] = useState<object>();
  const [searchText, setSearchText] = useState<string>();
  const [selectedProductTranslationId, setSelectedProductTranslationId] = useState<ProductTranslationType>(
    ProductTranslationType.SKU
  );
  const { domains } = useContext(AppContext).appState;

  const columns = useMemo(() => getDataColumns(productsData?.products, [targetFieldName]), [productsData]);

  const productsCount = productsData?.totalCount[0]?.TotalCount;
  const returnedCount = productsData?.products?.length;

  const filteredProducts = useMemo(
    () => getFilteredItems(productsData?.products, searchText, columns, null, domains),
    [productsData, searchText, columns, domains]
  );

  const rowSelection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          setSelectedProduct(rowSelection.getSelection()[0]);
        },
      }),
    []
  );

  useEffect(() => {
    setError(undefined);
    setProductsData(undefined);
    setSelectedProduct(undefined);
    setFilters({});
    rowSelection.setAllSelected(false);
  }, [selectedErrorRecord, rowSelection]);

  const onFieldValueChange = (fieldName: string, newValue: any) => {
    let newFilters = { ...filters };

    if (newValue === undefined || newValue.trim() === "") {
      delete newFilters[fieldName];
    } else {
      newFilters[fieldName] = newValue;
    }

    setFilters(newFilters);
  };

  const onSearchButtonClick = () => {
    setError(undefined);
    setProductsData(undefined);
    setSelectedProduct(undefined);
    rowSelection.setAllSelected(false);
    setLoadingProducts(true);
    getProducts({ ...filters, requestedRows: 10000 })
      .then((result: any) => setProductsData(result))
      .catch((error) => setError(error))
      .finally(() => setLoadingProducts(false));
  };

  const onSelectButtonClick = () => {
    onValueSelect({
      targetFieldName: "MatchedPartNumber",
      sourceOrgId: selectedErrorRecord["SourceOrgID"],
      originalMatchedPartNumber: selectedErrorRecord["MatchedPartNumber"],
      MatchedPartNumber: selectedProduct[targetFieldName],
      ProductTranslationTypeID: selectedProductTranslationId,
    });
    onDismiss();
  };

  const onSearchBoxChange = (event?: any, newValue?: string) => {
    setSearchText(newValue);
  };

  const onRecordFieldValueChange = useCallback((fieldName: string, newValue: any) => {
    if (fieldName === "ProductTranslationTypeID") {
      setSelectedProductTranslationId(newValue);
    }
  }, []);

  return (
    <ErrorCorrectionDialog
      {...props}
      additionalFields={[getProductTranslationTypeField(selectedProductTranslationId)]}
      isValueSelect={!!selectedProduct}
      onSelect={onSelectButtonClick}
      onRecordFieldValueChange={onRecordFieldValueChange}
    >
      <div className={classNames.searchPane}>
        <div className={classNames.searchFieldsPane}>
          <DataForm fields={searchFields} onFieldValueChange={onFieldValueChange} />
          <PrimaryButton className={classNames.searchButton} text="Search" onClick={onSearchButtonClick} />
        </div>
        {!loadingProducts && productsData && !!productsData.totalCount?.length && (
          <div className={classNames.searchInfoPane}>
            <div className={classNames.productsCount}>
              Products Count: <b>{formatNumber(productsCount)}</b>
            </div>
            {productsCount > returnedCount && (
              <div className={classNames.returnedCount}>
                Products Returned: <b>{formatNumber(returnedCount)}</b>
              </div>
            )}
            {!loadingProducts && productsData && productsData.products?.length > 0 && (
              <SearchBox
                className={classNames.searchBox}
                value={searchText}
                onChange={onSearchBoxChange}
                placeholder="Search products below"
              />
            )}
          </div>
        )}
      </div>
      <div className={classNames.searchResultPane} data-is-scrollable="true">
        {loadingProducts && <Spinner label="Loading products..." className={classNames.spinner} />}
        {!loadingProducts && productsData && productsData.products?.length <= 0 && (
          <div className={classNames.noProducts}>No product is found.</div>
        )}
        {!loadingProducts && error && <div className={classNames.error}>{error.message}</div>}
        {!loadingProducts && productsData && productsData.products?.length > 0 && (
          <DetailsList
            items={filteredProducts}
            columns={columns}
            selection={rowSelection}
            compact
            selectionMode={SelectionMode.single}
            constrainMode={ConstrainMode.unconstrained}
          />
        )}
      </div>
    </ErrorCorrectionDialog>
  );
};

export default ProductErrorDialog;

const searchFields = [
  {
    fieldName: "partNumber",
    label: "SKU",
    width: 160,
  },
  {
    fieldName: "upc",
    label: "UPC",
    width: 160,
  },
];
