import {
  ConstrainMode,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IDialogProps,
  PrimaryButton,
  Selection,
  SelectionMode,
} from "@fluentui/react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { AppContext } from "../app/App";
import classNames from "./TemplateSelectorDialog.module.scss";

export interface ITemplateSelectorDialogProps extends IDialogProps {
  onTemplateSelect: (transmissionTypeId: number, templateId: number) => void;
}

export const TemplateSelectorDialog = (props: ITemplateSelectorDialogProps) => {
  const { onTemplateSelect, onDismiss } = props;
  const [selectedTemplate, setSelectedTemplate] = useState<object>();
  const [selectedTransmissionTypeId, setSelectedTransmissionTypeId] = useState<number>();
  const { domains } = useContext(AppContext).appState;

  const transmissionTypes = useMemo(
    () => domains?.transmissionTypes?.map((t) => ({ key: t["TransmissionTypeID"], text: t["TransmissionTypeCode"] })),
    [domains]
  );

  const templates = useMemo(() => {
    let result = domains?.fileTemplates
      ?.filter((t) => t["TransmissionTypeID"] === selectedTransmissionTypeId)
      ?.map((t) => ({ key: t["FileTemplateID"], text: t["Description"] }));

    result.unshift({ key: "", text: "<None>" });

    return result;
  }, [domains, selectedTransmissionTypeId]);

  const rowSelection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          setSelectedTemplate(rowSelection.getSelection()[0]);
        },
      }),
    []
  );

  const onTransmissionTypeChange = useCallback((ev, option) => {
    setSelectedTemplate(undefined);
    setSelectedTransmissionTypeId(option.key);
  }, []);

  const onOkButtonClick = useCallback(() => {
    onTemplateSelect(selectedTransmissionTypeId, selectedTemplate["key"]);
    onDismiss();
  }, [selectedTransmissionTypeId, selectedTemplate, onTemplateSelect, onDismiss]);

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: "Copy From Template",
        closeButtonAriaLabel: "Close",
      }}
      minWidth={800}
      modalProps={{ isBlocking: true }}
      {...props}
    >
      <Dropdown
        options={transmissionTypes || []}
        label="Transmission Type"
        onChange={onTransmissionTypeChange}
        className={classNames.transmissionTypeDropdown}
      />
      <div className={classNames.resultPane} data-is-scrollable="true">
        <DetailsList
          items={templates || []}
          columns={columns}
          selection={rowSelection}
          compact
          selectionMode={SelectionMode.single}
          constrainMode={ConstrainMode.unconstrained}
        />
      </div>

      <DialogFooter>
        <PrimaryButton onClick={onOkButtonClick} text="Ok" disabled={!selectedTemplate} />
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default TemplateSelectorDialog;

const columns: IColumn[] = [
  {
    key: "text",
    fieldName: "text",
    name: "Template",
    minWidth: 200,
  },
];
