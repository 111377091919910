import { PrimaryButton } from "@fluentui/react";
import { useState } from "react";
import classNames from "./MDCSearch.module.scss";
import { SearchBox } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { DateRangePicker } from "../../shared/components/DateRangePicker/DateRangePicker";
import { getIsoDateString } from "../../shared/helpers/miscHelper";
import { Icon } from "@fluentui/react";
import filterClassNames from "../../shared/styles/FilterInfo.module.scss";

export interface IAppSearchProps {
  onSearchChange: (filterItems: {}) => void;
}

export const MDCSearch = (props: IAppSearchProps) => {
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [filterData, setFilterData] = useState({});
  const [filterMap, setFilterMap] = useState(new Map());
  const [searchBoxCleared, setSearchBoxCleared] = useState(false);
  const { onSearchChange } = props;
  const searchBoxId = useId("searchBox");

  const onSearchButtonClick = () => {
    var split = searchText.trim().split(/\s+/);
    var filterValuePairs = split.map((pair) => pair.split(":"));
    Object.keys(filterValuePairs).forEach((idx) => {
      filterMap.set(filterValuePairs[idx][0], filterValuePairs[idx][1]);
    });
    filterMap.set("sd", getIsoDateString(startDate));
    filterMap.set("ed", getIsoDateString(endDate));
    var result = JSON.parse(JSON.stringify(Object.fromEntries(filterMap)));
    onSearchChange(result);
    setFilterData(result);
    setStartDate(null);
    setEndDate(null);
  };

  const onSearchBoxClear = () => {
    setSearchText("");
    setSearchBoxCleared(true);
    setFilterData({});
    setFilterMap(new Map());
    setSearchBoxCleared(false);
  };

  const onSearchBoxChange = (event?: any, newValue?: string) => {
    var searchText = newValue || "";
    setSearchText(searchText);
  };

  const onStartDateChange = (newDate: Date) => {
    let newEndDate = endDate || newDate;
    setStartDate(newDate);
    setEndDate(newEndDate);
  };

  const onEndDateChange = (newDate: Date) => {
    let newStartDate = startDate || newDate;
    setStartDate(newStartDate);
    setEndDate(newDate);
  };

  return (
    <div className={classNames.root}>
      <SearchBox
        id={searchBoxId}
        className={classNames.searchBox}
        autoComplete="off"
        value={searchText}
        onClear={onSearchBoxClear}
        onChange={onSearchBoxChange}
      />
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />
      <PrimaryButton className={classNames.searchButton} text="Search" onClick={onSearchButtonClick} />
      {!searchBoxCleared &&
        Object.keys(filterData).map((key, index) => {
          return (
            <div key={index} className={filterClassNames.filterItems}>
              <div className={filterClassNames.filterItem}>
                <div className={filterClassNames.filterItemName}>
                  {<div className={filterClassNames.filterType}>{key}</div>}
                  <div>{filterData[key]}</div>
                </div>
                <Icon iconName="ChromeClose" className={filterClassNames.filterItemIcon} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MDCSearch;
