import { Icon } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import classNames from "./Portal.module.scss";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { getUserGraphData, getUserPhoto } from "../shared/helpers/apiHelper";
import { UserCallout } from "./UserCallout";
import { useId } from "@fluentui/react-hooks";
import AppLauncherCallout from "./AppLauncherCallout";
import { IUser } from "./Portal.types";
import { PortalContext } from "./Portal";
import { HeaderTags } from "./HeaderTags";

export const defaultAppTags = [];

const hostname = window.location.hostname.toLocaleLowerCase();

hostname === "mssales-dev.azurewebsites.net"
  ? defaultAppTags.push("Dev")
  : hostname === "mssales-int.azurewebsites.net"
  ? defaultAppTags.push("Int")
  : hostname === "mssales-ppe.azurewebsites.net" ||
    hostname === "fo-mssales-cmod-ui-int-app.azurewebsites.net" ||
    hostname === "mssales-int.microsoft-int.com"
  ? defaultAppTags.push("Ppe")
  : hostname !== "mssales.azurewebsites.net" &&
    hostname !== "mssales-prod.azurewebsites.net" &&
    hostname !== "fo-mssales-cmod-ui-prod-app.azurewebsites.net"
  ? defaultAppTags.push("Non-Prod")
  : console.log("prod frontend");

export interface IPortalHeaderProps {
  showUserCallout: boolean;
  onShowUserCalloutChange?: (showCallout: boolean) => void;
}

export const PortalHeader = (props: IPortalHeaderProps) => {
  const { showUserCallout, onShowUserCalloutChange } = props;
  const { selectedApp } = useContext(PortalContext);
  const { instance, inProgress } = useMsal();
  const [userGraphData, setUserGraphData] = useState<IUser>();
  const [userPhotoUrl, setUserPhotoUrl] = useState<string>();
  const [showAppLauncher, setShowAppLauncher] = useState<boolean>();
  const userImageId = useId("userImage");
  const appLauncherId = useId("appLancher");

  useEffect(() => {
    if (!userGraphData && inProgress === InteractionStatus.None) {
      getUserGraphData().then((response: any) => setUserGraphData(response));
      getUserPhoto().then((url) => setUserPhotoUrl(url));
    }
  }, [inProgress, userGraphData, instance]);

  return (
    <header className={`${classNames.header} ms-bgColor-themePrimary`}>
      <div className={classNames.headerContent}>
        <div className={classNames.headerLeftPane}>
          <div className={classNames.appLauncher} id={appLauncherId} onClick={() => setShowAppLauncher(true)}>
            <Icon className={classNames.icon} iconName="waffle" />
          </div>
          <div className={classNames.name}>
            MS Sales <Icon className={classNames.chevron} iconName="ChevronRight" /> {selectedApp?.name}
          </div>
          <HeaderTags items={selectedApp?.getAppTags ? selectedApp.getAppTags() : defaultAppTags} />
        </div>
        <div className={classNames.headerRightPane}>
          <HeaderTags items={selectedApp?.getUserTags && selectedApp.getUserTags()} tagClassName={classNames.userTag} />
          <div className={classNames.userPhoto}>
            {userPhotoUrl ? (
              <img
                id={userImageId}
                className={classNames.userImage}
                src={userPhotoUrl}
                alt="User"
                onClick={() => onShowUserCalloutChange(true)}
              />
            ) : (
              <Icon
                id={userImageId}
                className={classNames.userIcon}
                iconName="Contact"
                title="User"
                onClick={() => onShowUserCalloutChange(true)}
              />
            )}
          </div>
        </div>
      </div>
      {showUserCallout && (
        <UserCallout
          user={userGraphData}
          userPhoto={userPhotoUrl}
          target={`#${userImageId}`}
          onDismiss={() => onShowUserCalloutChange(false)}
        />
      )}
      {showAppLauncher && (
        <AppLauncherCallout target={`#${appLauncherId}`} onDismiss={() => setShowAppLauncher(false)} />
      )}
    </header>
  );
};

export default PortalHeader;
