import { Callout, DefaultButton, Icon } from "@fluentui/react";
import React, { useContext } from "react";
import { msalInstance } from "..";
import { IUser } from "./Portal.types";
import { PortalContext } from "./Portal";
import classNames from "./UserCallout.module.scss";

export interface IUserCalloutProps {
  user: IUser;
  userPhoto: string;
  target: string;
  onDismiss: () => void;
}

export const UserCallout = (props: IUserCalloutProps) => {
  const { user, userPhoto, target, onDismiss } = props;
  const { selectedApp, onPortalErrorChange } = useContext(PortalContext);

  const onLogoutClick = () => {
    msalInstance.logoutRedirect().catch((error) => onPortalErrorChange(error));
  };

  return (
    <Callout target={target} onDismiss={onDismiss} className={classNames.root}>
      <div className={classNames.userPane}>
        {userPhoto ? (
          <img src={userPhoto} className={classNames.userImage} alt="User" />
        ) : (
          <Icon className={classNames.userIcon} iconName="Contact" title="User" />
        )}
        <div className={classNames.userInfo}>
          <div className={classNames.userName}>{user.displayName}</div>
          <div className={classNames.userPrincipalName}>{user.userPrincipalName}</div>
        </div>
      </div>
      {selectedApp?.userOptionsComponent && (
        <div className={classNames.settingsPane}>{selectedApp?.userOptionsComponent}</div>
      )}
      <div className={classNames.logoutPane}>
        <DefaultButton text="logout" onClick={onLogoutClick} />
      </div>
    </Callout>
  );
};

export default UserCallout;
