import React from "react";
import { IField } from "./interfaces";
import { DatePicker } from "@fluentui/react";
import classNames from "./DataForm.module.scss";

export interface IDateTextFieldProps extends IField {
  id: string;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
}

export class DateTextField extends React.Component<IDateTextFieldProps> {
  render() {
    const { id, value, fieldProps, style, onChange } = this.props;
    const valueAsString = value.toString();

    const dateValue = isNaN(Date.parse(valueAsString))
      ? undefined
      : new Date(value + (valueAsString.length <= 10 ? "T08:00:00.000Z" : ""));

    return (
      <div className={classNames.dateText} style={style}>
        <DatePicker
          id={id}
          className={classNames.datePicker}
          value={dateValue}
          onSelectDate={(date: Date) => onChange(date.toISOString().substring(0, 10))}
          {...fieldProps}
        />
        <input className={classNames.text} value={valueAsString} onChange={(e) => onChange(e.target.value)} />
      </div>
    );
  }

  onChange;
}

export default DateTextField;
