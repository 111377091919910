import { IDomains, IFilterItem, ITransmissions } from "./App.types";
import { FilterType } from "../search/AppSearch.helper";

export enum SearchMode {
  wip = "WIP",
  all = "All",
  source = "Source",
  template = "Template",
}

export const getTransmissionTypeIconInfo = (transmissionTypeId: number): [string, string] => {
  switch (transmissionTypeId) {
    case 1: // Channel Sales
    case 10: // Sellin Sales
      return ["AllCurrency", "green"];
    case 2: // Channel Inventory
      return ["CheckList", "blue"];
    case 8: // Sellin Product
      return ["Product", "goldenrod"];
    case 9: // Sellin Customer
      return ["People", "purple"];
    case 11: // Sellin Recon
      return ["Compare", "marron"];
    default:
      return ["File", "navy"];
  }
};

export const getTransmissionsBySource = (transmissions: ITransmissions, domains: IDomains) => {
  var transmissionsBySource = {};

  transmissions?.allTransmissions?.forEach((transmission) => {
    let sourceOrgId = transmission["SourceOrgID"],
      sourceNamePropName = "s" + sourceOrgId, // Note: Prefix with "s" in case the source name starts with a number which is not allowed as prop name.
      statusItem = domains?.transmissionStatuses?.find(
        (s) => s["TransmissionStatusID"] === transmission["TransmissionStatusID"]
      ),
      status = (statusItem && statusItem["TransmissionStatusCode"]) || transmission["TransmissionStatusID"];

    if (sourceOrgId) {
      if (!transmissionsBySource.hasOwnProperty(sourceNamePropName)) {
        transmissionsBySource[sourceNamePropName] = {
          sourceOrgId,
          sourceOrgName: transmission["Name"],
          transmissions: [],
        };
      }
      let [iconName, iconColor] = getTransmissionTypeIconInfo(transmission["TransmissionTypeID"]);

      transmissionsBySource[sourceNamePropName].transmissions.push({
        id: transmission["TransmissionID"],
        name: transmission["FileName"],
        icon: iconName,
        iconProps: { iconName, styles: { root: { color: iconColor } } },
        url: "",
        date: transmission["ReportEndDate"] || transmission["TransmissionDate"],
        sourceFileId: transmission["SourceFileID"],
        status,
      });
    }
  });

  return transmissionsBySource;
};

export const getTransmissionsQueryParams = (filterItems: IFilterItem[], userClass: string) => {
  var queryParams = { userClass };

  filterItems?.forEach((filterItem) => {
    if (filterItem.filterType === FilterType.date) {
      var dateKeys = filterItem.filterValues[0].key?.toString().split(",");
      if (dateKeys.length === 3) {
        dateKeys[0] !== "undefined" && (queryParams["startDate"] = dateKeys[0]);
        dateKeys[1] !== "undefined" && (queryParams["endDate"] = dateKeys[1]);
        queryParams["dateFlag"] = Number(dateKeys[2]);
      }
    } else {
      queryParams[filterItem.filterType] = filterItem.filterValues.map((value) => value.key).join(",");
    }
  });

  return queryParams;
};

export const getTransmissionTypeName = (transmissionTypeId: number, domains: IDomains): string => {
  const transmissionTypes = domains?.transmissionTypes,
    transmissionTypeItem = transmissionTypes?.find((t) => t["TransmissionTypeID"] === transmissionTypeId);
  return (transmissionTypeItem && transmissionTypeItem["TransmissionTypeCode"]) || transmissionTypeId;
};

export {};
