import React from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { ItemDisplayView } from "./ItemContainer";
import { IStyle } from "@fluentui/react";

export interface IDataContext {
  context: any;
  parentDataContext?: IDataContext;
  field: IField;
  key?: string;
  onFieldValueChange?: (fieldName, newValue) => void;
}

export interface IFieldStatus {
  passed: boolean;
  message?: string;
}

export type BooleanEval = boolean | string | ((dataContext: IDataContext) => boolean);

export enum FieldType {
  boolean,
  button,
  container,
  context,
  comboBox,
  customHtml,
  displayOnly,
  date,
  dateText,
  enumeration,
  html,
  iconButton,
  items,
  json,
  month,
  number,
  sortedItems,
  text,
  textArray,
}

export interface IField {
  fieldName?: string;
  value?:
    | ((parentDataContext: IDataContext) => any)
    | string
    | string[]
    | number
    | number[]
    | boolean
    | object
    | object[];
  defaultValue?: any;
  label?: string;
  hints?: string;
  fieldType?: FieldType;
  width?: string | number;
  valueWidth?: string | number;
  fillWidth?: boolean;
  height?: string | number;
  isMultiLine?: boolean;
  required?: BooleanEval;
  disabled?: boolean;
  visible?: BooleanEval;
  lastInRow?: boolean;
  firstInRow?: boolean;
  doNotExpandWidth?: boolean;
  collapsible?: BooleanEval;
  collapsed?: BooleanEval;
  className?: string;
  valueClassName?: string;
  itemTypeName?: string;
  compact?: boolean;
  regEx?: string;
  style?: React.CSSProperties;
  styles?: IStyle;
  fieldValueStyle?: React.CSSProperties;
  options?: IDropdownOption[] | ((dataContext: IDataContext) => IDropdownOption[]);
  multiSelect?: boolean;
  allowFullEditor?: boolean;
  fieldProps?: object;
  itemDisplayView?: ItemDisplayView;
  fields?: IField[] | ((dataContext: IDataContext) => IField[]);
  doNotAllowItemViewSwitch?: boolean;
  doNotAllowNewItemCreation?: boolean;
  /** Flag a dropdown is case insensitive. Default is false. */
  caseInsensitive?: boolean;
  placeholder?: string;
  onValueChange?: (dataContext: IDataContext, newValue: any) => any;
  customTitle?: (dataContext: IDataContext, itemTypeName: string, index: number) => string;
  customTabHeader?: (dataContext: IDataContext, itemTypeName: string, index: number) => string;
  checkIfValid?: (dataContext: IDataContext, newValue: any) => IFieldStatus;
  onClick?: (dataContext?: IDataContext) => void;
}
