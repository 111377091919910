import { Nav, INavStyles, Spinner } from "@fluentui/react";
import { useCallback, useMemo } from "react";
import classNames from "../app/MDCFileExplorer.module.scss";
import { sortList } from "../../shared/helpers/miscHelper";
import {
  getTransmissionsByDay,
  getTransmissionsByMonth,
  getTransmissionsBySource,
  getTransmissionsByTid,
  getTransmissionsByYear,
} from "../app/MDCFileExplorer.helper";
import { IApiError } from "../../shared/helpers/apiHelper";

const navStyles: Partial<INavStyles> = {
  root: {
    height: "100%",
    padding: "0px 2px",
  },
  chevronButton: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 38,
  },
  chevronIcon: {
    left: 12,
  },
  link: {
    fontSize: 12,
  },
  groupContent: {
    marginBottom: 10,
    marginLeft: 10,
    animation: "none",
  },
};

export interface MDCFileExplorerProps {
  loading: boolean;
  transmissionData: any[];
  onTransmissionSelect: (transmission: []) => void;
  error: IApiError;
}

const getMonthFromNum = (num: string) => {
  var dict = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  };
  return dict[num];
};

export const MDCFileExplorer = (props: MDCFileExplorerProps) => {
  const { loading, transmissionData, onTransmissionSelect, error } = props;

  const onLinkClick = useCallback(
    (ev, item) => {
      onTransmissionSelect({ ...item });
    },
    [onTransmissionSelect]
  );

  const fileExplorerGroups = useMemo(() => {
    let groups = [{ links: [] }];
    var transmissionsBySource = getTransmissionsBySource(transmissionData);
    Object.keys(transmissionsBySource).forEach((sourceKey) => {
      var transmissionInformation = transmissionsBySource[sourceKey];
      var sourceTransmissions = transmissionInformation.transmissions;
      var sourceName = transmissionInformation.source;
      var sourceGroup = {
        name: sourceName,
        links: [],
      };
      var transmissionsByTid = getTransmissionsByTid(sourceTransmissions);
      Object.keys(transmissionsByTid).forEach((tidKey) => {
        var templateIDTransmission = transmissionsByTid[tidKey];
        var transmissionIDGroup = {
          name: tidKey,
          links: [],
        };
        var transmissionsByYear = getTransmissionsByYear(templateIDTransmission);
        Object.keys(transmissionsByYear).forEach((yearKey) => {
          var yearTransmissions = transmissionsByYear[yearKey];
          var yearGroup = {
            name: yearKey,
            links: [],
          };
          var transmissionsByMonth = getTransmissionsByMonth(yearTransmissions);
          Object.keys(transmissionsByMonth).forEach((monthKey) => {
            var monthTransmissions = transmissionsByMonth[monthKey];
            var monthGroup = {
              name: getMonthFromNum(monthKey),
              links: [],
              isExpanded: false,
            };
            var transmissionsByDay = getTransmissionsByDay(monthTransmissions);
            Object.keys(transmissionsByDay).forEach((dayKey) => {
              var dayTransmissions = transmissionsByDay[dayKey];
              var dayGroup = {
                name: dayKey,
                links: [],
                isExpanded: false,
              };
              Object.keys(dayTransmissions).forEach((transmission) => {
                dayGroup.links.push(dayTransmissions[transmission]);
              });
              monthGroup.links.push(dayGroup);
              monthGroup.links.sort(sortList());
              monthGroup.links.reverse();
            });
            yearGroup.links.push(monthGroup);
            yearGroup.links.sort(sortList());
          });
          transmissionIDGroup.links.push(yearGroup);
          transmissionIDGroup.links.sort(sortList());
          transmissionIDGroup.links.reverse();
        });
        sourceGroup.links.push(transmissionIDGroup);
        sourceGroup.links.sort(sortList());
      });
      groups[0].links.push(sourceGroup);
      groups[0].links.sort(sortList());
    });
    return groups;
  }, [transmissionData]);

  return (
    <div className={classNames.root}>
      {loading && <Spinner className={classNames.spinner} />}
      {error && <div className={classNames.noneFound}>{error["message"]}</div>}
      {!loading && transmissionData.length === 0 ? (
        <div className={classNames.noneFound}>
          No transmission file is found.
          <br />
          Modify your search criteria.
        </div>
      ) : (
        !loading && <Nav groups={fileExplorerGroups} styles={navStyles} onLinkClick={onLinkClick} />
      )}
    </div>
  );
};

export default MDCFileExplorer;
