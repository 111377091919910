import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Nav, INavStyles, Spinner, INavLink } from "@fluentui/react";
import classNames from "./FileExplorer.module.scss";
import { sortList } from "../../shared/helpers/miscHelper";
import { ITransmissions } from "./App.types";
import { SearchMode, getTransmissionsBySource } from "./App.helper";
import { AppContext } from "./App";
import { FilterType } from "../search/AppSearch.helper";
import FileExplorerActions from "./FileExplorerActions";

const navStyles: Partial<INavStyles> = {
  root: {
    height: "100%",
    padding: "0px 2px",
  },
  chevronButton: {
    fontSize: 12,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 38,
    lineHeight: 28,
    height: 28,
  },
  chevronIcon: {
    left: 18,
    lineHeight: 28,
    height: 28,
  },
  link: {
    height: 18,
    lineHeight: 18,
    fontSize: 11,
  },
  groupContent: {
    marginTop: 2,
    marginBottom: 8,
    marginLeft: 8,
    animation: "none",
  },
};

enum ExpandState {
  allCollapsed,
  allExpanded,
  undefined,
}

export interface IFilterExplorerProps {
  transmissions: ITransmissions;
  loading: boolean;
}

export const FileExplorer = (props: IFilterExplorerProps) => {
  const [expandState, setExpandState] = useState<ExpandState>(ExpandState.undefined);
  const { transmissions, loading } = props;
  const { appState, changeAppState } = useContext(AppContext);
  const {
    domains,
    filterItems,
    selectedSource,
    selectedTransmission,
    selectedMode,
    sources,
    templates,
    selectedTemplate,
  } = appState;
  const expandedList = useRef([]);
  const collapsedList = useRef([]);
  const hasTransmissions = transmissions?.allTransmissions?.length > 0;
  const showSourcesList = selectedMode === SearchMode.source;
  const showTemplatesList = selectedMode === SearchMode.template;

  useEffect(() => {
    if (loading) {
      expandedList.current = [];
      collapsedList.current = [];
      setExpandState(ExpandState.undefined);
    }
  }, [transmissions, loading]);

  const onLinkGroupClick = useCallback(
    (item) => {
      changeAppState({
        selectedSource: { id: Number(item.id), name: item.name },
        selectedTransmission: undefined,
        error: undefined,
      });

      // Add or remove the target source.
      if (expandState === ExpandState.undefined || expandState === ExpandState.allCollapsed) {
        const expandedIndex = expandedList.current.indexOf(item.id);

        if (expandedIndex >= 0) {
          expandedList.current.splice(expandedIndex, 1);
        } else {
          expandedList.current.push(item.id);
        }
      } else if (expandState === ExpandState.allExpanded) {
        const collapsedIndex = collapsedList.current.indexOf(item.id);

        if (collapsedIndex >= 0) {
          collapsedList.current.splice(collapsedIndex, 1);
        } else {
          collapsedList.current.push(item.id);
        }
      }

      setExpandState(ExpandState.undefined);
    },
    [changeAppState, expandState]
  );

  const onLinkClick = useCallback(
    (ev, item) => {
      changeAppState({
        selectedSource: { id: Number(item.sourceId), name: item.sourceName },
        selectedTransmission: { ...item },
        error: undefined,
      });
    },
    [changeAppState]
  );

  const onSourceClick = useCallback(
    (source) => {
      changeAppState({
        selectedSource: source,
        selectedTransmission: undefined,
        selectedTemplate: undefined,
        error: undefined,
      });
    },
    [changeAppState]
  );

  const onTemplateClick = useCallback(
    (template) => {
      changeAppState({
        selectedTemplate: template,
        selectedSource: undefined,
        selectedTransmission: undefined,
        error: undefined,
      });
    },
    [changeAppState]
  );

  const onRenderLink = useCallback(
    (link: INavLink) => {
      let linkClassNames = ["ms-Nav-linkText", classNames.navLink],
        status = link["status"],
        statusHint = status?.length ? status[0] : undefined,
        showStatusHint = status !== "Ready",
        statusHintColor =
          status === "Reject" || status === "FixableReject" ? "red" : status === "Recycle" ? "blue" : "black";

      link.id === selectedTransmission?.id && linkClassNames.push(classNames.navLinkSelected);

      return (
        <div className={classNames.navLinkPane}>
          {showStatusHint && (
            <div className={classNames.statusHint} title={status} style={{ color: `${statusHintColor}` }}>
              {statusHint}
            </div>
          )}
          <div className={linkClassNames.join(" ")}>{link.name}</div>
        </div>
      );
    },
    [selectedTransmission?.id]
  );

  const onCollapseAll = () => {
    expandedList.current = [];
    collapsedList.current = [];
    setExpandState(ExpandState.allCollapsed);
  };

  const onExpandAll = () => {
    expandedList.current = [];
    collapsedList.current = [];
    setExpandState(ExpandState.allExpanded);
  };

  const sourceFilterList = useMemo(() => {
    let sourceFilter = filterItems?.find((fi) => fi.filterType === FilterType.source);

    if (!sourceFilter) {
      if (selectedSource?.id) {
        sourceFilter = {
          filterType: FilterType.sourceOrgId,
          filterValues: [{ key: selectedSource.id.toString(), text: selectedSource.name }],
        };
      } else {
        return undefined;
      }
    }

    return sourceFilter.filterValues?.map((fv) => ({
      id: fv.key,
      name: fv.text,
      links: [{ id: null, name: "No transmission file is found.", url: null }],
      isExpand: true,
      onHeaderClick: () => onLinkGroupClick({ id: fv.key, name: fv.text }),
    }));
  }, [filterItems, selectedSource?.id, selectedSource?.name, onLinkGroupClick]);

  const groupedFileList = useMemo(
    () => {
      if (showSourcesList || showTemplatesList) return null;

      var result = [];
      var transmissionsBySource = getTransmissionsBySource(transmissions, domains);
      var sourceKeys = Object.keys(transmissionsBySource);
      var expandByDefault = false;

      if (sourceKeys.length === 1) {
        expandByDefault = true;
      }

      sourceKeys.forEach((key) => {
        var org = transmissionsBySource[key];
        var orgNavLinkGroup = {
          id: org.sourceOrgId,
          name: org.sourceOrgName,
          title: org.sourceOrgName,
          links: [],
          collapseByDefault: true,
          isExpanded:
            (expandState === ExpandState.allExpanded && collapsedList.current.indexOf(org.sourceOrgId) < 0) ||
            expandByDefault
              ? true
              : expandState === ExpandState.allCollapsed && expandedList.current.indexOf(org.sourceOrgId) < 0
              ? false
              : expandedList.current.indexOf(org.sourceOrgId) >= 0,
          onHeaderClick: () => onLinkGroupClick({ id: org.sourceOrgId, name: org.sourceOrgName }),
        };

        org.transmissions?.forEach((transmission) => {
          orgNavLinkGroup.links.push({ ...transmission, sourceId: org.sourceOrgId, sourceName: org.sourceOrgName });
        });

        result.push(orgNavLinkGroup);
      });

      // If only one source org is found, expand the source org node by default.  Otherwise, sort the source org list by name.
      if (result.length === 1) {
        result[0].collapseByDefault = false;
      } else {
        result.sort(sortList());
      }

      // Within each source org, sort the files by date.
      result.forEach((orgGroup) => {
        orgGroup.links?.sort(sortList("date"));
      });

      return result;
    }, // eslint-disable-next-line
    [
      showSourcesList,
      showTemplatesList,
      transmissions,
      domains,
      expandState,
      collapsedList.current.length,
      expandedList.current.length,
      onLinkGroupClick,
    ]
  );

  useEffect(() => {
    // If only one transmission is found, select that transmission by default.
    if (groupedFileList?.length === 1 && groupedFileList[0].links?.length === 1) {
      changeAppState({ selectedTransmission: groupedFileList[0].links[0] });
    }
  }, [groupedFileList, changeAppState]);

  const onPrevItem = useCallback(() => {
    if (groupedFileList?.length) {
      // Expand all nodes before using next/prev button if nothing is already expanded.
      if (expandState !== ExpandState.allExpanded && expandedList.current.length === 0) {
        setExpandState(ExpandState.allExpanded);
      }

      // Select the last transmission of last source if nothing is selected.
      if (!selectedTransmission && !selectedSource) {
        let lastSource = groupedFileList[groupedFileList.length - 1],
          lastTransmission = lastSource.links?.length ? lastSource.links[lastSource.links.length - 1] : undefined;
        changeAppState({ selectedSource: lastSource, selectedTransmission: lastTransmission });
        return;
      }

      for (let sIndex = 0; sIndex < groupedFileList.length; sIndex++) {
        let sourceOrg = groupedFileList[sIndex];

        if (sourceOrg.id === selectedSource.id) {
          let sourceOrgTransmissions = sourceOrg.links;

          if (!selectedTransmission) {
            if (sIndex === 0) {
              let lastSource = groupedFileList[groupedFileList.length - 1],
                lastTransmission = lastSource.links?.length ? lastSource.links[lastSource.links.length - 1] : undefined;
              changeAppState({ selectedSource: lastSource, selectedTransmission: lastTransmission });
            } else {
              let prevSource = groupedFileList[sIndex - 1],
                prevTransmission = prevSource.links?.length ? prevSource.links[prevSource.links.length - 1] : undefined;
              changeAppState({ selectedSource: prevSource, selectedTransmission: prevTransmission });
            }
            return;
          }

          if (sourceOrgTransmissions.length) {
            for (let tIndex = 0; tIndex < sourceOrgTransmissions.length; tIndex++) {
              let transmission = sourceOrgTransmissions[tIndex];

              if (transmission.id === selectedTransmission.id) {
                if (tIndex === 0) {
                  changeAppState({ selectedTransmission: undefined });
                } else {
                  let prevTransmission = sourceOrgTransmissions[tIndex - 1];
                  changeAppState({ selectedTransmission: prevTransmission });
                }
                return;
              }
            }
          }
        }
      }
    } else if (showSourcesList && sources?.length) {
      // Select the last source if nothing is selected.
      if (!selectedSource) {
        changeAppState({ selectedSource: sources[sources.length - 1] });
        return;
      }

      for (let sIndex = 0; sIndex < sources.length; sIndex++) {
        let source = sources[sIndex];
        if (source.id === selectedSource.id) {
          if (sIndex === 0) {
            changeAppState({ selectedSource: sources[sources.length - 1] });
          } else {
            changeAppState({ selectedSource: sources[sIndex - 1] });
          }
        }
      }
    } else if (showTemplatesList && templates?.length) {
      // Select the last template if nothing is selected.
      if (!selectedTemplate) {
        changeAppState({ selectedTemplate: templates[templates.length - 1] });
        return;
      }

      for (let sIndex = 0; sIndex < templates.length; sIndex++) {
        let template = templates[sIndex];
        if (template.FileTemplateID === selectedTemplate.FileTemplateID) {
          if (sIndex === 0) {
            changeAppState({ selectedTemplate: templates[templates.length - 1] });
          } else {
            changeAppState({ selectedTemplate: templates[sIndex - 1] });
          }
        }
      }
    }
  }, [
    selectedSource,
    selectedTemplate,
    selectedTransmission,
    groupedFileList,
    expandState,
    sources,
    showSourcesList,
    templates,
    showTemplatesList,
    changeAppState,
  ]);

  const onNextItem = useCallback(() => {
    if (groupedFileList?.length) {
      // Expand all nodes before using next/prev button if nothing is already expanded.
      if (expandState !== ExpandState.allExpanded && expandedList.current.length === 0) {
        setExpandState(ExpandState.allExpanded);
      }

      // Select the first source if nothing is selected.
      if (!selectedTransmission && !selectedSource) {
        changeAppState({ selectedSource: groupedFileList[0] });
        return;
      }

      for (let sIndex = 0; sIndex < groupedFileList.length; sIndex++) {
        let sourceOrg = groupedFileList[sIndex];

        if (sourceOrg.id === selectedSource.id) {
          let sourceOrgTransmissions = sourceOrg.links;

          if (!selectedTransmission) {
            if (sourceOrgTransmissions?.length) {
              changeAppState({ selectedTransmission: sourceOrgTransmissions[0] });
            } else if (sIndex < groupedFileList.length - 1) {
              changeAppState({ selectedSource: groupedFileList[sIndex + 1] });
            } else {
              changeAppState({ selectedSource: groupedFileList[0] });
            }
            return;
          }

          if (sourceOrgTransmissions.length) {
            for (let tIndex = 0; tIndex < sourceOrgTransmissions.length; tIndex++) {
              let transmission = sourceOrgTransmissions[tIndex];

              if (transmission.id === selectedTransmission.id) {
                if (tIndex < sourceOrgTransmissions.length - 1) {
                  changeAppState({ selectedTransmission: sourceOrgTransmissions[tIndex + 1] });
                } else if (tIndex === sourceOrgTransmissions.length - 1 && sIndex < groupedFileList.length - 1) {
                  changeAppState({ selectedSource: groupedFileList[sIndex + 1], selectedTransmission: undefined });
                } else {
                  changeAppState({ selectedSource: groupedFileList[0], selectedTransmission: undefined });
                }
                return;
              }
            }
          }
        }
      }
    } else if (showSourcesList && sources?.length) {
      // Select the first source if nothing is selected.
      if (!selectedSource) {
        changeAppState({ selectedSource: sources[0] });
        return;
      }

      for (let sIndex = 0; sIndex < sources.length; sIndex++) {
        let source = sources[sIndex];
        if (source.id === selectedSource.id) {
          if (sIndex === sources.length - 1) {
            changeAppState({ selectedSource: sources[0] });
          } else {
            changeAppState({ selectedSource: sources[sIndex + 1] });
          }
        }
      }
    } else if (showTemplatesList && templates?.length) {
      // Select the first template if nothing is selected.
      if (!selectedTemplate) {
        changeAppState({ selectedTemplate: templates[0] });
        return;
      }

      for (let sIndex = 0; sIndex < templates.length; sIndex++) {
        let template = templates[sIndex];
        if (template.FileTemplateID === selectedTemplate.FileTemplateID) {
          if (sIndex === templates.length - 1) {
            changeAppState({ selectedTemplate: templates[0] });
          } else {
            changeAppState({ selectedTemplate: templates[sIndex + 1] });
          }
        }
      }
    }
  }, [
    selectedSource,
    selectedTemplate,
    selectedTransmission,
    groupedFileList,
    expandState,
    showSourcesList,
    sources,
    showTemplatesList,
    templates,
    changeAppState,
  ]);

  const onFileExplorerKeyDown = useCallback(
    (ev: React.KeyboardEvent) => {
      if (ev.key === "ArrowLeft") {
        onPrevItem();
      } else if (ev.key === "ArrowRight") {
        onNextItem();
      }
    },
    [onNextItem, onPrevItem]
  );

  return (
    <div className={classNames.root} onKeyDown={onFileExplorerKeyDown}>
      {loading && <Spinner className={classNames.spinner} />}
      {!loading && !hasTransmissions && !sourceFilterList && !showSourcesList && !showTemplatesList && (
        <div className={classNames.noneFound}>
          No transmission file is found.
          <br />
          Modify your search criteria.
        </div>
      )}
      {!loading && !hasTransmissions && sourceFilterList && !showSourcesList && !showTemplatesList && (
        <Nav groups={sourceFilterList} styles={navStyles} />
      )}
      {!loading && hasTransmissions && !showSourcesList && !showTemplatesList && (
        <>
          <FileExplorerActions {...{ onCollapseAll, onExpandAll, onPrevItem, onNextItem }} />
          <div className={classNames.fileList}>
            <Nav groups={groupedFileList} styles={navStyles} onLinkClick={onLinkClick} onRenderLink={onRenderLink} />
          </div>
        </>
      )}
      {showSourcesList &&
        (sources?.length ? (
          <>
            <FileExplorerActions {...{ onPrevItem, onNextItem }} />
            <div className={classNames.sourcesList}>
              {sources.map((source) => {
                let sourceClassNames = [classNames.source];
                selectedSource?.id === source.id && sourceClassNames.push(classNames.selected);
                source.inactive && sourceClassNames.push(classNames.inactive);

                return (
                  <div className={sourceClassNames.join(" ")} onClick={() => onSourceClick(source)}>
                    {source.name}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className={classNames.noneFound}>
            No source is found.
            <br />
            Modify your search criteria.
          </div>
        ))}
      {showTemplatesList &&
        (templates?.length ? (
          <>
            <FileExplorerActions {...{ onPrevItem, onNextItem }} />
            <div className={classNames.templatesList}>
              {templates.map((template) => {
                let templateClassNames = [classNames.template];
                selectedTemplate?.FileTemplateID === template.FileTemplateID &&
                  templateClassNames.push(classNames.selected);

                return (
                  <div className={templateClassNames.join(" ")} onClick={() => onTemplateClick(template)}>
                    {template.Description}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className={classNames.noneFound}>
            No template is found.
            <br />
            Modify your search criteria.
          </div>
        ))}
    </div>
  );
};

export default FileExplorer;
