import React, { useCallback, useContext } from "react";
import { Dropdown } from "@fluentui/react";
import classNames from "./AppContent.module.scss";
import { AppContext } from "../app/App";
import { getTransmissionTypeName } from "../app/App.helper";
import { AppContentContext } from "./AppContent";

export interface IFileSelectorProps {
  onFileChange: (fileId: number) => void;
}

export const FileSelector = (props: IFileSelectorProps) => {
  const { onFileChange } = props;
  const { domains } = useContext(AppContext).appState;
  const { selectedSourceFiles, selectedFile } = useContext(AppContentContext);

  const onFileTypeDropdownChange = useCallback((ev, option) => onFileChange(Number(option.key)), [onFileChange]);

  if (!selectedSourceFiles?.files?.length || (selectedFile && selectedFile["IsNew"])) {
    return null;
  }

  return (
    <div className={classNames.selectedFileTypePane}>
      <div className={classNames.label}>Selected Transmission Type:</div>
      <Dropdown
        selectedKey={selectedFile && selectedFile["SourceFileID"]}
        className={classNames.fileTypeDropdown}
        options={getFileTypeOptions(selectedSourceFiles, domains)}
        onChange={onFileTypeDropdownChange}
      />
    </div>
  );
};

export default FileSelector;

const getFileTypeOptions = (selectedSourceFiles, domains) =>
  selectedSourceFiles?.files?.map((f) => {
    let transmissionTypeId = f["TransmissionTypeID"],
      transmissionType = getTransmissionTypeName(transmissionTypeId, domains);

    return { key: f["SourceFileID"], text: `${transmissionType} - ${f["FileNameUniqueIdentifier"]}` };
  });
