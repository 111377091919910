import { IconButton } from "@fluentui/react";
import React, { CSSProperties } from "react";
import { systemLogs } from "../../shared/helpers/apiHelper";
import classNames from "./LogsPanel.module.scss";

export interface ILogsPanelProps {
  onDismiss?: () => void;
  style?: CSSProperties;
  className?: string;
}

export const LogsPanel = (props: ILogsPanelProps = {}) => {
  const { style, className, onDismiss } = props;

  return (
    <div className={`${classNames.root} ${className}`} style={style}>
      <h3 className={classNames.headerTitle}>System Logs</h3>
      <IconButton
        iconProps={{ iconName: "ChromeClose" }}
        className={classNames.closeButton}
        title="Hide System Logs"
        onClick={onDismiss}
      />
      <div className={classNames.logList}>
        {systemLogs.map((log) => (
          <div className={classNames.logItem}>{log.message}</div>
        ))}
      </div>
    </div>
  );
};

export default LogsPanel;
