import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import classNames from "./AppContent.module.scss";
import Overview from "./Overview";
import SourceFileConfig from "./SourceFileConfig";
import TemplateConfig from "./TemplateConfig";
import ErrorCorrection from "./errorCorrection/ErrorCorrection";
import { AppContext } from "../app/App";
import { SearchInfoContent } from "../search/SearchInfoButton";
import AppContentHeader from "./AppContentHeader";
import {
  IFileTemplate,
  IHeaderItem,
  ISelectedSource,
  ISourceFiles,
  ISelectedTransmission,
  ITemplate,
} from "../app/App.types";
import { getSourceAndFiles, getChannelErrors, getSellinErrors, getFileTemplate } from "../helpers/apiHelper";
import { formatCurrency, formatNumber } from "../../shared/helpers/miscHelper";
import { verifyStreamingFile } from "../helpers/miscHelper";
import { processFileTemplateData } from "./AppContent.helper";
import { SearchMode } from "../app/App.helper";

const maxRequestedRows = 10000;
const mainErrorPaneId = "mainErrorPane";

export enum ContentTab {
  overview = "overview",
  errorCorrection = "errorCorrection",
  source = "source",
  file = "file",
  template = "template",
}

export interface IAppContentContext {
  fileErrors?: any[];
  errorSummaryItems?: any[];
  selectedErrorType?: IErrorTypeInfo;
  selectedSourceFiles?: ISourceFiles;
  selectedFile?: object;
  updateSelectedSourceFiles?: (newSourceFilesConfig) => void;
  updateSourceConfig?: (newSourceConfig) => void;
  updateSelectedFile?: (newFileConfig) => void;
  updateFileTemplateInfo?: (newTemplateConfig) => void;
  updateFileTemplateData?: (newFileTemplateData) => void;
  resetAllSelections?: () => void;
  reloadFileErrors?: () => void;
}

export interface IErrorTypeInfo {
  errorTypeId: number;
  errorCategory: string;
}

export const AppContentContext = createContext<IAppContentContext>({});

export const AppContent = () => {
  const [selectedContentTab, setSelectedContentTab] = useState<string>(ContentTab.overview);
  const [fileErrors, setFileErrors] = useState();
  const [selectedErrorType, setSelectedErrorType] = useState<IErrorTypeInfo>();
  const [selectedSourceFiles, setSelectedSourceFiles] = useState<ISourceFiles>();
  const [selectedFile, setSelectedFile] = useState<object>();
  const [fileTemplateData, setFileTemplateData] = useState<IFileTemplate>();
  const [loadingFileTemplate, setLoadingFileTemplate] = useState<boolean>(false);
  const [loadingFileErrors, setLoadingFileErrors] = useState<boolean>(false);
  const [loadingSourceFiles, setLoadingSourceFiles] = useState<boolean>(false);
  const { appState, onErrorChange, updateSelectedSource, updateSelectedTransmission, changeAppState } =
    useContext(AppContext);
  const {
    userClass,
    selectedSource,
    selectedTemplate,
    error,
    transmissions,
    selectedTransmission,
    domains,
    creatingNewSource,
    creatingNewTemplate,
    selectedMode,
  } = appState;
  const hasTransmissions = transmissions?.allTransmissions?.length > 0;
  const creatingConfig = creatingNewSource || creatingNewTemplate;
  const showContent = hasTransmissions || !!selectedSource || creatingConfig || !!selectedTemplate;
  const isStreamingFile = verifyStreamingFile(selectedTransmission, selectedSource, transmissions);
  const sourceTabHeaderText = isStreamingFile ? "Source" : "Source & File";

  const errorSummaryItems = useMemo(
    () => getErrorSummaryItems(transmissions, selectedSource, selectedTransmission, domains?.errorTypes),
    [transmissions, selectedSource, selectedTransmission, domains]
  );

  const hasOverviewData = selectedTransmission || !!errorSummaryItems?.length;

  const resetSourceSelection = useCallback(() => {
    setSelectedSourceFiles(undefined);
    setSelectedFile(undefined);
    setSelectedContentTab(ContentTab.overview);
    setSelectedErrorType(undefined);
    setFileErrors(undefined);
    setFileTemplateData(undefined);
    onErrorChange(undefined);
  }, [onErrorChange]);

  const resetAllSelections = useCallback(() => {
    resetSourceSelection();
    updateSelectedSource(undefined);
    updateSelectedTransmission(undefined);
  }, [resetSourceSelection, updateSelectedSource, updateSelectedTransmission]);

  // Reset data if userClass or source selection changes.
  useEffect(() => {
    resetSourceSelection();
  }, [userClass, selectedSource?.id, resetSourceSelection]);

  // Reset content if transmission/file selection changes.
  useEffect(() => {
    setSelectedContentTab(ContentTab.overview);
    setSelectedErrorType(undefined);
    setFileErrors(undefined);
    setFileTemplateData(undefined);
  }, [selectedTransmission]);

  useEffect(() => {
    if (error) {
      document.getElementById(mainErrorPaneId).scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [error]);

  // Load file error records.
  const onErrorTypeSelect = useCallback(
    (errorType: IErrorTypeInfo) => {
      // Switch to the error correction tab first.
      setSelectedContentTab(ContentTab.errorCorrection);
      setSelectedErrorType(errorType);
      setFileErrors(undefined);
      setLoadingFileErrors(true);

      const queryParams = {
        sourceOrgId: selectedSource?.id,
        sourceOrgName: selectedSource?.name,
        transmission: selectedTransmission?.id,
        errorType: errorType.errorTypeId,
        requestedRows: maxRequestedRows,
      };

      // Call api to load error records for the selected transmission/file.
      var errorsPromise = userClass === "S" ? getSellinErrors(queryParams) : getChannelErrors(queryParams);

      errorsPromise
        .then((result: any) => {
          setFileErrors(result?.errors);
          changeAppState({ error: undefined });
        })
        .catch((error) => onErrorChange(error))
        .finally(() => setLoadingFileErrors(false));
    },
    [selectedSource, selectedTransmission, userClass, onErrorChange, changeAppState]
  );

  const reloadFileErrors = useCallback(() => {
    onErrorTypeSelect(selectedErrorType);
  }, [onErrorTypeSelect, selectedErrorType]);

  useEffect(() => {
    if (errorSummaryItems?.length > 0) {
      const firstErrorCategoryItem = errorSummaryItems[0];

      setSelectedErrorType({
        errorTypeId: firstErrorCategoryItem.errorTypeId,
        errorCategory: firstErrorCategoryItem.category,
      });
    }
  }, [errorSummaryItems]);

  // Load file template data.
  const loadFileTemplateData = useCallback(
    (file) => {
      const transmissionTypeId = file && file["TransmissionTypeID"];
      const fileTemplateId = file && file["FileTemplateID"];

      setLoadingFileTemplate(true);
      getFileTemplate({ userClass, transmissionTypeId, fileTemplateId })
        .then((result: any) => {
          processFileTemplateData(result, userClass);
          setFileTemplateData(result);
        })
        .catch((error) => onErrorChange(error))
        .finally(() => setLoadingFileTemplate(false));
    },
    [userClass, onErrorChange]
  );

  useEffect(() => {
    if (selectedTemplate) {
      loadFileTemplateData(selectedTemplate);
    }
  }, [selectedTemplate, loadFileTemplateData]);

  const reloadSourceAndFilesData = useCallback(
    (userClass?: string, selectedSource?: ISelectedSource) => {
      if (!selectedSource?.isNew) {
        setLoadingSourceFiles(true);
        getSourceAndFiles({ userClass, sourceOrgId: selectedSource.id })
          .then((result: any) => {
            setSelectedSourceFiles(result);
            setSelectedFile(undefined);
            setFileTemplateData(undefined);

            if (result?.files?.length) {
              let defaultFile = selectedTransmission
                ? result.files.find((f) => f["SourceFileID"] === selectedTransmission.sourceFileId)
                : result.files[0];

              if (defaultFile) {
                setSelectedFile(defaultFile);
                loadFileTemplateData(defaultFile);
              }
            }
          })
          .catch((error) => onErrorChange(error))
          .finally(() => setLoadingSourceFiles(false));
      }
    },
    // Note: Purposely remove dependency of userClass as somehow this method is called first before selectedSource is set empty at App component which switching userClass.
    //// eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTransmission, onErrorChange, loadFileTemplateData]
  );

  // Load source and files config data.
  const loadSourceAndFilesData = useCallback(() => {
    if (selectedSource) {
      reloadSourceAndFilesData(userClass, selectedSource);
    } else {
      setSelectedContentTab(ContentTab.overview);
      setSelectedErrorType(undefined);
      setSelectedSourceFiles(undefined);
      setSelectedFile(undefined);
      setFileErrors(undefined);
    }
  }, [userClass, selectedSource, reloadSourceAndFilesData]);

  useEffect(() => {
    if (!hasOverviewData) {
      loadSourceAndFilesData();
    }
  }, [hasOverviewData, loadSourceAndFilesData]);

  const updateSourceConfig = useCallback(
    (newSourceConfig) => {
      let newSourceFiles = { ...selectedSourceFiles, sources: [newSourceConfig] };

      setSelectedSourceFiles(newSourceFiles);

      // Update the new source config in domains.
      let newSourceOrg = domains?.sourceOrganizations?.find(
        (org) => org["SourceOrgID"] === newSourceConfig["SourceOrgID"]
      );

      if (!newSourceOrg) {
        domains?.sourceOrganizations?.push(newSourceConfig);
        changeAppState({ domains: { ...domains } });
      }
    },
    [domains, selectedSourceFiles, changeAppState]
  );

  const updateSelectedSourceFiles = useCallback(
    (newSourceFilesConfig) => {
      let newSource = newSourceFilesConfig?.sources[0];
      setSelectedFile(undefined);
      updateSelectedTransmission(undefined);
      updateSelectedSource({ id: newSource["OrganizationID"], name: newSource["GlobalName"], isNew: true });
      setTimeout(() => setSelectedSourceFiles(newSourceFilesConfig), 500);
    },
    [updateSelectedSource, updateSelectedTransmission]
  );

  const updateSelectedFile = useCallback((newFileConfig) => {
    setSelectedFile(newFileConfig);
  }, []);

  const updateFileTemplateInfo = useCallback(
    (newTemplateConfig) => {
      let { templateInfo, templateColumns } = fileTemplateData;

      templateInfo[0]["Description"] = newTemplateConfig["Description"];
      templateInfo[0]["FileDelimiterASCIIValue"] = newTemplateConfig["FileDelimiterASCIIValue"];
      templateInfo[0]["FileTypeID"] = newTemplateConfig["FileTypeID"];
      templateInfo[0]["IsNew"] = false;

      let newFileTemplateId = newTemplateConfig["FileTemplateID"];

      if (newFileTemplateId) {
        templateInfo[0]["FileTemplateID"] = newFileTemplateId;

        newTemplateConfig.columns?.forEach((column) => (column["FileTemplateID"] = newFileTemplateId));
      }

      templateColumns = newTemplateConfig.columns;

      setFileTemplateData({ templateInfo, templateColumns });
    },
    [fileTemplateData]
  );

  const updateFileTemplateData = useCallback((newFileTemplateData) => {
    setFileTemplateData(newFileTemplateData);
  }, []);

  const onPivotLinkClick = useCallback(
    (item: PivotItem) => {
      var newContentTab = item.props.itemKey;

      if (newContentTab === ContentTab.errorCorrection && selectedErrorType && !fileErrors) {
        onErrorTypeSelect(selectedErrorType);
      } else {
        setSelectedContentTab(newContentTab);
      }

      if ((newContentTab === ContentTab.source || newContentTab === ContentTab.template) && !selectedSourceFiles) {
        loadSourceAndFilesData();
      }

      onErrorChange(undefined);
    },
    [selectedErrorType, fileErrors, selectedSourceFiles, loadSourceAndFilesData, onErrorChange, onErrorTypeSelect]
  );

  const headerItems: IHeaderItem[] = useMemo(
    () =>
      getHeaderItems(
        selectedSource,
        selectedTransmission,
        selectedTemplate,
        selectedMode,
        showContent,
        creatingNewSource,
        creatingNewTemplate
      ),
    [
      selectedSource,
      selectedTransmission,
      selectedTemplate,
      selectedMode,
      showContent,
      creatingNewSource,
      creatingNewTemplate,
    ]
  );

  const onFileChange = useCallback(
    (fileId) => {
      let newFile = selectedSourceFiles?.files?.find((f) => f["SourceFileID"] === fileId);
      if (newFile) {
        setSelectedFile(newFile);
        loadFileTemplateData(newFile);
      }
    },
    [selectedSourceFiles, loadFileTemplateData]
  );

  return (
    <div className={classNames.root} data-is-scrollable="true">
      {error && (
        <div
          id={mainErrorPaneId}
          className={classNames.errorPane}
          dangerouslySetInnerHTML={{ __html: error.message }}
        />
      )}
      <AppContentContext.Provider
        value={{
          fileErrors,
          errorSummaryItems,
          selectedErrorType,
          selectedSourceFiles,
          selectedFile,
          updateSelectedSourceFiles,
          updateSourceConfig,
          updateSelectedFile,
          updateFileTemplateInfo,
          updateFileTemplateData,
          resetAllSelections,
          reloadFileErrors,
        }}
      >
        <AppContentHeader className={classNames.header} items={headerItems} />
        {!showContent && (
          <div className={classNames.noContentPane}>
            <div className={classNames.noContentText}>
              No source org or file is selected. Start typing in the Searchbox above to select search filters.
              <br />
              Remember to click the <b>Search</b> button to initiate the search.
            </div>
            <SearchInfoContent />
          </div>
        )}
        {showContent && (
          <Pivot selectedKey={selectedContentTab} onLinkClick={onPivotLinkClick}>
            {hasOverviewData && !creatingConfig && (
              <PivotItem itemKey={ContentTab.overview} headerText="Overview" className={classNames.pivotItem}>
                <Overview onErrorTypeSelect={onErrorTypeSelect} />
              </PivotItem>
            )}
            {!!errorSummaryItems?.length && !creatingConfig && (
              <PivotItem itemKey={ContentTab.errorCorrection} headerText="Error Correction">
                <ErrorCorrection loadingFileErrors={loadingFileErrors} onErrorTypeSelect={onErrorTypeSelect} />
              </PivotItem>
            )}
            {((selectedSource && !creatingConfig) || creatingNewSource) && (
              <PivotItem itemKey={ContentTab.source} headerText={sourceTabHeaderText}>
                <SourceFileConfig loadingSourceFiles={loadingSourceFiles} onFileChange={onFileChange} />
              </PivotItem>
            )}
            {((selectedSource && fileTemplateData && !isStreamingFile && !creatingConfig) ||
              creatingNewTemplate ||
              selectedTemplate) && (
              <PivotItem itemKey={ContentTab.template} headerText="Template">
                <TemplateConfig
                  fileTemplateData={fileTemplateData}
                  loadingFileTemplate={loadingFileTemplate}
                  onFileChange={onFileChange}
                />
              </PivotItem>
            )}
          </Pivot>
        )}
      </AppContentContext.Provider>
    </div>
  );
};

export default AppContent;

const getHeaderItems = (
  selectedSource: ISelectedSource,
  selectedTransmission: ISelectedTransmission,
  selectedTemplate: ITemplate,
  selectedMode: SearchMode,
  showContent: boolean,
  creatingNewSource: boolean,
  creatingNewTemplate: boolean
): IHeaderItem[] => {
  var items = [];

  if (!showContent) {
    items.push({ key: undefined, text: "CMODS Modern" });
  } else if (creatingNewSource) {
    items.push({ key: undefined, text: "Create New Source" });
  } else if (creatingNewTemplate) {
    items.push({ key: undefined, text: "Create New Template" });
  } else {
    if (selectedMode !== SearchMode.source && selectedMode !== SearchMode.template) {
      items.push({ key: undefined, text: "All Files" });
    }

    if (selectedMode === SearchMode.template && selectedTemplate) {
      items.push({
        key: selectedTemplate.FileTemplateID,
        text: selectedTemplate.Description,
        iconName: "FileTemplate",
        title: `Template '${selectedTemplate.Description}`,
      });
    } else if (selectedSource) {
      items.push({
        key: selectedSource.id,
        text: selectedSource.name,
        iconName: "Org",
        title: `${selectedSource.name} with sourceOrgId of ${selectedSource.id}`,
      });

      if (selectedTransmission) {
        items.push({
          key: selectedTransmission.id,
          text: selectedTransmission.name,
          iconName: selectedTransmission.icon,
          title: `${selectedTransmission.name} with fileId of ${selectedTransmission.id}`,
        });
      }
    }
  }

  return items;
};

const getErrorSummaryItems = (transmissions, selectedSource, selectedTransmission, errorTypes) => {
  let errorItems = [],
    aggregatesByErrorTypes = {},
    wipTransmissions = transmissions?.wipTransmissions,
    isStreamingFile = verifyStreamingFile(selectedTransmission, selectedSource, transmissions);

  wipTransmissions?.forEach((item) => {
    var sourceId = item["SourceOrgID"];
    var transmissionId = item["TransmissionID"];

    if (
      (!selectedSource || sourceId === selectedSource.id) &&
      (!selectedTransmission || transmissionId === selectedTransmission.id) &&
      (!isStreamingFile || Number(transmissionId) < 0)
    ) {
      var errorTypeId = item["ErrortypeID"];

      if (errorTypeId) {
        var errorTypeIdKey = "e" + errorTypeId;

        if (!aggregatesByErrorTypes.hasOwnProperty(errorTypeIdKey)) {
          aggregatesByErrorTypes[errorTypeIdKey] = { errorTypeId, errorRevenue: 0, errorRecordCount: 0, errorQty: 0 };
        }

        var errorRevenue = aggregatesByErrorTypes[errorTypeIdKey].errorRevenue + item["ErrorRevenue"];
        var errorRecordCount = aggregatesByErrorTypes[errorTypeIdKey].errorRecordCount + item["ErrorRecordCount"];
        var errorQty = aggregatesByErrorTypes[errorTypeIdKey].errorQty + item["ErrorQty"];

        aggregatesByErrorTypes[errorTypeIdKey] = { errorTypeId, errorRevenue, errorRecordCount, errorQty };
      }
    }
  });

  Object.keys(aggregatesByErrorTypes).forEach((key) => {
    var errorTypeItem = aggregatesByErrorTypes[key];
    var errorTypeData = errorTypes?.find((e) => e["ErrorTypeID"] === errorTypeItem.errorTypeId),
      errorType = (errorTypeData && errorTypeData["ErrorTypeCode"]) || errorTypeItem.errorTypeId;

    errorItems.push({
      errorTypeId: errorTypeItem.errorTypeId,
      category: errorType,
      revenue: `${formatCurrency(errorTypeItem.errorRevenue)}`,
      quantity: `${formatNumber(errorTypeItem.errorQty)}`,
      recordCount: `${formatNumber(errorTypeItem.errorRecordCount)}`,
    });
  });

  return errorItems;
};
