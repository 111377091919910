import React, { useCallback, useContext, useRef, useState } from "react";
import { DefaultButton, Dialog, DialogFooter, IconButton, Spinner, Stack } from "@fluentui/react";
import classNames from "./FileExplorer.module.scss";
import { AppContext } from "./App";
import {
  checkCrossBorderAlloc,
  getExcelFile,
  getLogFile,
  getRawDataFile,
  purgeTransmission,
  removeTransmission,
} from "../helpers/apiHelper";
import AlertDialog from "../../shared/components/CommonDialog/AlertDialog";
import PurgeTransmissionDialog from "../contents/PurgeTransmissionDialog";

export const FileActions = () => {
  const [loadingExcelFile, setLoadingExcelFile] = useState<boolean>(false);
  const [loadingRawDataFile, setLoadingRawDataFile] = useState<boolean>(false);
  const [loadingLogFile, setLoadingLogFile] = useState<boolean>(false);
  const [purgingFile, setPurgingFile] = useState<boolean>(false);
  const [removingFile, setRemovingFile] = useState<boolean>(false);
  const [showPurgeNotAllowedDialog, setShowPurgeNotAllowedDialog] = useState<boolean>(false);
  const [showPurgeDialog, setShowPurgeDialog] = useState<boolean>(false);
  const { appState, onErrorChange, onTransmissionUpdate } = useContext(AppContext);
  const { userClass, selectedTransmission, transmissions } = appState;
  const transmissionId = selectedTransmission?.id;
  const isPurgable = selectedTransmission && selectedTransmission["status"] !== "Purged";
  const isRemovable = selectedTransmission && selectedTransmission["status"] === "Reject";
  const abortController = useRef(new AbortController());

  const onPurgeButtonClick = useCallback(() => {
    if (transmissionId) {
      onErrorChange(undefined);
      checkCrossBorderAlloc({ transmissionId })
        .then((result) => {
          let crossBorderAlloc = result && result["crossBorderAlloc"]?.length && result["crossBorderAlloc"][0];

          crossBorderAlloc &&
          crossBorderAlloc["IsCrossBorderAllocation"] === "Yes" &&
          crossBorderAlloc["AllowCrossBorderPurging"] === "No"
            ? setShowPurgeNotAllowedDialog(true)
            : setShowPurgeDialog(true);
        })
        .catch((error) => onErrorChange(error));
    }
  }, [transmissionId, onErrorChange]);

  const onRemoveButtonClick = useCallback(() => {
    if (transmissionId) {
      onErrorChange(undefined);
      setRemovingFile(true);
      removeTransmission({ transmissionId })
        .then(() => {
          onTransmissionUpdate(transmissionId);
        })
        .catch((error) => onErrorChange(error))
        .finally(() => setRemovingFile(false));
    }
  }, [transmissionId, onErrorChange, onTransmissionUpdate]);

  const onPurgeTransmission = useCallback(
    (description) => {
      setPurgingFile(true);
      purgeTransmission({ transmissionId, description }, abortController.current.signal)
        .then(() => {
          const transmission = transmissions?.allTransmissions?.find(
            (item) => item["TransmissionID"] === selectedTransmission.id
          );

          onTransmissionUpdate(selectedTransmission.id, {
            ...transmission,
            TransmissionStatusID: 13,
            status: "Purged",
          });
        })
        .catch((error) => onErrorChange(error))
        .finally(() => setPurgingFile(false));
    },
    [selectedTransmission, transmissionId, transmissions, onErrorChange, onTransmissionUpdate]
  );

  const onExcelButtonClick = useCallback(() => {
    if (transmissionId) {
      onErrorChange(undefined);
      setLoadingExcelFile(true);
      getExcelFile(userClass, transmissionId)
        .catch((error) => onErrorChange(error))
        .finally(() => setLoadingExcelFile(false));
    }
  }, [userClass, transmissionId, onErrorChange]);

  const onRawDataButtonClick = useCallback(() => {
    if (transmissionId) {
      onErrorChange(undefined);
      setLoadingRawDataFile(true);
      getRawDataFile(userClass, transmissionId)
        .catch((error) => onErrorChange(error))
        .finally(() => setLoadingRawDataFile(false));
    }
  }, [userClass, transmissionId, onErrorChange]);

  const onLogFileButtonClick = useCallback(() => {
    if (transmissionId) {
      onErrorChange(undefined);
      setLoadingLogFile(true);
      getLogFile(userClass, transmissionId)
        .catch((error) => onErrorChange(error))
        .finally(() => setLoadingLogFile(false));
    }
  }, [userClass, transmissionId, onErrorChange]);

  return (
    <>
      <Stack horizontal className={classNames.fileActionsCommandBar}>
        {isPurgable &&
          (purgingFile ? (
            <Spinner className={classNames.commandBarSpinner} title="Purging file..." />
          ) : (
            <IconButton
              className={classNames.commandBarButton}
              iconProps={{ iconName: "PageRemove" }}
              title="Purge this file"
              onClick={onPurgeButtonClick}
            />
          ))}
        {isRemovable &&
          (removingFile ? (
            <Spinner className={classNames.commandBarSpinner} title="Removing file..." />
          ) : (
            <IconButton
              className={classNames.commandBarButton}
              iconProps={{ iconName: "Trash" }}
              title="Remove this file from view"
              onClick={onRemoveButtonClick}
            />
          ))}
        {selectedTransmission &&
          (loadingExcelFile ? (
            <Spinner className={classNames.commandBarSpinner} title="Loading Excel file..." />
          ) : (
            <IconButton
              className={classNames.commandBarButton}
              iconProps={{ iconName: "ExcelDocument" }}
              title="View data in Excel"
              onClick={onExcelButtonClick}
            />
          ))}
        {selectedTransmission &&
          (loadingRawDataFile ? (
            <Spinner className={classNames.commandBarSpinner} title="Loading raw data file..." />
          ) : (
            <IconButton
              className={classNames.commandBarButton}
              iconProps={{ iconName: "TextDocument" }}
              title="View raw data in Notepad"
              onClick={onRawDataButtonClick}
            />
          ))}
        {selectedTransmission &&
          (loadingLogFile ? (
            <Spinner className={classNames.commandBarSpinner} title="Loading log file..." />
          ) : (
            <IconButton
              className={classNames.commandBarButton}
              iconProps={{ iconName: "DietPlanNotebook" }}
              title="View log file in Notepad"
              onClick={onLogFileButtonClick}
            />
          ))}
      </Stack>
      {showPurgeNotAllowedDialog && (
        <AlertDialog
          hidden={!showPurgeNotAllowedDialog}
          headerText="Warning"
          contentText="It is not allowed to purge this transmission because of cross border allocation."
          onDismiss={() => setShowPurgeNotAllowedDialog(false)}
        />
      )}
      {showPurgeDialog && (
        <PurgeTransmissionDialog
          hidden={!showPurgeDialog}
          onCommit={onPurgeTransmission}
          onDismiss={() => setShowPurgeDialog(false)}
        />
      )}
      {purgingFile && (
        <Dialog hidden={!purgingFile}>
          <Spinner label="Purging selected file..." labelPosition="right" />
          <DialogFooter>
            <DefaultButton
              text="Cancel"
              onClick={() => {
                abortController.current.abort();
                abortController.current = new AbortController();
                setPurgingFile(false);
              }}
            />
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};

export default FileActions;
