import { Dialog, DialogFooter, IDialogProps, PrimaryButton } from "@fluentui/react";

export interface IAlertDialogProps extends IDialogProps {
  headerText?: string;
  contentText?: string;
  onDismiss?: () => void;
}

export const AlertDialog = (props: React.PropsWithChildren<IAlertDialogProps>) => {
  const { headerText, contentText, children, onDismiss } = props;

  return (
    <Dialog {...props} dialogContentProps={{ title: headerText, subText: contentText }} onDismiss={onDismiss}>
      {children}
      <DialogFooter>
        <PrimaryButton onClick={onDismiss} text="Ok" />
      </DialogFooter>
    </Dialog>
  );
};

export default AlertDialog;
