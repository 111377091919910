import React, { useState } from "react";
import classNames from "./ConfigForm.module.scss";
import { PrimaryButton, DefaultButton, Spinner } from "@fluentui/react";
import ConfirmDialog from "../../shared/components/CommonDialog/ConfirmDialog";

export interface IConfigFormProps {
  title: string;
  className?: string;
  compact?: boolean;
  isDirty?: boolean;
  isSaving?: boolean;
  isEditable?: boolean;
  showNewButton?: boolean;
  showDeleteButton?: boolean;
  enableCancelButton?: boolean;
  customButtons?: React.ReactNode;
  onSaveButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onNewButtonClick?: () => void;
  onDeleteButtonClick?: () => void;
}

export const ConfigForm = (props: React.PropsWithChildren<IConfigFormProps>) => {
  const {
    title,
    className,
    compact,
    isDirty,
    isSaving,
    children,
    isEditable = true,
    showNewButton,
    showDeleteButton,
    enableCancelButton,
    customButtons,
    onSaveButtonClick,
    onCancelButtonClick,
    onNewButtonClick,
    onDeleteButtonClick,
  } = props;
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState<boolean>(false);

  var rootClassNames = [classNames.root, className];
  compact && rootClassNames.push(classNames.compact);
  !isEditable && rootClassNames.push(classNames.nonEditable);

  return (
    <>
      <div className={rootClassNames.join(" ")}>
        <div className={classNames.header}>
          <div className={classNames.title}>{title}</div>
          {isEditable && (
            <div className={classNames.actionPane}>
              {showNewButton && <DefaultButton text="New" className={classNames.button} onClick={onNewButtonClick} />}
              {isSaving ? (
                <div className={classNames.isSavingSpinner}>
                  <Spinner />
                </div>
              ) : (
                <PrimaryButton
                  text="Save"
                  className={classNames.button}
                  disabled={!isDirty}
                  onClick={onSaveButtonClick}
                />
              )}
              {showDeleteButton && (
                <DefaultButton
                  text="Delete"
                  className={classNames.button}
                  onClick={() => setShowDeleteConfirmDialog(true)}
                />
              )}
              {customButtons && <>{customButtons}</>}
              <DefaultButton
                text="Cancel"
                className={classNames.button}
                disabled={!isDirty && !enableCancelButton}
                onClick={onCancelButtonClick}
              />
            </div>
          )}
        </div>
        {children}
      </div>
      {showDeleteConfirmDialog && (
        <ConfirmDialog
          headerText="Delete Template"
          onConfirm={onDeleteButtonClick}
          onDismiss={() => setShowDeleteConfirmDialog(false)}
        >
          Are you sure you want to delete this Template?
        </ConfirmDialog>
      )}
    </>
  );
};

export default ConfigForm;
