import React, { useState, useContext, useEffect, useCallback, createRef } from "react";
import {
  Callout,
  CommandBar,
  DirectionalHint,
  IChoiceGroupOption,
  ICommandBarProps,
  IconButton,
  PrimaryButton,
  Spinner,
} from "@fluentui/react";
import classNames from "./AppSearch.module.scss";
import AutoCompleteSearch from "./AutoCompleteSearch";
import { DateRangePicker } from "../../shared/components/DateRangePicker/DateRangePicker";
import FilterItems from "./FilterItems";
import { IFilterItem } from "../app/App.types";
import { getIsoDateString } from "../../shared/helpers/miscHelper";
import { SearchInfoButton } from "./SearchInfoButton";
import { useId } from "@fluentui/react-hooks";
import { FilterType } from "./AppSearch.helper";
import { AppContext } from "../app/App";
import RadioToggle from "../../shared/components/RadioToggle/RadioToggle";
import SearchFilters from "./SearchFilters";
import { useBrowserState } from "../../shared/helpers/hooks";
import { SearchMode } from "../app/App.helper";

export interface IAppSearchProps {
  onSearchChange: (filterItems: IFilterItem[]) => void;
  commandBarProps?: ICommandBarProps;
  hideSearch?: boolean;
}

export const AppSearch = (props: IAppSearchProps) => {
  const { onSearchChange, commandBarProps, hideSearch = false } = props;
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [filterItems, setFilterItems] = useState<IFilterItem[]>([]);
  const [dateFlag, setDateFlag] = useState<number>(1);
  const [showSearchWarning, setShowSearchWarning] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string>();
  const [sourceOrgId, setSourceOrgId] = useState<string>();
  const [showFilters, setShowFilters] = useBrowserState("showFilters");
  const { appState, changeAppState } = useContext(AppContext);
  const { userClass, domains, loadingDomains, filterDefinitions, selectedMode, searchPanelHeight } = appState;
  const modeToggleId = useId();
  const fileIdFilter = filterItems?.find((filter) => filter.filterType === FilterType.fileId);
  const sourceOrgIdFilter = filterItems?.find((filter) => filter.filterType === FilterType.sourceOrgId);
  const isSearchAllowed =
    selectedMode === SearchMode.all
      ? (startDate && endDate) || fileIdFilter || sourceOrgIdFilter
      : selectedMode === SearchMode.wip
      ? (startDate && endDate) || (!startDate && !endDate)
      : true;
  const isShowFilters = showFilters === "true";
  const isSourceOrTemplateMode = selectedMode === SearchMode.source || selectedMode === SearchMode.template;
  const searchFiltersButtonClassNames = [classNames.searchFiltersButton];
  const rootRef = createRef<HTMLDivElement>();

  isShowFilters && searchFiltersButtonClassNames.push(classNames.buttonSelected);

  const resetFilters = useCallback(() => {
    // if (selectedMode !== SearchMode.all && selectedMode !== SearchMode.wip) {
    //   if (selectedMode === SearchMode.source) {
    //     // For source mode, add the active status filter if it's not already defined.
    //     setFilterItems((fi) => {
    //       let activeStatusFilter = fi?.find((filterItem) => filterItem.filterType === FilterType.activeStatus);
    //       return activeStatusFilter ? [activeStatusFilter] : [];
    //     });
    //   } else if (selectedMode === SearchMode.template) {
    setFilterItems([]);
    // }

    setStartDate(undefined);
    setEndDate(undefined);
    setFileId("");
    setSourceOrgId("");
    // }
  }, []);

  // If user class is changed, reset all filters.
  useEffect(() => {
    resetFilters();
  }, [userClass, resetFilters]);

  useEffect(() => {
    if (!rootRef.current) return; // Wait for the elementRef to be available
    const resizeObserver = new ResizeObserver((entries) => {
      let newHeight = entries?.length && entries[0]?.contentRect?.height;
      newHeight !== searchPanelHeight && changeAppState({ searchPanelHeight: newHeight });
    });
    resizeObserver.observe(rootRef.current);
    return () => resizeObserver.disconnect(); // Clean up
  }, [changeAppState, rootRef, searchPanelHeight]);

  const onStartDateChange = (newDate: Date) => {
    let newEndDate = endDate || newDate;
    setStartDate(newDate);
    setEndDate(newEndDate);
    changeDateFilterItem(newDate, newEndDate, dateFlag, selectedMode);
  };

  const onEndDateChange = (newDate: Date) => {
    let newStartDate = startDate || newDate;
    setStartDate(newStartDate);
    setEndDate(newDate);
    changeDateFilterItem(newStartDate, newDate, dateFlag, selectedMode);
  };

  const onDateFlagChange = (ev, option: IChoiceGroupOption) => {
    var newDateFlag = Number(option.key);
    setDateFlag(newDateFlag);
    startDate && endDate && changeDateFilterItem(startDate, endDate, newDateFlag, selectedMode);
  };

  const changeDateFilterItem = (
    start: Date,
    end: Date,
    newDateFlag: number = dateFlag,
    newMode: SearchMode = selectedMode
  ) => {
    var itemIndex = filterItems?.findIndex((filterItem) => filterItem.filterType === FilterType.date),
      dateFlagPrefix = newDateFlag === 1 ? "R: " : "E: ",
      dateFilterValue = dateFlagPrefix + getDateFilterValue(start, end),
      filterValues = [
        { key: `${getIsoDateString(start)},${getIsoDateString(end)},${newDateFlag}`, text: dateFilterValue },
      ];

    if (itemIndex >= 0) {
      if (!start || !end) {
        filterItems.splice(itemIndex, 1);
      } else if (start && end) {
        filterItems[itemIndex].filterValues = filterValues;
      }
    } else if (start && end) {
      filterItems.push({ filterType: FilterType.date, filterValues });
    }
    onFilterItemsChange(filterItems.slice(), newMode, start, end);
  };

  const processNewFilterItem = (existingTypeIndex, newItems, key, text, filterType, searchFlag) => {
    if (existingTypeIndex >= 0) {
      if (key !== "") {
        // Due to the SQL SP not supporting multi-selection of the same filter type yet, we will only support single value selection for now.
        //var filterValues = newItems[existingTypeIndex].filterValues;
        //filterValues.push({ key, text });
        newItems[existingTypeIndex].filterValues = [{ key, text }];
      } else {
        // Remove the filter if key is empty, i.e. "<All>"
        newItems.splice(existingTypeIndex, 1);
      }
    } else {
      newItems.push({ filterType, filterValues: [{ key, text }], searchFlag });
    }
  };

  const onFilterItemChange = (
    filterType: FilterType,
    key: string,
    text: string,
    selected: boolean,
    searchFlag?: string,
    clearFilters?: boolean
  ) => {
    var newItems = clearFilters ? [] : [...filterItems];
    var existingItemIndex = newItems.findIndex(
      (item) => item.filterType === filterType && item.filterValues?.find((value) => value.key === key)
    );
    var existingTypeIndex = newItems.findIndex((item) => item.filterType === filterType);

    // For item already exists
    if (existingItemIndex >= 0) {
      if (!selected) {
        var filterValues = newItems[existingTypeIndex].filterValues;

        if (filterValues.length > 1) {
          var existingValueIndex = newItems[existingTypeIndex].filterValues.findIndex((value) => value.key === key);
          filterValues.splice(existingValueIndex, 1);
        } else {
          newItems.splice(existingItemIndex, 1);
        }
      }
    } else {
      // For item doesn't exist.
      processNewFilterItem(existingTypeIndex, newItems, key, text, filterType, searchFlag);

      // Additional processing for various filter types.
      if (filterType === FilterType.fileId) {
        setFileId(key);
      } else if (filterType === FilterType.sourceOrgId) {
        setSourceOrgId(key);
      } else if (filterType === FilterType.subsidiary) {
        // Add related filters, e.g. add related region as filter when a subsidiary is selected.
        addRelatedRegionFilter(newItems, key, domains, filterDefinitions);
      } else if (filterType === FilterType.source) {
        let selectedSource = domains?.sourceOrganizations?.find((source) => source["SourceOrgID"] === Number(key));

        if (selectedSource) {
          let selectedSubsidiaryId = selectedSource["SubsidiaryID"],
            selectedChannelId = selectedSource["ChannelID"],
            selectedContactId = selectedSource["ContactID"],
            selectedOrgTypeId = selectedSource["OrganizationTypeID"];

          addRelatedFilter(newItems, FilterType.subsidiary, selectedSubsidiaryId, domains, filterDefinitions);

          addRelatedRegionFilter(newItems, selectedSubsidiaryId, domains, filterDefinitions);

          addRelatedFilter(newItems, FilterType.channel, selectedChannelId, domains, filterDefinitions);

          addRelatedFilter(newItems, FilterType.contact, selectedContactId, domains, filterDefinitions);

          addRelatedFilter(newItems, FilterType.orgType, selectedOrgTypeId, domains, filterDefinitions);
        }
      } else if (filterType === FilterType.region) {
        removeRelatedFilter(newItems, FilterType.subsidiary);
      }
    }

    onFilterItemsChange(newItems, selectedMode, startDate, endDate);
  };

  const onFilterTypeRemove = (filterType: FilterType) => {
    var newItems = [...filterItems];
    var existingTypeIndex = newItems.findIndex((item) => item.filterType === filterType);

    if (existingTypeIndex >= 0) {
      newItems.splice(existingTypeIndex, 1);
    }

    var newStartDate = startDate,
      newEndDate = endDate;

    if (filterType === FilterType.date) {
      newStartDate = undefined;
      newEndDate = undefined;
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else if (filterType === FilterType.fileId) {
      setFileId("");
    } else if (filterType === FilterType.sourceOrgId) {
      setSourceOrgId("");
    }

    onFilterItemsChange(newItems, selectedMode, newStartDate, newEndDate);
  };

  const onFilterItemsChange = (newFilterItems: IFilterItem[], mode: SearchMode, startDate, endDate) => {
    // Do not proceed to search if searchAll is set but no date range is defined, unless file id or source org id is used.
    let fileIdFilter = newFilterItems?.find((item) => item.filterType === FilterType.fileId),
      sourceOrgIdFilter = newFilterItems?.find((item) => item.filterType === FilterType.sourceOrgId);

    if (mode === SearchMode.all && (!startDate || !endDate) && !fileIdFilter && !sourceOrgIdFilter) {
      setShowSearchWarning(true);
    }

    setFilterItems(newFilterItems);
  };

  const onModeChange = (ev, option: IChoiceGroupOption, targetStartDate = startDate, targetEndDate = endDate) => {
    let newMode = SearchMode[option.key.toLowerCase()];

    changeAppState({
      selectedMode: newMode,
      transmissions: undefined,
      sources: undefined,
      templates: undefined,
      selectedSource: undefined,
      selectedTransmission: undefined,
      selectedTemplate: undefined,
      error: undefined,
    });

    // For "all" search, default date range to last 7 days.
    if (newMode === SearchMode.all && !targetStartDate && !targetEndDate) {
      let newStartDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        newEndDate = new Date();

      setStartDate(newStartDate);
      setEndDate(newEndDate);
      changeDateFilterItem(newStartDate, newEndDate, dateFlag, newMode);
    } else if (newMode !== SearchMode.all) {
      setShowSearchWarning(false);
      setStartDate(undefined);
      setEndDate(undefined);
      changeDateFilterItem(undefined, undefined, dateFlag, newMode);

      if (newMode === SearchMode.source) {
        // When switching to source search, default to active status if it's not already set.
        let activeStatusFilter = filterItems?.find((filter) => filter.filterType === FilterType.activeStatus);

        if (!activeStatusFilter) {
          onFilterItemChange(FilterType.activeStatus, "1", "Active", true, undefined, true);
        } else {
          setFilterItems([activeStatusFilter]);
        }
      } else if (newMode === SearchMode.template) {
        setFilterItems([]);
      }
    }

    if (newMode === SearchMode.all || newMode === SearchMode.wip) {
      // For All or Wip, remove the active status filter if it's already defined.
      let activeStatusFilterIndex = filterItems?.findIndex((filter) => filter.filterType === FilterType.activeStatus);

      if (activeStatusFilterIndex >= 0) {
        let newFilterItems = [...filterItems];
        newFilterItems.splice(activeStatusFilterIndex, 1);

        setFilterItems(newFilterItems);
      }
    }
  };

  const onSearchButtonClick = () => {
    if (!isSearchAllowed) {
      setShowSearchWarning(true);
    } else {
      onSearchChange(filterItems);
    }
  };

  const onClearFiltersClick = () => {
    resetFilters();
  };

  return (
    <div className={classNames.root} ref={rootRef}>
      {!hideSearch && (
        <>
          <div className={classNames.searchMainPane}>
            <div className={classNames.searchRoot}>
              {loadingDomains && (
                <Spinner className={classNames.loadingSpinner} label="Loading domain data..." labelPosition="right" />
              )}
              {!loadingDomains && domains && (
                <>
                  <AutoCompleteSearch onFilterItemChange={onFilterItemChange} />
                  <PrimaryButton
                    className={classNames.searchButton}
                    text="Search"
                    onClick={onSearchButtonClick}
                    disabled={!isSearchAllowed}
                  />
                  <SearchInfoButton className={classNames.searchInfoButton} />
                  <RadioToggle
                    className={classNames.modeToggle}
                    options={[
                      { key: SearchMode.wip, text: SearchMode.wip },
                      { key: SearchMode.all, text: SearchMode.all },
                      { key: SearchMode.source, text: SearchMode.source },
                      { key: SearchMode.template, text: SearchMode.template },
                    ]}
                    selectedKey={selectedMode}
                    onChange={onModeChange}
                    id={modeToggleId}
                  />
                  {!isSourceOrTemplateMode && (
                    <DateRangePicker
                      className={classNames.dateRangePicker}
                      startDate={startDate}
                      endDate={endDate}
                      onStartDateChange={onStartDateChange}
                      onEndDateChange={onEndDateChange}
                    >
                      <RadioToggle
                        className={classNames.dateFlag}
                        options={[
                          { key: "1", text: "Received Date (R)" },
                          { key: "0", text: "Report End Date (E)" },
                        ]}
                        selectedKey={dateFlag === 0 ? "0" : "1"}
                        itemGap={12}
                        onChange={onDateFlagChange}
                      />
                    </DateRangePicker>
                  )}
                  <IconButton
                    className={searchFiltersButtonClassNames.join(" ")}
                    iconProps={{ iconName: "FilterSettings" }}
                    title="Select search filters"
                    onClick={() => setShowFilters(isShowFilters ? "false" : "true")}
                  />
                  {!!filterItems?.length && (
                    <IconButton
                      className={classNames.clearFiltersButton}
                      iconProps={{ iconName: "ClearFilter" }}
                      title="Clear all filters"
                      onClick={onClearFiltersClick}
                    />
                  )}
                  <FilterItems items={filterItems} onFilterTypeRemove={onFilterTypeRemove} />
                  {showSearchWarning && (
                    <Callout
                      className={classNames.searchWarningCallout}
                      target={`#${modeToggleId}`}
                      directionalHint={DirectionalHint.topCenter}
                      isBeakVisible={false}
                      onDismiss={() => setShowSearchWarning(false)}
                    >
                      Search "All" requires at least date range to be selected.
                    </Callout>
                  )}
                </>
              )}
            </div>
            {!loadingDomains && domains && commandBarProps && (
              <CommandBar {...commandBarProps} className={classNames.commandBar} />
            )}
          </div>
          <div className={classNames.searchSubPane}>
            {!loadingDomains && domains && isShowFilters && (
              <SearchFilters
                filterItems={filterItems}
                fileId={fileId}
                sourceOrgId={sourceOrgId}
                onFilterItemChange={onFilterItemChange}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AppSearch;

const getDateFilterValue = (start: Date, end: Date) => {
  if (start && end && Number(start) === Number(end)) {
    return getIsoDateString(start);
  }

  var filterValue = "";
  start && !end && (filterValue += ">= ");
  start && (filterValue += getIsoDateString(start) || "");
  !start && end && (filterValue += "<= ");
  start && end && (filterValue += " to ");
  end && (filterValue += getIsoDateString(end) || "");
  return filterValue;
};

const addRelatedFilter = (filterItems, filterType, key, domains, filterDefinitions) => {
  if (!key) return;

  let filterDefinition = filterDefinitions?.find((filterDef) => filterDef.filterType === filterType);

  if (!filterDefinition) return;

  let { keyName, textNames, textNameDelimiter, tableName } = filterDefinition,
    selectedItem = domains && domains[tableName]?.find((item) => item[keyName] === key);

  removeRelatedFilter(filterItems, filterType);

  if (selectedItem) {
    filterItems.push({
      filterType,
      filterValues: [{ key, text: textNames.map((textName) => selectedItem[textName]).join(textNameDelimiter) }],
      searchFlag: filterDefinition.searchFlag,
    });
  }
};

const removeRelatedFilter = (filterItems, filterType) => {
  let selectedFilterIndex = filterItems.findIndex((item) => item.filterType === filterType);

  if (selectedFilterIndex >= 0) {
    filterItems.splice(selectedFilterIndex, 1);
  }
};

const addRelatedRegionFilter = (filterItems, subsidiaryId, domains, filterDefinitions) => {
  let selectedSubsidiary = domains?.subsidiaries?.find(
    (subsidiary) => subsidiary["SubsidiaryID"] === Number(subsidiaryId)
  );

  if (selectedSubsidiary) {
    let regionId = selectedSubsidiary["WWRegionID"];

    addRelatedFilter(filterItems, FilterType.region, regionId, domains, filterDefinitions);
  }
};
