import { Callout, List } from "@fluentui/react";
import React, { useContext } from "react";
import { appFieldName, PortalContext } from "./Portal";
import { msSalesApps } from "./Portal.apps";
import classNames from "./Portal.module.scss";
import { IMSSalesApp } from "./Portal.types";

export interface IAppLauncherCalloutProps {
  target: string;
  onDismiss: () => void;
}

export const AppLauncherCallout = (props: IAppLauncherCalloutProps) => {
  const { target, onDismiss } = props;
  const { onAppChange } = useContext(PortalContext);

  const onAppItemClick = (app: IMSSalesApp) => {
    onAppChange(app);
    localStorage[appFieldName] = msSalesApps.findIndex((a) => a === app);
    onDismiss();
  };

  const onRenderCell = (app: IMSSalesApp): JSX.Element => {
    return (
      <div className={classNames.appItem} onClick={() => onAppItemClick(app)}>
        {app.name}
      </div>
    );
  };

  return (
    <Callout target={target} onDismiss={onDismiss} beakWidth={0}>
      <List className={classNames.appList} items={msSalesApps} onRenderCell={onRenderCell} />
    </Callout>
  );
};

export default AppLauncherCallout;
