import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Portal from "./portal/Portal";
import classNames from "./portal/Portal.module.scss";
import { ThemeProvider } from "@fluentui/react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

initializeIcons();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider className={classNames.themeProvider}>
      <MsalProvider instance={msalInstance}>
        <Portal />
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
