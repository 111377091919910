import React from "react";
import classNames from "./FileExplorer.module.scss";
import { IconButton } from "@fluentui/react";
import FileActions from "./FileActions";

export interface IFileExplorerActionsProps {
  onPrevItem: () => void;
  onNextItem: () => void;
  onCollapseAll?: () => void;
  onExpandAll?: () => void;
}

export const FileExplorerActions = (props: IFileExplorerActionsProps) => {
  const { onCollapseAll, onExpandAll, onPrevItem, onNextItem } = props;

  return (
    <div className={classNames.commandBar}>
      <div className={classNames.leftActionPane}>
        {onCollapseAll && (
          <IconButton
            iconProps={{
              iconName: "DoubleChevronUp",
              className: classNames.iconButton,
            }}
            title="Collapse all"
            onClick={onCollapseAll}
          />
        )}
        {onExpandAll && (
          <IconButton
            iconProps={{
              iconName: "DoubleChevronDown",
              className: classNames.iconButton,
            }}
            title="Expand all"
            onClick={onExpandAll}
          />
        )}
        <IconButton
          iconProps={{
            iconName: "Previous",
            className: classNames.iconButton,
          }}
          title="Previous Item"
          onClick={onPrevItem}
        />
        <IconButton
          iconProps={{
            iconName: "Next",
            className: classNames.iconButton,
          }}
          title="Next Item"
          onClick={onNextItem}
        />
      </div>
      <div className={classNames.rightActionPane}>
        <FileActions />
      </div>
    </div>
  );
};

export default FileExplorerActions;
