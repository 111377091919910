import React from "react";
import { Icon } from "@fluentui/react";
import classNames from "./AppSearch.module.scss";
import { IFilterItem } from "../app/App.types";
import { FilterType } from "./AppSearch.helper";

export interface IFilterItemsProps {
  items: IFilterItem[];
  onFilterTypeRemove: (filterType: FilterType) => void;
}

export const FilterItems = (props: IFilterItemsProps) => {
  const { items, onFilterTypeRemove } = props;

  return (
    <div className={classNames.filterItems}>
      {items?.map((item, index) => {
        var filterValuesText = item.filterValues?.map((values) => values.text).join(", ");
        return (
          <div key={"filterItem" + index} className={classNames.filterItem}>
            <div className={classNames.filterItemName} title={`${item.filterType}: ${filterValuesText}`}>
              {item.searchFlag && <div className={classNames.filterType}>{item.searchFlag}</div>}
              <div className={classNames.filterName}>{filterValuesText}</div>
            </div>
            <Icon
              iconName="ChromeClose"
              className={classNames.filterItemIcon}
              onClick={() => onFilterTypeRemove(item.filterType)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FilterItems;
