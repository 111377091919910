import { IColumn, IDialogProps } from "@fluentui/react";
import React from "react";
import { getReportingFreqHistory } from "../helpers/apiHelper";
import ItemListDialog from "./ItemListDialog";

export interface IReportingFreqHistoryDialogProps extends IDialogProps {
  sourceFileId: number;
}

export const ReportingFreqHistoryDialog = (props: IReportingFreqHistoryDialogProps) => {
  const { sourceFileId } = props;

  return (
    <ItemListDialog
      dialogTitle="Reporting Frequency History"
      dataTableName="reportingFreqHistory"
      dataFieldName="sourceFileId"
      dataFieldValue={sourceFileId}
      getData={getReportingFreqHistory}
      columns={columns}
      {...props}
      minWidth={540}
    />
  );
};

export default ReportingFreqHistoryDialog;

const columns: IColumn[] = [
  {
    fieldName: "ReportingFrequency",
    key: "ReportingFrequency",
    name: "Frequency",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    fieldName: "StartDate",
    key: "StartDate",
    name: "Start Date",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    fieldName: "EndDate",
    key: "EndDate",
    name: "End Date",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    fieldName: "ReportingActivity",
    key: "ReportingActivity",
    name: "Reporting Activity",
    minWidth: 100,
    maxWidth: 100,
  },
];
