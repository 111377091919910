import React, { useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  Icon,
  IDialogProps,
  Link,
  List,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import classNames from "./SortedItemsPicker.module.scss";
import { IItem } from "./SortedItemsPicker";
import { getSortedItems } from "./SortedItemsPicker.helper";

export interface ISortedItemsPickerDialog extends IDialogProps {
  items: IItem[];
  selectedKeys: string;
  label?: string;
  hideListActions?: boolean;
  onCommitClick: (selectedKeys: string) => void;
}

export const SortedItemsPickerDialog = (props: ISortedItemsPickerDialog) => {
  const { items, selectedKeys, label, hideListActions, onDismiss, onCommitClick } = props;
  const [newSelectedKeys, setNewSelectedKeys] = useState<string>(selectedKeys);
  const [searchText, setSearchText] = useState<string>();
  const selectedKeyValues = newSelectedKeys && newSelectedKeys.split(",");
  const selectedCount = selectedKeyValues?.length;
  const dialogContentProps = {
    title: "Select " + (label || "Sorted Items"),
    subText: "Hover over selected items and use the up/down arrow to change sort order.",
  };

  useEffect(() => document.querySelector("#itemListPane")?.scrollTo({ top: 0 }), [items, newSelectedKeys]);

  const sortedItems = useMemo(
    () =>
      getSortedItems(items, newSelectedKeys).filter(
        (item) => !searchText || item.text?.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
      ),
    [searchText, items, newSelectedKeys]
  );

  const onItemCheckboxChange = (item: IItem, checked: boolean) => {
    let newKeyValues = [...selectedKeyValues],
      itemKey = item.key.toString();

    if (checked) {
      newKeyValues.push(itemKey);
    } else {
      let index = newKeyValues.indexOf(itemKey);
      newKeyValues.splice(index, 1);
    }

    setNewSelectedKeys(newKeyValues.join(","));
  };

  const onMoveItem = (index: number, newIndex: number) => {
    let newKeyValues = [...selectedKeyValues],
      item = newKeyValues.splice(index, 1);

    newKeyValues.splice(newIndex, 0, item[0]);

    setNewSelectedKeys(newKeyValues.join(","));
  };

  const onOkClick = () => {
    onCommitClick(newSelectedKeys);
    onDismiss();
  };

  const onSelectAllClick = () => {
    setNewSelectedKeys(items?.map((item) => item.key).join(","));
  };

  const onClearAllClick = () => {
    setNewSelectedKeys("");
  };

  const onSearchTextChange = (ev, newValue) => {
    setSearchText(newValue);
  };

  const onRenderCell = (item: IItem, index: number): JSX.Element => {
    return (
      <div key={item.key + index.toString()} className={classNames.item}>
        <Checkbox
          label={item.text}
          checked={item.selected}
          onChange={(ev, checked) => onItemCheckboxChange(item, checked)}
        />
        <div className={classNames.actionPane}>
          {item.selected && (
            <>
              {index < selectedCount - 1 && (
                <Icon
                  className={classNames.actionIcon}
                  iconName="SortDown"
                  title="Move item down"
                  onClick={() => onMoveItem(index, index + 1)}
                />
              )}
              {index !== 0 && (
                <Icon
                  className={classNames.actionIcon}
                  iconName="SortUp"
                  title="Move item up"
                  onClick={() => onMoveItem(index, index - 1)}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      modalProps={{ className: classNames.dialog }}
      {...props}
      dialogContentProps={dialogContentProps}
      minWidth={540}
    >
      {!hideListActions && (
        <div className={classNames.listActionPane}>
          <Link onClick={onSelectAllClick}>Select all</Link>
          <Link onClick={onClearAllClick}>Clear all</Link>
        </div>
      )}
      <TextField
        className={classNames.searchText}
        value={searchText}
        onChange={onSearchTextChange}
        placeholder="Search items"
      />
      <div id="itemListPane" className={classNames.itemListPane} data-is-scrollable="true">
        <List className={classNames.itemList} items={sortedItems} onRenderCell={onRenderCell} />
      </div>
      <DialogFooter>
        <PrimaryButton onClick={onOkClick} text="Ok" />
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default SortedItemsPickerDialog;
