import React, { useContext } from "react";
import AlertDialog, { IAlertDialogProps } from "../../../shared/components/CommonDialog/AlertDialog";
import { startCase } from "../../../shared/helpers/miscHelper";
import { AppContext } from "../../app/App";
import { sellinErrorColumns } from "../../app/UserOptions.helper";
import classNames from "./ErrorCorrection.module.scss";

export interface IRecordDetailsDialogProps extends IAlertDialogProps {
  recordItem: object;
}

export const RecordDetailsDialog = (props: IRecordDetailsDialogProps) => {
  const { recordItem } = props;
  const { userClass } = useContext(AppContext).appState;

  const errorColumns = userClass === "C" ? getChannelErrorColumns(recordItem) : sellinErrorColumns;

  return (
    <AlertDialog {...props} headerText="View Record Details" minWidth={520}>
      <div className={classNames.recordDetailsPane}>
        <div className={classNames.list}>
          {errorColumns.map((fieldName, index) => (
            <div key={fieldName + index} className={classNames.field}>
              <div className={classNames.name}>{startCase(fieldName)}:</div>
              <div className={classNames.value}>
                {recordItem?.hasOwnProperty(fieldName) ? recordItem[fieldName] : ""}
              </div>
            </div>
          ))}
        </div>
      </div>
    </AlertDialog>
  );
};

export default RecordDetailsDialog;

const getChannelErrorColumns = (recordItem: object): string[] => Object.keys(recordItem);
