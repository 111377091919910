import React, { useState, useContext, useCallback } from "react";
import { SearchBox, Callout, DirectionalHint, FocusZone, FocusZoneDirection, List } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import classNames from "./AutoCompleteSearch.module.scss";
import { AppContext } from "../app/App";
import { ISuggestionItem } from "../app/App.types";
import { FilterType } from "./AppSearch.helper";

const maxSearchCountBySearchFlag = 30;
const maxSearchCountOverall = 10;

export interface IAutoCompleteSearchProps {
  onFilterItemChange?: (
    filterType: FilterType,
    key: string,
    text: string,
    selected: boolean,
    searchFlag?: string
  ) => void;
}

export const AutoCompleteSearch = (props: IAutoCompleteSearchProps) => {
  const { onFilterItemChange } = props;
  const [searchText, setSearchText] = useState("");
  const [showCallout, setShowCallout] = useState(false);
  const [suggestions, setSuggestions] = useState<ISuggestionItem[]>([]);
  const { domains, filterDefinitions } = useContext(AppContext).appState;
  const searchBoxId = useId("searchBox");
  const searchCalloutId = useId("searchCallout");
  const searchListId = useId("searchList");

  const onSearchBoxChange = (event?: any, newValue?: string) => {
    var searchText = newValue || "";
    generateSuggestions(searchText);
    setSearchText(searchText);
    setShowCallout(searchText?.length >= 2);
  };

  const onSearchBoxClear = () => {
    setSearchText("");
    setShowCallout(false);
  };

  const generateSuggestions = (searchText: string) => {
    var suggestions = [];
    searchText = searchText.toLocaleLowerCase();

    const searchFlags = filterDefinitions?.filter((fd) => fd.useAsSuggestion).map((fd) => fd.searchFlag),
      searchFlag = searchText.substring(0, searchText.indexOf(":") + 1),
      useSearchFlag = searchFlags?.indexOf(searchFlag) >= 0;

    if (searchText.length > 2 && searchText.startsWith("f:")) {
      var fileId = Number(searchText.substring(2));
      suggestions = suggestions.concat([
        { filterType: FilterType.fileId, key: fileId, text: fileId, searchFlag: "f:" },
      ]);
    }

    if (searchText.length > 3 && searchText.startsWith("si:")) {
      var sourceOrgId = Number(searchText.substring(3));
      suggestions = suggestions.concat([
        { filterType: FilterType.sourceOrgId, key: sourceOrgId, text: sourceOrgId, searchFlag: "si:" },
      ]);
    }

    filterDefinitions
      ?.filter(
        (fd) => fd.useAsSuggestion && fd.filterType !== FilterType.fileId && fd.filterType !== FilterType.sourceOrgId
      )
      ?.forEach((fd) => {
        suggestions = suggestions.concat(
          findMatchingItems(
            fd.filterType,
            fd.tableName,
            fd.keyName,
            fd.textNames[0],
            fd.searchFlag,
            useSearchFlag,
            searchText
          )
        );
      });

    setSuggestions(suggestions);
  };

  const findMatchingItems = useCallback(
    (
      filterType: FilterType,
      tableName: string,
      keyFieldName: string,
      searchFieldName: string,
      searchFlag: string,
      useSearchFlag: boolean,
      searchText: string
    ): ISuggestionItem[] => {
      if (useSearchFlag && !searchText.startsWith(searchFlag)) {
        return [];
      }

      if (useSearchFlag) {
        searchText = searchText.replace(searchFlag, "").trim();
      }

      var returnCount = useSearchFlag ? maxSearchCountBySearchFlag : maxSearchCountOverall;
      var matchingItems = [];
      var items = domains && domains[tableName];

      if (items?.length) {
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          var value: string = item[searchFieldName]?.toString().toLocaleLowerCase();

          if (value?.indexOf(searchText) >= 0) {
            matchingItems.push({
              filterType,
              key: item[keyFieldName].toString(),
              text: item[searchFieldName],
              searchFlag,
              activeFlag: item["ActiveFlag"],
            });
            if (matchingItems.length === returnCount) {
              break;
            }
          }
        }
      }

      return matchingItems;
    },
    [domains]
  );

  const onSuggestionItemClick = (item: ISuggestionItem) => {
    onFilterItemChange(item.filterType, item.key, item.text, true, item.searchFlag);
    onSearchBoxClear();
  };

  const onSearchKeyDown = (ev: React.KeyboardEvent<HTMLElement>) => {
    const keyCode = ev.which;
    switch (keyCode) {
      case 40:
        let el: any = window.document.querySelector(`#${searchListId}`);
        el.focus();
        break;
    }
  };

  const onSuggestionRenderCell = (item: ISuggestionItem) => {
    let isActive = item.activeFlag !== 0,
      valueClassName = isActive ? "" : classNames.inactive;

    return (
      <div
        key={"autoCompleteItem" + item.filterType + item.key}
        className={classNames.autoCompleteItem}
        data-is-focusable={true}
        onClick={() => onSuggestionItemClick(item)}
      >
        <div className={classNames.autoCompleteItemType}>{item.filterType}</div>
        <div className={`${classNames.autoCompleteItemValue} ${valueClassName}`}>{item.text}</div>
        {!isActive && <span className={classNames.inactiveLabel}>inactive</span>}
      </div>
    );
  };

  return (
    <div onKeyDown={onSearchKeyDown}>
      <SearchBox
        id={searchBoxId}
        className={classNames.searchBox}
        autoComplete="off"
        value={searchText}
        onChange={onSearchBoxChange}
        onClear={onSearchBoxClear}
      />
      <Callout
        id={searchCalloutId}
        target={`#${searchBoxId}`}
        className={classNames.callout}
        gapSpace={2}
        coverTarget={false}
        alignTargetEdge={true}
        onDismiss={(ev) => setShowCallout(false)}
        setInitialFocus={false}
        hidden={!showCallout}
        directionalHintFixed
        directionalHint={DirectionalHint.bottomLeftEdge}
        isBeakVisible={false}
      >
        <FocusZone direction={FocusZoneDirection.vertical}>
          <List id={searchListId} tabIndex={0} items={suggestions} onRenderCell={onSuggestionRenderCell} />
        </FocusZone>
      </Callout>
    </div>
  );
};

export default AutoCompleteSearch;
