import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DataForm } from "../../../shared/components/DataForm";
import ConfigForm from "../ConfigForm";
import classNames from "./ErrorCorrection.module.scss";
import { AppContentContext, IErrorTypeInfo } from "../AppContent";
import { AppContext } from "../../app/App";
import {
  ConstrainMode,
  ContextualMenu,
  ContextualMenuItemType,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Icon,
  IContextualMenuItem,
  IDropdownOption,
  SearchBox,
  SelectionMode,
  Spinner,
  TextField,
} from "@fluentui/react";
import { debounce, formatNumber } from "../../../shared/helpers/miscHelper";
import {
  getErrorFields,
  getErrorColumns,
  getFilteredItems,
  getErrorRecordKey,
  getErrorRecordInfo,
  getTargetDropdownOptions,
  getFieldDomainName,
  lookupMsgIdFieldName,
  FixLevelSuffix,
  channelSourceFixLevelColumns,
  sellinSourceFixLevelColumns,
  channelFileFixLevelColumns,
  sellinFileFixLevelColumns,
  IsValueChangedSuffix,
  OriginalPrefix,
  channelKeepAsIsColumns,
  sellinKeepAsIsColumns,
  channelForceColumns,
  sellinForceColumns,
  ValueChangedSuffix,
} from "./ErrorCorrection.helper";
import ProductErrorDialog from "./ProductErrorDialog";
import { fixErrors, getChannelErrorRow } from "../../helpers/apiHelper";
import { useWindowSize } from "../../../shared/helpers/hooks";
import RecordDetailsDialog from "./RecordDetailsDialog";

const basicCheckIconProps = { iconName: "SkypeCircleCheck", style: { color: "green" } };
const fileFixLevelIconProps = { iconName: "SkypeCircleCheck", style: { color: "#0078d4" } };
const sourceFixLevelIconProps = { iconName: "SkypeCircleCheck", style: { color: "darkmagenta" } };

export interface IValueSelection {
  transmissionId: number;
  transmissionRowId: number;
  [prop: string]: string | number | boolean;
}

export interface IErrorCorrectionProps {
  loadingFileErrors: boolean;
  onErrorTypeSelect: (errorType: IErrorTypeInfo) => void;
}

export const ErrorCorrection = (props: IErrorCorrectionProps) => {
  const { loadingFileErrors, onErrorTypeSelect } = props;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedErrorRecord, setSelectedErrorRecord] = useState<object>();
  const [selectedErrorRow, setSelectedErrorRow] = useState<object>();
  const [showErrorCorrectionDialog, setShowErrorCorrectionDialog] = useState<boolean>();
  const [searchText, setSearchText] = useState<string>();
  const [selectedValues, setSelectedValues] = useState<IValueSelection[]>([]);
  const [showSelectedItemMenu, setShowSelectedItemMenu] = useState<boolean>(false);
  const [showRecordDetailsDialog, setShowRecordDetailsDialog] = useState<boolean>(false);
  const [selectedItemMenuId, setSelectedItemMenuId] = useState<string>();
  const [selectedItemMenuItems, setSelectedItemMenuItems] = useState<IContextualMenuItem[]>();
  const { appState, onErrorChange, changeAppState } = useContext(AppContext);
  const { userClass, selectedSource, selectedTransmission, domains } = appState;
  const { fileErrors, selectedErrorType, errorSummaryItems, reloadFileErrors } = useContext(AppContentContext);
  const recordCount = errorSummaryItems.find((i) => i.errorTypeId === selectedErrorType?.errorTypeId)?.recordCount;
  const { errorTypeId, errorCategory: category } = selectedErrorType || {};
  const hasNoError = errorTypeId === undefined;
  const useProductDialog = selectedErrorRecord && category === "Product";
  const windowSize = useWindowSize();
  const isDirty = selectedValues?.length > 0;

  const columns = useMemo(
    () => getErrorColumns(errorTypeId, fileErrors, selectedSource, selectedTransmission, userClass),
    [errorTypeId, fileErrors, selectedSource, selectedTransmission, userClass]
  );

  const targetColumnFieldName = columns?.length && columns[0].fieldName;

  const formContext = {
    sourceOrgId: selectedSource?.id,
    transmissionId: selectedTransmission?.id,
    errorTypeId,
    category,
    recordCount,
    recordReturned: fileErrors && formatNumber(fileErrors?.length),
  };

  const filteredFileErrors = useMemo(
    () => getFilteredItems(fileErrors, searchText, columns, selectedValues, domains, selectedErrorRecord),
    [fileErrors, searchText, columns, selectedValues, selectedErrorRecord, domains]
  );

  useEffect(() => {
    changeAppState({ isDirty });
  }, [isDirty, changeAppState]);

  // useCallback is needed as this is passed as prop to child component.
  const onValueSelect = useCallback(
    (values: object) => {
      let newValues = selectedValues.slice(),
        { sourceOrgId, transmissionId, transmissionRowId, existingValueIndex } = getErrorRecordInfo(
          selectedErrorRecord,
          selectedValues
        );

      if (existingValueIndex >= 0) {
        let selectedValue = newValues[existingValueIndex],
          originalFieldName = OriginalPrefix + values["targetFieldName"],
          originalValue = selectedValue[originalFieldName];

        // Keep the original value if it's already defined.
        if (originalValue !== undefined) {
          delete values[originalFieldName];
        }

        // Update existing selectedValue with new values.
        newValues[existingValueIndex] = { ...selectedValue, ...values };
      } else {
        // Create new selectedValue.
        newValues.push({
          sourceOrgId,
          transmissionId,
          transmissionRowId,
          ...values,
        });
      }

      setSelectedValues(newValues);
    },
    [selectedValues, selectedErrorRecord]
  );

  const onValueUnselect = useCallback(
    (fieldName) => {
      let { existingValueIndex } = getErrorRecordInfo(selectedErrorRecord, selectedValues);

      if (existingValueIndex >= 0) {
        // First delete the original props of the unselected field.
        delete selectedValues[existingValueIndex][fieldName];
        delete selectedValues[existingValueIndex][OriginalPrefix + fieldName];
        delete selectedValues[existingValueIndex][fieldName + FixLevelSuffix];
        delete selectedValues[existingValueIndex][lookupMsgIdFieldName(fieldName)];

        // Then, if there is no more field with IsValueChanged, delete the entire selected value.
        let changedValues = Object.keys(selectedValues[existingValueIndex]).find((propName) =>
          propName.startsWith(OriginalPrefix)
        );

        if (!changedValues) {
          selectedValues.splice(existingValueIndex, 1);
        }

        setSelectedValues(selectedValues.slice());
      }
    },
    [selectedValues, selectedErrorRecord]
  );

  const onValueUnfixable = useCallback(() => {
    let { sourceOrgId, transmissionId, transmissionRowId, existingValueIndex } = getErrorRecordInfo(
      selectedErrorRecord,
      selectedValues
    );

    if (existingValueIndex >= 0) {
      selectedValues.splice(existingValueIndex, 1);
    }
    selectedValues.push({
      sourceOrgId,
      transmissionId,
      transmissionRowId,
      isUnfixable: true,
    });
    setSelectedValues(selectedValues.slice());
  }, [selectedValues, selectedErrorRecord]);

  const onFieldValueChange = (fieldName: string, newValue: any) => {
    if (fieldName === "errorTypeId") {
      var errorCategory = errorSummaryItems.find((i) => i.errorTypeId === newValue)?.category;

      setSearchText(undefined);
      setSelectedValues([]);
      setSelectedErrorRecord(undefined);
      onErrorTypeSelect({ errorTypeId: newValue, errorCategory });
      onErrorChange(undefined);
    }
  };

  const onSearchBoxChange = (event?: any, newValue?: string) => {
    setSearchText(newValue);
  };

  const onViewRecordDetails = useCallback(() => {
    if (userClass === "S") {
      setShowRecordDetailsDialog(true);
    } else {
      getChannelErrorRow({
        transmission: selectedErrorRecord["TransmissionID"],
        rowId: selectedErrorRecord["TransmissionRowID"],
      })
        .then((result) => {
          if (result && result["errorRow"]?.length) {
            setSelectedErrorRow(result["errorRow"][0]);
          }
          setShowRecordDetailsDialog(true);
        })
        .catch((error) => onErrorChange(error));
    }
  }, [userClass, selectedErrorRecord, onErrorChange]);

  const getSelectedItemMenuItems = (item, fieldName) => {
    let menuItems = [],
      newValue = {},
      fixLevelFieldName = fieldName + FixLevelSuffix,
      originalFieldName = OriginalPrefix + fieldName,
      msgIdFieldName = lookupMsgIdFieldName(fieldName),
      sourceFixLevelColumns = userClass === "C" ? channelSourceFixLevelColumns : sellinSourceFixLevelColumns,
      fileFixLevelColumns = userClass === "C" ? channelFileFixLevelColumns : sellinFileFixLevelColumns,
      keepAsIsColumns = userClass === "C" ? channelKeepAsIsColumns : sellinKeepAsIsColumns,
      forceColumns = userClass === "C" ? channelForceColumns : sellinForceColumns,
      isSourceFixLevelSupported = sourceFixLevelColumns.indexOf(fieldName) >= 0,
      isFileFixLevelSupported = fileFixLevelColumns.indexOf(fieldName) >= 0,
      isFixLevelSupported = isSourceFixLevelSupported || isFileFixLevelSupported,
      isKeepAsIsSupported = keepAsIsColumns.indexOf(fieldName) >= 0,
      isForceSupported = forceColumns.indexOf(fieldName) >= 0;

    let selectedValue = selectedValues?.find(
      (v) => v.transmissionId === item["TransmissionID"] && v.transmissionRowId === item["TransmissionRowID"]
    );

    if (selectedValue && !selectedValue.isUnfixable) {
      if (isFixLevelSupported) {
        const fixLevel = selectedValue[fixLevelFieldName];

        isSourceFixLevelSupported &&
          menuItems.push({
            key: "sourceLevel",
            text: "Source Level Change",
            iconProps: fixLevel === "S" ? sourceFixLevelIconProps : undefined,
            onClick: () => {
              newValue[fixLevelFieldName] = "S";
              onValueSelect(newValue);
            },
          });

        isFileFixLevelSupported &&
          menuItems.push({
            key: "fileLevel",
            text: "File Level Change",
            iconProps: fixLevel === "F" ? fileFixLevelIconProps : undefined,
            onClick: () => {
              newValue[fixLevelFieldName] = "F";
              onValueSelect(newValue);
            },
          });

        menuItems.push({
          key: "recordLevel",
          text: "Record Level Change",
          iconProps: fixLevel !== "F" && fixLevel !== "S" ? basicCheckIconProps : undefined,
          onClick: () => {
            newValue[fixLevelFieldName] = "R";
            onValueSelect(newValue);
          },
        });

        menuItems.push({
          key: "fixLevelDivider",
          itemType: ContextualMenuItemType.Divider,
        });
      }
    }

    const itemFixLevel = item[fixLevelFieldName];
    const keepAsIsSelected = selectedValue && selectedValue[msgIdFieldName] === 2,
      showKeepAsIsMenu =
        isKeepAsIsSupported && (!selectedValue || keepAsIsSelected) && (selectedValue || !itemFixLevel);

    showKeepAsIsMenu &&
      menuItems.push({
        key: "keepAsIs",
        text: "Keep As Is",
        iconProps: keepAsIsSelected ? basicCheckIconProps : undefined,
        onClick: () => {
          newValue[msgIdFieldName] = 2;
          newValue[fieldName] = item[fieldName];
          newValue[originalFieldName] = item[fieldName];
          keepAsIsSelected ? onValueUnselect(newValue) : onValueSelect(newValue);
        },
      });

    const forceSelected = selectedValue && selectedValue[msgIdFieldName] === 1,
      showForceMenu = isForceSupported && (!selectedValue || forceSelected) && (selectedValue || !itemFixLevel);

    showForceMenu &&
      menuItems.push({
        key: "force",
        text: "Force",
        iconProps: forceSelected ? basicCheckIconProps : undefined,
        onClick: () => {
          newValue[msgIdFieldName] = 1;
          newValue[fieldName] = item[fieldName];
          newValue[originalFieldName] = item[fieldName];
          forceSelected ? onValueUnselect(newValue) : onValueSelect(newValue);
        },
      });

    (showKeepAsIsMenu || showForceMenu) &&
      menuItems.push({
        key: "keepForceDivider",
        itemType: ContextualMenuItemType.Divider,
      });

    useProductDialog &&
      !item[fieldName + IsValueChangedSuffix] &&
      !selectedValue?.isUnfixable &&
      menuItems.push({ key: "unfixable", text: "Unfixable", onClick: onValueUnfixable });

    selectedValue &&
      menuItems.push({ key: "undoChange", text: "Undo Change", onClick: () => onValueUnselect(fieldName) });

    selectedErrorRecord &&
      menuItems.push({ key: "viewDetails", text: "View Record Details", onClick: onViewRecordDetails });

    return menuItems;
  };

  const resetSelectedValues = useCallback(() => {
    setSelectedValues([]);
    setSelectedErrorRecord(undefined);
  }, []);

  const onSaveButtonClick = useCallback(() => {
    if (selectedValues?.length) {
      setIsSaving(true);
      fixErrors(selectedValues, userClass)
        .then(() => {
          resetSelectedValues();
          reloadFileErrors();
        })
        .catch((error) => onErrorChange(error))
        .finally(() => setIsSaving(false));
    }
  }, [onErrorChange, resetSelectedValues, reloadFileErrors, selectedValues, userClass]);

  const onCancelButtonClick = () => {
    resetSelectedValues();
  };

  const onErrorValueChange = useCallback(
    (sourceOrgId, transmissionId, transmissionRowId, originalValue, column, newValue) => {
      let targetFieldName = column.fieldName,
        originalFieldName = OriginalPrefix + targetFieldName,
        selectedValue = { sourceOrgId, transmissionId, transmissionRowId, targetFieldName };

      selectedValue[originalFieldName] = originalValue;
      selectedValue[targetFieldName] = newValue;
      onValueSelect(selectedValue);
    },
    [onValueSelect]
  );

  const onTextFieldChange = useCallback(
    (sourceOrgId, transmissionId, transmissionRowId, originalValue, column, newValue) => {
      debounce(
        onErrorValueChange(sourceOrgId, transmissionId, transmissionRowId, originalValue, column, newValue),
        500
      );
    },
    [onErrorValueChange]
  );

  const onRenderItemColumn = (item, index, column) => {
    let isTargetColumn = column.className === classNames.targetColumn,
      sourceOrgId = item["SourceOrgID"],
      transmissionId = item["TransmissionID"],
      transmissionRowId = item["TransmissionRowID"],
      isSelectedItem =
        selectedErrorRecord &&
        item &&
        selectedErrorRecord["TransmissionID"] === transmissionId &&
        selectedErrorRecord["TransmissionRowID"] === transmissionRowId,
      fieldName = column.fieldName,
      fixLevelFieldName = fieldName + FixLevelSuffix,
      valueChangeFieldName = fieldName + IsValueChangedSuffix,
      msgIdFieldName = lookupMsgIdFieldName(fieldName),
      itemValue = item[fieldName] || "",
      selectedValue = selectedValues?.find(
        (v) =>
          v.transmissionId === transmissionId &&
          v.transmissionRowId === transmissionRowId &&
          (v.hasOwnProperty(fieldName) || v.hasOwnProperty(msgIdFieldName))
      ),
      msgIdSelectedValue =
        (selectedValue && selectedValue[msgIdFieldName]) || item[msgIdFieldName + ValueChangedSuffix],
      targetDropdownDomain = getFieldDomainName(fieldName),
      targetDropdownOptions = getTargetDropdownOptions(domains, targetDropdownDomain),
      fieldNeedFix = item[msgIdFieldName] !== 0 && item[msgIdFieldName] !== null,
      valueChangedClassNames = column.className ? [column.className] : [],
      cellContent = <div className={fieldNeedFix ? column.className : classNames.fixNotNeeded}>{itemValue}</div>,
      disableEditing = errorTypeId === 18, // Disable editing for Revenue error.
      isValueChanged = item[valueChangeFieldName] || msgIdSelectedValue || item.isUnfixable;

    isValueChanged && valueChangedClassNames.push(classNames.valueChangedColumn);
    item.isUnfixable && valueChangedClassNames.push(classNames.unfixable);
    item[fixLevelFieldName] === "F" && valueChangedClassNames.push(classNames.fileFixLevel);
    item[fixLevelFieldName] === "S" && valueChangedClassNames.push(classNames.sourceFixLevel);
    item["ProductTranslationTypeID"] === 1 && valueChangedClassNames.push(classNames.matchedToProductId);
    item["ProductTranslationTypeID"] === 2 && valueChangedClassNames.push(classNames.matchedToSku);
    item["ProductTranslationTypeID"] === 3 && valueChangedClassNames.push(classNames.matchedToUpc);
    msgIdSelectedValue === 1 && valueChangedClassNames.push(classNames.force);
    msgIdSelectedValue === 2 && valueChangedClassNames.push(classNames.keepAsIs);
    selectedValue && valueChangedClassNames.push(classNames.selected);

    if (isTargetColumn && fieldNeedFix) {
      let selectedItemMenuItems = getSelectedItemMenuItems(item, fieldName),
        itemMenuIconId = `itemMenuIcon_${transmissionId}_${transmissionRowId}_${fieldName}_${index}`,
        isUnfixable = item?.isUnfixable;

      cellContent = (
        <div className={classNames.valueChangedItemPane}>
          {!useProductDialog &&
          isSelectedItem &&
          (selectedValue || !item[fixLevelFieldName]) &&
          !msgIdSelectedValue &&
          !disableEditing ? (
            <div className={valueChangedClassNames.join(" ") + " " + classNames.valueChangedEditField}>
              {targetDropdownDomain ? (
                <Dropdown
                  className={classNames.valueChangedDropdown}
                  options={targetDropdownOptions}
                  selectedKey={itemValue}
                  dropdownWidth={300}
                  onChange={(ev, option) =>
                    onErrorValueChange(sourceOrgId, transmissionId, transmissionRowId, itemValue, column, option.key)
                  }
                  onRenderTitle={(options: IDropdownOption[]) => <span>{options?.map((o) => o.key).join(", ")}</span>}
                />
              ) : (
                <TextField
                  className={classNames.valueChangedTextbox}
                  value={itemValue}
                  styles={{ field: { fontSize: "12px", padding: "0px 4px" }, fieldGroup: { height: 28 } }}
                  onChange={(ev, newValue) =>
                    onTextFieldChange(sourceOrgId, transmissionId, transmissionRowId, itemValue, column, newValue)
                  }
                />
              )}
            </div>
          ) : isValueChanged ? (
            <div className={valueChangedClassNames.join(" ")}>
              {/* Note: Below, using "input" as a hack to keep the text with proper text ellipsis on long text. */}
              <input className={classNames.valueChangedText} value={itemValue} disabled />
            </div>
          ) : (
            cellContent
          )}
          <div className={classNames.itemActionsPane}>
            {useProductDialog && isTargetColumn && isSelectedItem && !isUnfixable && (
              <Icon
                className={classNames.errorEditButton}
                iconName="Edit"
                title="Edit error value of the selected record"
                onClick={() => setShowErrorCorrectionDialog(true)}
              />
            )}
            {!!selectedItemMenuItems?.length && isSelectedItem && (
              <Icon
                id={itemMenuIconId}
                className={classNames.valueChangedActionsIcon}
                iconName="More"
                onClick={() => {
                  setSelectedItemMenuId(itemMenuIconId);
                  setSelectedItemMenuItems(selectedItemMenuItems);
                  setShowSelectedItemMenu(true);
                }}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={classNames.itemCellPane} title={itemValue}>
        {cellContent}
      </div>
    );
  };

  const onRenderRow = (props) => {
    const item = props.item,
      isSelectedItem =
        selectedErrorRecord &&
        item &&
        selectedErrorRecord["TransmissionID"] === item["TransmissionID"] &&
        selectedErrorRecord["TransmissionRowID"] === item["TransmissionRowID"],
      rowStyles = isSelectedItem ? { root: { backgroundColor: "#f0f8ff" } } : {};

    return (
      <DetailsRow
        {...props}
        styles={rowStyles}
        onClick={() => {
          !isSelectedItem && setSelectedErrorRecord(item);
          setSelectedErrorRow(undefined);
          onErrorChange(undefined);
        }}
        onMouseEnter={() => setShowSelectedItemMenu(false)}
      />
    );
  };

  let formTitle = "Error Correction";

  if (selectedTransmission) {
    formTitle += ` for ${selectedTransmission.name}`;
  } else if (selectedSource) {
    formTitle += ` for ${selectedSource.name}`;
  } else {
    formTitle += " for All Files";
  }

  return (
    <ConfigForm
      className={classNames.configForm}
      title={formTitle}
      compact
      isDirty={isDirty}
      isSaving={isSaving}
      isEditable={!hasNoError}
      onSaveButtonClick={onSaveButtonClick}
      onCancelButtonClick={onCancelButtonClick}
    >
      {hasNoError && <div className={classNames.textPane}>No error found</div>}
      {!hasNoError && (
        <div className={classNames.infoPane}>
          <div className={classNames.infoLeftPane}>
            <DataForm
              context={formContext}
              fields={getErrorFields(errorSummaryItems)}
              onFieldValueChange={onFieldValueChange}
            />
          </div>
          <div className={classNames.infoRightPane}>
            {!!fileErrors?.length && (
              <SearchBox className={classNames.searchBox} value={searchText} onChange={onSearchBoxChange} />
            )}
          </div>
        </div>
      )}
      {loadingFileErrors && <Spinner label="Loading data..." />}
      {!loadingFileErrors && !!fileErrors?.length && (
        <div className={classNames.fileErrorsPane}>
          <div
            className={classNames.fileErrorsTable}
            data-is-scrollable="true"
            style={{ maxHeight: windowSize.height - 420 }}
          >
            <DetailsList
              items={filteredFileErrors}
              columns={columns}
              compact
              selectionMode={SelectionMode.none}
              constrainMode={ConstrainMode.unconstrained}
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              onRenderItemColumn={onRenderItemColumn}
              onRenderRow={onRenderRow}
              getKey={(item) => getErrorRecordKey(item)}
            />
          </div>
          <ContextualMenu
            items={selectedItemMenuItems}
            hidden={!showSelectedItemMenu}
            target={`#${selectedItemMenuId}`}
            gapSpace={2}
            onDismiss={() => setShowSelectedItemMenu(false)}
          />
        </div>
      )}
      {showErrorCorrectionDialog && (
        <ProductErrorDialog
          selectedErrorRecord={selectedErrorRecord}
          columns={columns}
          targetColumnFieldName={targetColumnFieldName}
          hidden={!showErrorCorrectionDialog}
          onDismiss={() => setShowErrorCorrectionDialog(false)}
          onValueSelect={onValueSelect}
        />
      )}
      {showRecordDetailsDialog && (
        <RecordDetailsDialog
          recordItem={selectedErrorRow || selectedErrorRecord}
          hidden={!showRecordDetailsDialog}
          onDismiss={() => setShowRecordDetailsDialog(false)}
        />
      )}
    </ConfigForm>
  );
};

export default ErrorCorrection;
