import { Icon } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import classNames from "./SortedItemsPicker.module.scss";
import { getSelectedItems } from "./SortedItemsPicker.helper";
import SortedItemsPickerDialog from "./SortedItemsPickerDialog";

export interface IItem {
  key: string | number;
  text: string;
  ariaLabel?: string;
  selected?: boolean;
}

export interface ISortedItemsPickerProps {
  items: IItem[];
  selectedKeys: string;
  id?: string;
  label?: string;
  className?: string;
  displayValuesAsText?: boolean;
  hideAction?: boolean;
  hideListActions?: boolean;
  onChange?: (selectedKeys: string) => void;
}

export const SortedItemsPicker = (props: ISortedItemsPickerProps) => {
  const { items, selectedKeys, className, label, displayValuesAsText, hideAction, hideListActions, onChange } = props;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const selectedItems = getSelectedItems(items, selectedKeys);
  const selectedTexts = selectedItems?.join(", ");

  const onDialogDismiss = useCallback(() => setShowDialog(false), []);

  const onCommitClick = useCallback((selectedKeys) => onChange(selectedKeys), [onChange]);

  return (
    <>
      <div className={`${classNames.root} ${className} SortedItemsPicker`} tabIndex={-1}>
        {displayValuesAsText ? (
          <div className={`${classNames.selectedTexts} selectedTexts`}>{selectedTexts}</div>
        ) : (
          <ul className={`${classNames.selectedItems} selectedItems`}>
            {selectedItems?.map((item, index) => (
              <li key={`item${index}`} className={classNames.selectedItem}>
                {item}
              </li>
            ))}
          </ul>
        )}
        {!hideAction && (
          <Icon
            className={`${classNames.actionIcon} actionIcon`}
            iconName="More"
            tabIndex={-1}
            onClick={() => setShowDialog(true)}
          />
        )}
      </div>
      {showDialog && (
        <SortedItemsPickerDialog
          items={items}
          selectedKeys={selectedKeys}
          label={label}
          hidden={!showDialog}
          hideListActions={hideListActions}
          onDismiss={onDialogDismiss}
          onCommitClick={onCommitClick}
        />
      )}
    </>
  );
};

export default SortedItemsPicker;
