import React, { useContext } from "react";
import { AppContext } from "../app/App";
import { IconCalloutButton } from "../../shared/components/CalloutButton/IconCalloutButton";
import classNames from "./AppSearch.module.scss";

export interface ISearchInfoButtonProps {
  className?: string;
}

export const SearchInfoButton = (props: ISearchInfoButtonProps) => {
  const { className } = props;

  return (
    <IconCalloutButton iconName="Info" title="Search flag hints" className={className}>
      <div className={classNames.searchInfoButtonContent}>
        <SearchInfoContent />
      </div>
    </IconCalloutButton>
  );
};

export const SearchInfoContent = () => {
  const { filterDefinitions } = useContext(AppContext).appState;
  return (
    <div className={classNames.searchInfoPane}>
      <div className={classNames.searchInfoHeader}>Search Flag Hints:</div>
      {filterDefinitions
        ?.filter((filterDef) => filterDef.useAsSuggestion)
        ?.map((filterDef, index) => (
          <div key={`searchInfoItem-${index}`} className={classNames.searchInfoItem}>
            <div className={classNames.searchInfoItemLabel}>{filterDef.searchFlag}</div>
            <div className={classNames.searchInfoItemNote}>{filterDef.label} Search</div>
            <div className={classNames.searchInfoItemExample}>{filterDef.searchFlagExample}</div>
          </div>
        ))}
    </div>
  );
};
