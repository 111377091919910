import CmodsApp from "../cmods/app/App";
import { UserOptions as CmodsUserOptions } from "../cmods/app/UserOptions";
import { getUserTags, userClassOptionName } from "../cmods/app/UserOptions.helper";
import MDC from "../mdc/app/MDC";
import { IMSSalesApp } from "./Portal.types";

export const msSalesApps: IMSSalesApp[] = [
  {
    name: "CMODS Modern",
    appComponent: <CmodsApp />,
    userOptionsComponent: <CmodsUserOptions />,
    defaultUserOptions: { [userClassOptionName]: localStorage[userClassOptionName] || "C" },
    getUserTags: getUserTags,
  },
  { name: "Modern Data Contract", appComponent: <MDC /> },
];
