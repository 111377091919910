import React, { useCallback, useContext } from "react";
import { ComboBox, IComboBoxOption, IDropdownOption, TextField } from "@fluentui/react";
import classNames from "./SearchFilters.module.scss";
import { AppContext } from "../app/App";
import { IDomains, IFilterItem } from "../app/App.types";
import { FilterType, IFilterDefinition } from "./AppSearch.helper";
import { SearchMode } from "../app/App.helper";

export interface ISearchFiltersProps {
  filterItems: IFilterItem[];
  fileId: string;
  sourceOrgId: string;
  onFilterItemChange?: (
    filterType: FilterType,
    key: string,
    text: string,
    selected: boolean,
    searchFlag?: string
  ) => void;
}

export const SearchFilters = (props: ISearchFiltersProps) => {
  const { filterItems, fileId, sourceOrgId, onFilterItemChange } = props,
    { domains, filterDefinitions, selectedMode } = useContext(AppContext).appState;

  const onSourceOrgIdChange = useCallback(
    (ev, newValue) => {
      onFilterItemChange(FilterType.sourceOrgId, newValue, newValue, true, "si:");
    },
    [onFilterItemChange]
  );

  const onFileIdChange = useCallback(
    (ev, newValue) => {
      onFilterItemChange(FilterType.fileId, newValue, newValue, true, "f:");
    },
    [onFilterItemChange]
  );

  const selectedActiveStatus = getSelectedKeys(filterItems, FilterType.activeStatus);

  return (
    <div className={classNames.root}>
      {filterDefinitions
        ?.filter((filterDef) => filterDef.useAsAdvancedFilter && filterDef.useInSearchModes?.indexOf(selectedMode) >= 0)
        ?.map((filterDef, index) => (
          <ComboBox
            key={`searchFilter-${index}`}
            label={filterDef.label}
            options={getFilterOptions(domains, filterItems, filterDef)}
            selectedKey={getSelectedKeys(filterItems, filterDef.filterType)}
            autoComplete="on"
            className={classNames.comboBox}
            styles={{ root: { width: filterDef.dropdownWidth } }}
            onChange={(ev, option: IComboBoxOption) =>
              onFilterItemChange(
                filterDef.filterType,
                option.key.toString(),
                option.text,
                option.selected,
                filterDef.searchFlag
              )
            }
          />
        ))}
      {selectedMode === SearchMode.source && (
        <ComboBox
          key={`searchFilter-activeStatus`}
          label="Active Status"
          options={getActiveStatusOptions(selectedActiveStatus)}
          selectedKey={selectedActiveStatus}
          autoComplete="on"
          className={classNames.comboBox}
          styles={{ root: { width: 100 } }}
          onChange={(ev, option: IComboBoxOption) =>
            onFilterItemChange(FilterType.activeStatus, option.key.toString(), option.text, option.selected)
          }
        />
      )}
      {selectedMode !== SearchMode.template && (
        <TextField
          className={classNames.textField}
          key={`searchFilter-sourceOrgId`}
          label="Source Org ID"
          value={sourceOrgId}
          styles={{ fieldGroup: { width: 100 } }}
          onChange={onSourceOrgIdChange}
        />
      )}
      {selectedMode !== SearchMode.source && selectedMode !== SearchMode.template && (
        <TextField
          className={classNames.textField}
          key={`searchFilter-fileId`}
          label="File ID"
          value={fileId}
          styles={{ fieldGroup: { width: 100 } }}
          onChange={onFileIdChange}
        />
      )}
    </div>
  );
};

export default SearchFilters;

export const getFilterOptions = (
  domains: IDomains,
  filterItems: IFilterItem[],
  filterDef: IFilterDefinition
): IComboBoxOption[] => {
  let { tableName, textNameDelimiter, keyName, filterType, dropdownItemLimit = 5000, textNames = [] } = filterDef,
    items = domains && domains[tableName],
    selectedSubsidiaryFilter = filterItems?.find((item) => item.filterType === FilterType.subsidiary),
    selectedRegionFilter = filterItems?.find((item) => item.filterType === FilterType.region),
    selectedChannelFilter = filterItems?.find((item) => item.filterType === FilterType.channel),
    selectedContactFilter = filterItems?.find((item) => item.filterType === FilterType.contact),
    selectedOrgTypeFilter = filterItems?.find((item) => item.filterType === FilterType.orgType),
    selectedSourceFilter = filterItems?.find((item) => item.filterType === FilterType.source),
    selectedStatusFilter = filterItems?.find((item) => item.filterType === FilterType.status),
    selectedFileTypeFilter = filterItems?.find((item) => item.filterType === FilterType.fileType),
    selectedOriginalityFilter = filterItems?.find((item) => item.filterType === FilterType.originality),
    selectedFreqFilter = filterItems?.find((item) => item.filterType === FilterType.freq),
    selectedActiveStatus = filterItems?.find((item) => item.filterType === FilterType.activeStatus),
    selectedSourceOrgIdFilter = filterItems?.find((item) => item.filterType === FilterType.sourceOrgId);

  // Apply other filters if source filter is used.
  if (filterType === FilterType.source) {
    items = applyFilterToDropdownItems(items, selectedSourceFilter, "SourceOrgID");
    items = applyFilterToDropdownItems(items, selectedSourceOrgIdFilter, "SourceOrgID");
    items = applyFilterToDropdownItems(items, selectedSubsidiaryFilter, "SubsidiaryID");
    items = applyFilterToDropdownItems(items, selectedChannelFilter, "ChannelID");
    items = applyFilterToDropdownItems(items, selectedContactFilter, "ContactID");
    items = applyFilterToDropdownItems(items, selectedOrgTypeFilter, "OrganizationTypeID");
    items = applyFilterToDropdownItems(items, selectedActiveStatus, "ActiveFlag");

    // If subsidiary filter is not selected and region filter is selected, filter the sources based on the subsidiaries related to the region.
    if (!selectedSubsidiaryFilter && selectedRegionFilter) {
      let relatedSubsidiaryIDs = domains?.subsidiaries
        ?.filter((sub) => sub["WWRegionID"].toString() === getFilterValues(selectedRegionFilter))
        ?.map((sub) => sub["SubsidiaryID"]);

      if (relatedSubsidiaryIDs?.length) {
        items = items.filter((item) => relatedSubsidiaryIDs.indexOf(item["SubsidiaryID"]) >= 0);
      }
    }
  }

  // Apply other filters if subsidiary filter is used.
  if (filterType === FilterType.subsidiary) {
    items = applyFilterToDropdownItems(items, selectedRegionFilter, "WWRegionID");
  }

  items && dropdownItemLimit && (items = items.slice(0, dropdownItemLimit));

  let anySelection = false,
    result = items?.map((item) => {
      let text = textNames.map((textName) => item[textName]).join(textNameDelimiter),
        key = item[keyName].toString(),
        selected =
          filterType === FilterType.subsidiary
            ? key === getFilterValues(selectedSubsidiaryFilter)
            : filterType === FilterType.region
            ? key === getFilterValues(selectedRegionFilter)
            : filterType === FilterType.channel
            ? key === getFilterValues(selectedChannelFilter)
            : filterType === FilterType.contact
            ? key === getFilterValues(selectedContactFilter)
            : filterType === FilterType.orgType
            ? key === getFilterValues(selectedOrgTypeFilter)
            : filterType === FilterType.source
            ? key === getFilterValues(selectedSourceFilter)
            : filterType === FilterType.status
            ? key === getFilterValues(selectedStatusFilter)
            : filterType === FilterType.fileType
            ? key === getFilterValues(selectedFileTypeFilter)
            : filterType === FilterType.originality
            ? key === getFilterValues(selectedOriginalityFilter)
            : filterType === FilterType.freq
            ? key === getFilterValues(selectedFreqFilter)
            : filterType === FilterType.sourceOrgId
            ? key === getFilterValues(selectedSourceOrgIdFilter)
            : false;

      selected && (anySelection = true);

      return {
        key,
        text,
        ariaLabel: text,
        selected,
        inactive: filterType === FilterType.source ? item["ActiveFlag"] === 0 : undefined,
      };
    });

  result?.unshift({ key: "", text: "<All>", selected: !anySelection });

  return result;
};

const getSelectedKeys = (filterItems: IFilterItem[], filterType: FilterType): string[] => {
  var filterValues = filterItems.find((item) => item.filterType === filterType)?.filterValues;
  return filterValues?.map((value) => value.key) || [];
};

const getFilterValues = (filterItem: IFilterItem): string => filterItem?.filterValues?.map((v) => v.key)?.join(",");

const applyFilterToDropdownItems = (
  dropdownItems: IDropdownOption[],
  filterItem: IFilterItem,
  filterfieldName: string
): IDropdownOption[] => {
  if (filterItem) {
    dropdownItems = dropdownItems.filter((item) =>
      filterItem.filterValues?.find((value) => Number(value.key) === Number(item[filterfieldName]))
    );
  }

  return dropdownItems;
};

const getActiveStatusOptions = (selectedActiveStatus: string[]): IComboBoxOption[] => [
  { key: "", text: "<All>", selected: !selectedActiveStatus?.length },
  { key: "1", text: "Active" },
  { key: "0", text: "Inactive" },
];
