import React, { useContext, useEffect, useState } from "react";
import { DetailsList, Dialog, DialogType, IColumn, IDialogProps, SelectionMode, Spinner } from "@fluentui/react";
import { AppContext } from "../app/App";

export interface IItemListDialogProps extends IDialogProps {
  dialogTitle?: string;
  columns?: IColumn[];
  dataTableName?: string;
  dataFieldName?: string;
  dataFieldValue?: string | number;
  getData?: (data: Object) => Promise<Object>;
}

export const ItemListDialog = (props: IItemListDialogProps) => {
  const { dataFieldName, dataFieldValue, getData, dataTableName, columns, dialogTitle, hidden } = props;
  const [items, setItems] = useState<any[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const { onErrorChange } = useContext(AppContext);

  useEffect(() => {
    if (!hidden && dataFieldValue) {
      let param = {};
      param[dataFieldName] = dataFieldValue;

      setLoadingData(true);
      getData(param)
        .then((result: any) => result && setItems(result[dataTableName]))
        .catch((error) => onErrorChange(error))
        .finally(() => setLoadingData(false));
    }
  }, [hidden, dataTableName, dataFieldName, dataFieldValue, getData, onErrorChange]);

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: dialogTitle,
        closeButtonAriaLabel: "Close",
      }}
      minWidth={500}
      modalProps={{ isBlocking: true }}
      {...props}
    >
      {loadingData && <Spinner />}
      {!loadingData &&
        (items?.length ? (
          <DetailsList items={items} columns={columns} selectionMode={SelectionMode.none} />
        ) : (
          <div>No data is found.</div>
        ))}
    </Dialog>
  );
};

export default ItemListDialog;
