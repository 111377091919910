import React from "react";
import { ChoiceGroup, IChoiceGroupProps } from "@fluentui/react";

export interface IRadioToggle extends IChoiceGroupProps {
  itemGap?: number;
}

export const RadioToggle = (props: IRadioToggle) => {
  const { itemGap = 6 } = props;
  const styles = {
    root: { marginTop: -8 },
    flexContainer: {
      columnGap: itemGap + "px",
      display: "inline-flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  };

  return <ChoiceGroup {...props} styles={styles} />;
};

export default RadioToggle;
