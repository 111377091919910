import { msalInstance } from "../../";

const apiDevUrl = "https://mssales-api-dev.azurewebsites.net/";
const apiIntUrl = "https://mssales-api-int.azurewebsites.net/";
const apiPpeUrl = "https://mssales-api-ppe.azurewebsites.net/";
const apiProdUrl = "https://mssales-api-prod.azurewebsites.net/";
const apiPMEIntUrl = "https://fo-mssales-cmod-api-int-app.azurewebsites.net/";
const apiPMEProdUrl = "https://fo-mssales-cmod-api-prod-app.azurewebsites.net/";
const apiScopesDevUrl = "api://204d0da9-5d53-43f9-8da8-5db09afcc4ee/user_impersonation"; // Dev and Int use the same scope
const apiScopesIntUrl = "api://7f8d884b-8b9d-4a58-b30a-2f738644bc05/user_impersonation";
const apiScopesPpeUrl = "api://9b296c24-237a-44c5-b2af-4edd386c4162/user_impersonation";
const apiScopesProdUrl = "api://ddc5ac71-ef3a-4bf1-88e8-add92c25d850/user_impersonation";
const apiScopesPMEIntUrl = "api://204d0da9-5d53-43f9-8da8-5db09afcc4ee/user_impersonation";
const apiScopesPMEProdUrl = "api://eff97cb3-03a1-483c-9fa0-6e70a4fffcb6/user_impersonation";

export const systemLogs = [];

export const apiHostUrl =
  process.env.REACT_APP_API_URL ||
  (window.location.hostname === "mssales-prod.azurewebsites.net"
    ? apiProdUrl
    : window.location.hostname === "mssales-ppe.azurewebsites.net"
    ? apiPpeUrl
    : window.location.hostname === "mssales-int.azurewebsites.net"
    ? apiIntUrl
    : window.location.hostname === "fo-mssales-cmod-ui-int-app.azurewebsites.net" ||
      window.location.hostname === "mssales-int.microsoft-int.com"
    ? apiPMEIntUrl
    : window.location.hostname === "fo-mssales-cmod-ui-prod-app.azurewebsites.net"
    ? apiPMEProdUrl
    : apiDevUrl);

const apiScopes =
  window.location.hostname === "mssales-prod.azurewebsites.net"
    ? apiScopesProdUrl
    : window.location.hostname === "mssales-ppe.azurewebsites.net"
    ? apiScopesPpeUrl
    : window.location.hostname === "mssales-int.azurewebsites.net"
    ? apiScopesIntUrl
    : window.location.hostname === "fo-mssales-cmod-ui-int-app.azurewebsites.net"
    ? apiScopesPMEIntUrl
    : window.location.hostname === "fo-mssales-cmod-ui-prod-app.azurewebsites.net"
    ? apiScopesPMEProdUrl
    : apiScopesDevUrl;

const graphUrlBase = "https://graph.microsoft.com/v1.0/";
const graphDataEndpoint = graphUrlBase + "me";
const graphPhotoEndpoint = graphDataEndpoint + "/photo/$value";
const graphScopes = ["User.Read"];

export enum ResponseDataType {
  none,
  blob,
  json,
  text,
  file,
}

//------------------------------------------------
// MS Graph API calls for user data
//------------------------------------------------
export const getUserGraphData = async () => fetchData(graphDataEndpoint, undefined, ResponseDataType.json, graphScopes);

export const getUserPhoto1 = async () =>
  fetchData(graphPhotoEndpoint, undefined, ResponseDataType.blob, graphScopes).then((blob: any) =>
    URL.createObjectURL(blob)
  );

export const getUserPhoto = async () => {
  try {
    const blob: any = await fetchData(graphPhotoEndpoint, undefined, ResponseDataType.blob, graphScopes);
    if (!blob) {
      console.log("No photo data returned.");
      return null;
    } else {
      return URL.createObjectURL(blob);
    }
  } catch (error) {
    //console.error("Error fetching user photo:", error);
    return null; // Return null or a default image URL if needed
  }
};

//------------------------------------------------
// API helper functions
//------------------------------------------------
export const postApiData = (
  queryParams,
  endpoint: string,
  errorMessage?: string,
  dataType?: ResponseDataType,
  options?: any
): Promise<Object> =>
  fetchData(
    endpoint,
    {
      method: "POST",
      body: JSON.stringify(queryParams),
      ...options,
    },
    dataType,
    undefined,
    errorMessage
  );

export const getApiData = (endpoint: string, errorMessage?: string): Promise<Object> =>
  fetchData(endpoint, {}, undefined, undefined, errorMessage);

export const fetchData = async (
  endpoint: string,
  options?: any,
  dataType: ResponseDataType = ResponseDataType.json,
  scopes: string[] = [apiScopes],
  errorMessage: string = ""
): Promise<Response | Object> => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const response = await msalInstance.acquireTokenSilent({
    scopes,
    account,
  });

  const accessToken = response.accessToken;

  let headers = new Headers();
  headers.append("Authorization", "Bearer " + accessToken);
  headers.append("Content-type", "application/json");

  let fetchOptions = {
    method: "GET",
    headers,
    ...options,
  };

  let promise: Promise<Response> = new Promise((resolve, reject) => {
    fetch(endpoint, fetchOptions)
      .then((response) => {
        if (response.ok && response.status !== 204) {
          resolve(response);
        } else {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") >= 0) {
            response.json().then((error) => reject(error));
          } else {
            response.text().then((errorText) => {
              const statusCode = response.status.toString(),
                error: IApiError = {
                  statusCode,
                  message: errorMessage + " &nbsp; " + (getErrorMessage(statusCode, errorText) || response.statusText),
                };
              reject(error);
            });
          }
        }
      })
      .catch((error) => reject({ message: `${errorMessage} ${error}` }));
  });

  switch (dataType) {
    case ResponseDataType.json:
      return promise.then((response) =>
        // Special handling for data in json shape of { result, logs }.
        response.json().then((data) => {
          if (data?.result && data?.logs) {
            // Output all logs to browser's console.
            data?.logs?.forEach((log) => {
              console.log(`Server log: ${log}`);
              systemLogs.unshift({ message: log, timestamp: new Date() });
            });
            return data.result;
          } else {
            return data;
          }
        })
      );
    case ResponseDataType.blob:
      return promise.then((response) => response.blob());
    case ResponseDataType.text:
      return promise.then((response) => response.text());
    case ResponseDataType.file:
      return promise.then((response) => {
        var contentDisposition = response.headers.get("content-disposition"),
          filename = "download-file.txt";

        if (contentDisposition) {
          var filenameStartIndex = contentDisposition.indexOf("filename=") + 9,
            filenameEndIndex = contentDisposition.indexOf(";", filenameStartIndex);

          filename = contentDisposition.substring(filenameStartIndex, filenameEndIndex);
        }

        return response
          .blob()
          .then((blob) => URL.createObjectURL(blob))
          .then((url) => {
            var a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            return url;
          });
      });
    default:
      return promise;
  }
};

export interface IApiError {
  statusCode: string;
  message: string;
  stackTrace?: string;
}

export const getErrorMessage = (statusCode: string, errorMessage?: string): string => {
  let msg;

  if (errorMessage) {
    msg = errorMessage;
  } else {
    switch (statusCode) {
      case "204":
        msg = "No data is returned.";
        break;
      case "401":
        msg = "You are not authorized to access this data.";
        break;
      default:
        msg = `Status code ${statusCode} is returned from the API.`;
        break;
    }
  }

  return msg;
};
export {};
