export const getBooleanFlagText = (data: any[], fieldName: string) =>
  data?.length && !!data[0][fieldName] ? "Yes" : "No";

// Additional processing on file template data.
export const processFileTemplateData = (result, userClass) =>
  result?.templateColumns?.forEach((column) => {
    // For Sellin, check SystemRequiredFlag in additional to RequiredFlag.
    if (userClass === "S") {
      column["RequiredFlag"] = column["RequiredFlag"] || column["SystemRequiredFlag"];
      column["RequiredRejectLevelID"] = column["RequiredRejectLevelID"] || column["SystemRequiredRejectLevelID"];
    }
  });
