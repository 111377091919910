import React, { useCallback, useContext } from "react";
import { DetailsList, IColumn, Icon, SelectionMode } from "@fluentui/react";
import classNames from "./Overview.module.scss";
import { AppContentContext, IErrorTypeInfo } from "./AppContent";

export interface IErrorSummaryProps {
  onErrorTypeSelect: (errorType: IErrorTypeInfo) => void;
}

export const ErrorSummary = (props: IErrorSummaryProps) => {
  const { onErrorTypeSelect } = props;
  const { errorSummaryItems } = useContext(AppContentContext);

  const onErrorTypeEditClick = useCallback(
    (item) => {
      const errorType = { errorTypeId: item["errorTypeId"], errorCategory: item["category"] };
      onErrorTypeSelect(errorType);
    },
    [onErrorTypeSelect]
  );

  return (
    <div className={classNames.section}>
      <div className={classNames.header}>Error Summary</div>
      {errorSummaryItems?.length ? (
        <DetailsList
          className={classNames.table}
          items={errorSummaryItems}
          columns={getErrorSummaryColumns(onErrorTypeEditClick)}
          selectionMode={SelectionMode.none}
          compact={true}
        />
      ) : (
        <div className={classNames.noContentPane}>No related error</div>
      )}
    </div>
  );
};

export default ErrorSummary;

const getErrorSummaryColumns = (onErrorTypeEditClick: (item) => void): IColumn[] => [
  {
    fieldName: "edit",
    key: "edit",
    name: "Edit",
    minWidth: 50,
    maxWidth: 50,
    onRender: (item) => (
      <Icon
        iconName="Edit"
        className={classNames.errorEditIcon}
        title="Edit this error"
        onClick={() => onErrorTypeEditClick(item)}
      />
    ),
  },
  {
    fieldName: "category",
    key: "category",
    name: "Category",
    minWidth: 120,
    maxWidth: 140,
  },
  {
    fieldName: "revenue",
    key: "revenue",
    name: "Revenue",
    minWidth: 100,
    maxWidth: 120,
  },
  {
    fieldName: "quantity",
    key: "quantity",
    name: "Quantity",
    minWidth: 100,
    maxWidth: 120,
  },
  {
    fieldName: "recordCount",
    key: "recordCount",
    name: "Record Count",
    minWidth: 100,
    maxWidth: 120,
  },
];
