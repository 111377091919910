import { apiHostUrl, postApiData } from "../../shared/helpers/apiHelper";

const apiUrlBase = `${apiHostUrl}mdc/`;

//------------------------------------------------
// MDC API calls
//------------------------------------------------

export const getSearchData = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}getTransmissionData`, "Fail to get search data.");

export const reprocessTransmissionFile = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}ReprocessTransmissionFile`, "Fail to reprocess file.");
