import React, { useCallback, useContext, useState } from "react";
import { ChoiceGroup } from "@fluentui/react";
import { PortalContext } from "../../portal/Portal";
import SortedItemsPickerDialog from "../../shared/components/SortedItemsPicker/SortedItemsPickerDialog";
import { startCase } from "../../shared/helpers/miscHelper";
import {
  channelErrorColumns,
  defaultChannelErrorColumns,
  sellinErrorColumns,
  defaultSellinErrorColumns,
  userClassOptionName,
  userClassOptions,
  showLogsOptionName,
  selectedChannelErrorColumnsName,
  selectedSellinErrorColumnsName,
} from "./UserOptions.helper";
import classNames from "./App.module.scss";
import MatchCodeLookupDialog from "./MatchCodeLookupDialog";
import ProductLookupDialog from "./ProductLookupDialog";

export const UserOptions = () => {
  const { userOptions, onUserOptionChange, onShowUserCalloutChange } = useContext(PortalContext);
  const userClass = (userOptions && userOptions[userClassOptionName]) || "C";
  const showLogsPanel = (userOptions && userOptions[showLogsOptionName]) || false;
  const [showGridSettingsDialog, setShowGridSettingsDialog] = useState<boolean>(false);
  const [showMatchCodeLookupDialog, setShowMatchCodeLookupDialog] = useState<boolean>(false);
  const [showProductLookupDialog, setShowProductLookupDialog] = useState<boolean>(false);

  const getColumnItem = (columnName) => ({ key: columnName, text: startCase(columnName) });

  const errorColumnItems =
    userClass === "C" ? channelErrorColumns.map(getColumnItem) : sellinErrorColumns.map(getColumnItem);

  const selectedKeys =
    userClass === "C"
      ? localStorage[selectedChannelErrorColumnsName] || defaultChannelErrorColumns.join(",")
      : localStorage[selectedSellinErrorColumnsName] || defaultSellinErrorColumns.join(",");

  const onUserClassChange = useCallback(
    (ev, option) => {
      onUserOptionChange(userClassOptionName, option.key);
      localStorage[userClassOptionName] = option.key;
    },
    [onUserOptionChange]
  );

  const onGridSettingsCommit = useCallback(
    (selectedValues) => {
      userClass === "C"
        ? (localStorage[selectedChannelErrorColumnsName] = selectedValues)
        : (localStorage[selectedSellinErrorColumnsName] = selectedValues);

      onShowUserCalloutChange(false);
    },
    [onShowUserCalloutChange, userClass]
  );

  const onGridSettingsDismiss = useCallback(() => {
    setShowGridSettingsDialog(false);
    onShowUserCalloutChange(false);
  }, [onShowUserCalloutChange]);

  const onMatchCodeLookupDismiss = useCallback(() => {
    setShowMatchCodeLookupDialog(false);
    onShowUserCalloutChange(false);
  }, [onShowUserCalloutChange]);

  const onProductLookupDismiss = useCallback(() => {
    setShowProductLookupDialog(false);
    onShowUserCalloutChange(false);
  }, [onShowUserCalloutChange]);

  return (
    <>
      <div className={classNames.userOptions}>
        <ChoiceGroup
          options={userClassOptions}
          selectedKey={userOptions && userOptions[userClassOptionName]}
          label="User Class"
          onChange={onUserClassChange}
        />
        <div className={classNames.actionLink} onClick={() => setShowGridSettingsDialog(true)}>
          Grid Settings
        </div>
        <div className={classNames.actionLink} onClick={() => setShowMatchCodeLookupDialog(true)}>
          Match Code Lookup
        </div>
        <div className={classNames.actionLink} onClick={() => setShowProductLookupDialog(true)}>
          Product Lookup
        </div>
        <div className={classNames.actionLink} onClick={() => onUserOptionChange(showLogsOptionName, !showLogsPanel)}>
          {showLogsPanel ? "Hide" : "Show"} System Logs
        </div>
      </div>
      {showGridSettingsDialog && (
        <SortedItemsPickerDialog
          label="Error Collection Columns"
          items={errorColumnItems}
          selectedKeys={selectedKeys}
          hidden={!showGridSettingsDialog}
          onDismiss={onGridSettingsDismiss}
          onCommitClick={onGridSettingsCommit}
        />
      )}
      {showMatchCodeLookupDialog && <MatchCodeLookupDialog onDismiss={onMatchCodeLookupDismiss} />}
      {showProductLookupDialog && <ProductLookupDialog onDismiss={onProductLookupDismiss} />}
    </>
  );
};

export default UserOptions;
