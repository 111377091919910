import { useState, useEffect, MouseEventHandler } from "react";

// This hook creates a state hook and stores the state into the browser localStorage.
export const useBrowserState = (
  stateName: string,
  initialState: string = localStorage[stateName]
): [string, React.Dispatch<React.SetStateAction<string>>] => {
  const [state, setState] = useState<string>(initialState);

  useEffect(() => localStorage.setItem(stateName, state));

  return [state, setState];
};

// This hook calculates the current window size.
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export interface IUseResizeBarYProps {
  defaultPosX?: number;
  minPosX?: number;
  maxPosX?: number;
}

export interface IUseResizeBarYResult {
  resizeBarPosX: number;
  onMouseDown: MouseEventHandler;
  onMouseUp: MouseEventHandler;
  onMouseMove: MouseEventHandler;
}

// This hook sets up vertical resize bar events and X position.
export const useResizeBarY = (props: IUseResizeBarYProps = {}): IUseResizeBarYResult => {
  const { defaultPosX = 300, minPosX = 1, maxPosX = 1000 } = props;
  const [dragging, setDragging] = useState<boolean>(false);
  const [resizeBarPosX, setResizeBarXPos] = useState<number>(defaultPosX);

  const onMouseDown = (e: React.MouseEvent) => {
    setResizeBarXPos(e.clientX);
    setDragging(true);
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (dragging) {
      e.preventDefault();

      let clientX = e.clientX;

      if (resizeBarPosX && clientX >= minPosX && clientX <= maxPosX) {
        setResizeBarXPos(clientX);
      }
    }
  };

  return { resizeBarPosX, onMouseDown, onMouseUp, onMouseMove };
};
