import { IItem } from "./SortedItemsPicker";

export const getSelectedItems = (items: IItem[], selectedKeysText: string): string[] => {
  let result = [],
    selectedKeys = selectedKeysText && selectedKeysText.split(",");

  if (items?.length && selectedKeys?.length) {
    selectedKeys.forEach((k) => {
      let selectedItem = items.find((i) => i.key === k || i.key === Number(k));
      result.push(selectedItem ? selectedItem.text : k);
    });
  }

  return result;
};

export const getSortedItems = (items: IItem[], selectedKeysText: string): IItem[] => {
  let unSelectedItems = [],
    selectedItems = [],
    selectedKeys = selectedKeysText && selectedKeysText.split(",");

  if (items?.length) {
    selectedKeys?.length &&
      selectedKeys.forEach((k) => {
        let selectedItem = items.find((i) => i.key === k || i.key === Number(k));
        selectedItems.push({ ...selectedItem, selected: true });
      });

    items.forEach((item) => {
      item.text && !selectedItems.find((si) => si.key === item.key) && unSelectedItems.push(item);
    });
  }

  return selectedItems.concat(unSelectedItems);
};
