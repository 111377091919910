import { IColumn, IDialogProps } from "@fluentui/react";
import React from "react";
import { getFileTemplateSources } from "../helpers/apiHelper";
import ItemListDialog from "./ItemListDialog";

export interface ITemplateSourcesDialogProps extends IDialogProps {
  fileTemplateId: number;
}

export const TemplateSourcesDialog = (props: ITemplateSourcesDialogProps) => {
  const { fileTemplateId } = props;

  return (
    <ItemListDialog
      dialogTitle="Template Users"
      dataTableName="templateSources"
      dataFieldName="FileTemplateID"
      dataFieldValue={fileTemplateId}
      getData={getFileTemplateSources}
      columns={columns}
      {...props}
      minWidth={960}
    />
  );
};

export default TemplateSourcesDialog;

const columns: IColumn[] = [
  {
    fieldName: "Source",
    key: "Source",
    name: "Source",
    minWidth: 200,
  },
  {
    fieldName: "Active",
    key: "Active",
    name: "Active",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    fieldName: "Method",
    key: "Method",
    name: "Method",
    minWidth: 160,
    maxWidth: 160,
  },
  {
    fieldName: "FileNameID",
    key: "FileNameID",
    name: "File Name ID",
    minWidth: 120,
    maxWidth: 120,
  },
  {
    fieldName: "Country",
    key: "Country",
    name: "Country",
    minWidth: 120,
    maxWidth: 120,
  },
  {
    fieldName: "SourceID",
    key: "SourceID",
    name: "Source ID",
    minWidth: 100,
    maxWidth: 100,
  },
];
