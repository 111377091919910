import { ISelectedSource, ISelectedTransmission, ITransmissions } from "../app/App.types";

export const verifyStreamingFile = (
  selectedFile: ISelectedTransmission,
  selectedSource?: ISelectedSource,
  transmissions?: ITransmissions
): boolean => {
  if (selectedFile) {
    return Number(selectedFile?.id) < 0;
  }

  if (selectedSource && transmissions?.allTransmissions?.length) {
    let sourceTransmissions = transmissions.allTransmissions.filter(
      (transmission) => transmission["Name"] === selectedSource.name
    );

    return !!sourceTransmissions?.length && Number(sourceTransmissions[0]["TransmissionID"]) < 0;
  }

  return false;
};
