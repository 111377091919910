export const getTransmissionsByDay = (transmissionList: any[]) => {
  var transmissionsByDay = {};

  transmissionList.forEach((transmission) => {
    var date = transmission["timeStamp"].split(/['T']+/)[0];
    var name = date.split(/[-]+/)[2];

    groupTransmissions(transmission, name, transmissionsByDay);
  });
  return transmissionsByDay;
};

export const getTransmissionsByMonth = (transmissionList: any[]) => {
  var transmissionsByMonth = {};
  transmissionList.forEach((transmission) => {
    var date = transmission["timeStamp"].split(/['T']+/)[0];
    var name = date.split(/[-]+/)[1];

    groupTransmissions(transmission, name, transmissionsByMonth);
  });
  return transmissionsByMonth;
};

export const getTransmissionsByYear = (transmissionList: any[]) => {
  var transmissionsByYear = {};

  transmissionList.forEach((transmission) => {
    var date = transmission["timeStamp"].split(/['T']+/)[0];
    var name = date.split(/[-]+/)[0];

    groupTransmissions(transmission, name, transmissionsByYear);
  });
  return transmissionsByYear;
};

export const getTransmissionsByTid = (transmissionList: any[]) => {
  var transmissionsByTid = {};

  transmissionList.forEach((transmission) => {
    let name = transmission["id"];

    groupTransmissions(transmission, name, transmissionsByTid);
  });
  return transmissionsByTid;
};

export const getTransmissionsBySource = (transmissionList: any[]) => {
  var transmissionsBySource = {};

  transmissionList.forEach((transmission) => {
    let templateId = transmission["templateId"];
    let source = transmission["source"] ? transmission["source"] : "None";

    if (!transmissionsBySource.hasOwnProperty(source)) {
      transmissionsBySource[source] = {
        source,
        templateId,
        transmissions: [],
      };
    }

    transmissionsBySource[source].transmissions.push({
      id: transmission["templateId"],
      filePath: transmission["fileName"],
      name: transmission["fileName"].split(/[/]+/).pop(),
      source: source,
      streamName: transmission["streamName"],
      fileNumber: transmission["id"],
      createdAt: transmission["createdAt"],
      updatedAt: transmission["updatedAt"],
      mergeStatus: transmission["transmissionMergeStatusHistory"],
      auditStatus: transmission["auditStatusHistory"],
      reconStatus: transmission["reconciliationStatus"],
      containerPath: transmission["containerPath"],
      timeStamp: transmission["createdAt"],
      icon: "OpenFile",
    });
  });
  return transmissionsBySource;
};

const groupTransmissions = (transmission: {}, name: string, groupDict: {}) => {
  if (!groupDict.hasOwnProperty(name)) {
    groupDict[name] = [];
  }
  groupDict[name].push({
    ...transmission,
  });
};
