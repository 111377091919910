import {
  ConstrainMode,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  IDialogProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  Spinner,
} from "@fluentui/react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { DataForm, FieldType, IField } from "../../shared/components/DataForm";
import { AppContext } from "../app/App";
import { IDomains } from "../app/App.types";
import { getOrganizations } from "../helpers/apiHelper";
import { useConfigChanges } from "./Config.hooks";
import classNames from "./OrgSearchDialog.module.scss";

export interface IOrgSearchDialogProps extends IDialogProps {
  onOrgSelect: (selectedOrg: object) => void;
}

export interface IOrgData {
  organizations: any[];
  totalCount: any[];
}

export const OrgSearchDialog = (props: IOrgSearchDialogProps) => {
  const { onDismiss, onOrgSelect } = props;
  const [organizationData, setOrganizationData] = useState<IOrgData>();
  const [selectedOrg, setSelectedOrg] = useState<object>();
  const [loading, setLoading] = useState<boolean>(false);
  const { appState, onErrorChange } = useContext(AppContext);
  const { domains } = appState;
  const [configChanges, onFieldValueChange] = useConfigChanges();

  const onSearchClick = useCallback(() => {
    setLoading(true);
    setOrganizationData(undefined);
    setSelectedOrg(undefined);
    getOrganizations(configChanges)
      .then((organizationData: any) => {
        let extendedData = extendedOrganizationData(organizationData, domains);
        setOrganizationData(extendedData);
      })
      .catch((error) => onErrorChange(error))
      .finally(() => setLoading(false));
  }, [configChanges, domains, onErrorChange]);

  const rowSelection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          setSelectedOrg(rowSelection.getSelection()[0]);
        },
      }),
    []
  );

  const onSelectClick = useCallback(() => {
    onOrgSelect(selectedOrg);
  }, [selectedOrg, onOrgSelect]);

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: "Organization Search",
        closeButtonAriaLabel: "Close",
      }}
      minWidth={1000}
      modalProps={{ isBlocking: true, className: classNames.dialog }}
      {...props}
    >
      <div className={classNames.searchPane}>
        <DataForm
          context={configChanges}
          fields={getFields(domains, onSearchClick)}
          onFieldValueChange={onFieldValueChange}
        />
      </div>
      {loading && <Spinner className={classNames.spinner} />}
      {!loading && !!organizationData?.organizations?.length && (
        <div className={classNames.searchResultPane} data-is-scrollable="true">
          <DetailsList
            items={organizationData?.organizations || []}
            columns={columns}
            selection={rowSelection}
            compact
            selectionMode={SelectionMode.single}
            constrainMode={ConstrainMode.unconstrained}
          />
        </div>
      )}
      {organizationData?.totalCount?.length && (
        <div className={classNames.searchCount}>
          {organizationData?.organizations?.length} of {organizationData?.totalCount[0]["TotalCount"]} records
        </div>
      )}
      <DialogFooter>
        <PrimaryButton text="Select" disabled={!selectedOrg} onClick={onSelectClick} />
        <DefaultButton text="Cancel" onClick={onDismiss} />
      </DialogFooter>
    </Dialog>
  );
};

export default OrgSearchDialog;

const getFields = (domains: IDomains, onSearchClick): IField[] => {
  let countryOptions = domains.countries?.map((c) => ({ key: c["CountryID"], text: c["Name"] }));
  countryOptions.unshift({ key: "", text: "" });

  let organizationTypeOptions = domains?.organizationTypes?.map((i) => ({
    key: i["OrganizationTypeID"],
    text: i["Description"],
  }));
  organizationTypeOptions.unshift({ key: "", text: "" });

  return [
    { fieldName: "name", label: "Global Name" },
    {
      fieldName: "countryId",
      fieldType: FieldType.enumeration,
      options: countryOptions,
      placeholder: "",
      label: "Country",
      width: 240,
    },
    {
      fieldName: "organizationTypeId",
      fieldType: FieldType.enumeration,
      options: organizationTypeOptions,
      placeholder: "",
      label: "Org Type",
      width: 160,
    },
    { fieldName: "organizationId", label: "Org ID", fieldType: FieldType.number },
    {
      fieldType: FieldType.button,
      value: "Search",
      onClick: onSearchClick,
    },
  ];
};

const columns: IColumn[] = [
  {
    key: "OrganizationID",
    fieldName: "OrganizationID",
    name: "Organization ID",
    minWidth: 90,
    maxWidth: 90,
    isResizable: true,
  },
  {
    key: "GlobalName",
    fieldName: "GlobalName",
    name: "Global Name",
    minWidth: 120,
    isResizable: true,
  },
  {
    key: "OrganizationTypeName",
    fieldName: "OrganizationTypeName",
    name: "Organization Type",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "CountryName",
    fieldName: "CountryName",
    name: "Country",
    minWidth: 120,
    maxWidth: 200,
    isResizable: true,
  },
];

const extendedOrganizationData = (organizationData: IOrgData, domains: IDomains) => {
  if (organizationData) {
    let organizations = organizationData?.organizations?.map((o) => ({
      ...o,
      OrganizationTypeName: domains?.organizationTypes?.find(
        (ot) => ot["OrganizationTypeID"] === o["OrganizationTypeID"]
      )["Description"],
      CountryName: domains?.countries?.find((c) => c["CountryID"] === o["CountryID"])["Name"],
    }));

    organizationData.organizations = organizations;
  }

  return organizationData;
};
