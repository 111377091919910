import React, { CSSProperties } from "react";
import { IField, FieldType, IDataContext } from "./interfaces";
import { getCollapsiblePaneStyle } from "./DataForm.helper";
import DataFormContent, { resolveFields } from "./DataFormContent";
import classNames from "./DataForm.module.scss";
import { Icon, Pivot, PivotItem, DetailsList, IColumn, IStyle, SelectionMode } from "@fluentui/react";

export enum ItemDisplayView {
  list = "list",
  tab = "tab",
  table = "table",
}

export interface IItemContainerProps {
  label: string;
  fieldName: string;
  field: IField;
  fields: IField[] | ((dataContext: IDataContext) => IField[]);
  items: any[];
  itemTypeName?: string;
  compact?: boolean;
  collapsed?: boolean;
  itemDisplayView?: ItemDisplayView;
  parentDataContext?: IDataContext;
  style?: React.CSSProperties;
  styles?: IStyle;
  hints?: string;
  onFieldValueChange: (fieldName: string, newValue: any) => void;
  onItemSelected?: (fieldName: string, value: any) => void;
}

export interface IItemContainerState {
  collapsed: boolean;
  itemDisplayView: ItemDisplayView;
  selectedKey: string;
}

export class ItemContainer extends React.Component<IItemContainerProps, IItemContainerState> {
  itemsElement: HTMLElement;

  state = {
    collapsed: this.props.collapsed !== false,
    itemDisplayView: this.props.itemDisplayView || ItemDisplayView.tab,
    selectedKey: null,
  };

  componentDidMount() {
    if (!this.state.selectedKey) {
      // Initial to select the first item.
      const selectedKey = this.props.itemTypeName + 0;
      this.setSelectedKey(selectedKey);
    }
  }

  componentDidUpdate(prevProps) {
    const { items: prevItems } = prevProps;
    const { items: currItems } = this.props;

    if (currItems.length > prevItems.length) {
      if (this.itemsElement) {
        let lastItemElement = this.itemsElement.lastElementChild;
        lastItemElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      if (this.state.itemDisplayView === ItemDisplayView.tab) {
        this.setState({ selectedKey: this.props.itemTypeName + (currItems.length - 1) });
      }
    }
  }

  render() {
    const { label, field, fields, items, itemTypeName = "Item", compact, style, styles, hints } = this.props;
    const { collapsed, itemDisplayView } = this.state;

    const itemsStyle = getCollapsiblePaneStyle(collapsed, "block"),
      isListView = itemDisplayView === ItemDisplayView.list,
      isTabView = itemDisplayView === ItemDisplayView.tab,
      isTableView = itemDisplayView === ItemDisplayView.table;

    const rootClassNames = [classNames.containerRoot, classNames.itemContainer];
    compact && rootClassNames.push(classNames.compact);

    const itemsUi =
      collapsed || !items?.length
        ? null
        : isListView
        ? this.renderItemList(items, itemsStyle, fields, itemTypeName)
        : isTabView
        ? this.renderItemTabs(items, itemsStyle, fields, itemTypeName)
        : isTableView
        ? this.renderItemTable(items, itemsStyle, fields, itemTypeName, styles)
        : null;

    return (
      <div className={rootClassNames.join(" ")} style={style}>
        <div className={classNames.containerLabel}>
          <span title={hints}>{label}</span>
          {!!items?.length && field.collapsible && (
            <Icon
              key={`${label}ToggleContent`}
              iconName={collapsed ? "ChevronDown" : "ChevronUp"}
              className={classNames.collapsedIcon}
              title="Toggle Content"
              onClick={() => this.setState({ collapsed: !collapsed })}
            />
          )}
          {!!items?.length && !field.doNotAllowItemViewSwitch && (
            <Icon
              key={`${label}ToggleDisplayView`}
              iconName="List"
              className={classNames.displayViewIcon}
              title="Switch Item View"
              onClick={() =>
                this.setState({
                  itemDisplayView: isListView
                    ? ItemDisplayView.tab
                    : isTabView
                    ? ItemDisplayView.table
                    : ItemDisplayView.list,
                })
              }
            />
          )}
          {!field.doNotAllowNewItemCreation && (
            <Icon iconName="Add" title="Add New Item" className={classNames.addItemIcon} onClick={this.onItemAdd} />
          )}
        </div>
        {!collapsed && <span className={classNames.subTitle}>{hints}</span>}
        {itemsUi}
      </div>
    );
  }

  renderItemTabs(
    items: any[],
    itemsStyle: CSSProperties,
    fields: IField[] | ((dataContext: IDataContext) => IField[]),
    itemTypeName
  ) {
    const { field, parentDataContext } = this.props;
    const { selectedKey } = this.state;
    return (
      <Pivot
        className={classNames.pivot}
        linkSize="normal"
        style={itemsStyle}
        selectedKey={selectedKey}
        overflowBehavior="menu"
        onLinkClick={this.onTabSelect}
      >
        {items.map((item, index) => (
          <PivotItem
            key={`${itemTypeName}${index}`}
            itemKey={`${itemTypeName}${index}`}
            className={classNames.pivotItem}
            headerText={
              (field.customTabHeader && field.customTabHeader(parentDataContext, itemTypeName, index)) ||
              `${itemTypeName} #${index + 1}`
            }
          >
            <div className={classNames.items}>{this.renderItemFields(items, item, index, fields, itemTypeName)}</div>
          </PivotItem>
        ))}
      </Pivot>
    );
  }

  renderItemList(
    items: any[],
    itemsStyle: CSSProperties,
    fields: IField[] | ((dataContext: IDataContext) => IField[]),
    itemTypeName
  ) {
    return (
      <div
        className={`${classNames.items} ${classNames.content}`}
        style={itemsStyle}
        ref={(el) => (this.itemsElement = el)}
      >
        {items?.map ? (
          items.map((item, index) => this.renderItemFields(items, item, index, fields, itemTypeName))
        ) : (
          <>{items}</>
        )}
      </div>
    );
  }

  renderItemTable(
    items: any[],
    itemsStyle: CSSProperties,
    fields: IField[] | ((dataContext: IDataContext) => IField[]),
    itemTypeName: string,
    styles: any
  ) {
    const columns: IColumn[] = [],
      fieldsToUse = resolveFields(fields, this.props.parentDataContext, items[0]);

    fieldsToUse?.forEach((field) => {
      var columnName = field.label,
        fieldClone = { ...field, label: "", style: { padding: 0 } };

      columns.push({
        key: field.fieldName,
        fieldName: field.fieldName,
        name: columnName,
        minWidth: Number(field.width),
        maxWidth: Number(field.width),
        isResizable: true,
        onRender: (item, index) => {
          return (
            <div id={`tableViewItemContainer${index}`} ref={(el) => (this.itemsElement = el)}>
              <DataFormContent
                field={fieldClone}
                fields={[fieldClone]}
                context={item}
                onFieldValueChange={(fieldName, newValue) => this.onItemFieldValueChange(index, fieldName, newValue)}
                id={`tableViewItem${index}`}
              />
            </div>
          );
        },
      });
    });

    return (
      <div className={`${classNames.items} ${classNames.itemsTable}`} style={itemsStyle} data-is-scrollable="true">
        <DetailsList items={items} columns={columns} selectionMode={SelectionMode.none} compact styles={styles} />
      </div>
    );
  }

  renderItemFields = (
    items: any[],
    item,
    index: number,
    fields: IField[] | ((dataContext: IDataContext) => IField[]),
    itemTypeName
  ) => {
    const { itemDisplayView } = this.state,
      isTabView = itemDisplayView === ItemDisplayView.tab;
    const { field, parentDataContext } = this.props;
    let fieldsToUse = resolveFields(fields, parentDataContext, item);

    let itemFields = updateItemFieldValues(fieldsToUse, item),
      key = `${itemTypeName}-${index}`;

    var dataContext: IDataContext = {
      context: item,
      parentDataContext,
      field,
      key,
      onFieldValueChange: (fieldName, newValue) => this.onItemFieldValueChange(index, fieldName, newValue),
    };

    return (
      <div key={key} className={classNames.itemFields}>
        <div className={`${classNames.dataField} ${classNames.itemLabelField}`}>
          <div className={classNames.itemLabel}>
            {(field.customTitle && field.customTitle(parentDataContext, itemTypeName, index)) ||
              `${itemTypeName} #${index + 1}`}
          </div>
          <div className={classNames.itemActions}>
            <Icon
              className={classNames.actionIcon}
              iconName="Delete"
              title="Delete Item"
              onClick={() => this.onItemDelete(index)}
            />
            {index > 0 && (
              <Icon
                className={classNames.actionIcon}
                iconName={isTabView ? "Back" : "Up"}
                title={isTabView ? "Move Back" : "Move Up"}
                onClick={() => this.onItemMove(index, -1)}
              />
            )}
            {index < items.length - 1 && (
              <Icon
                className={classNames.actionIcon}
                iconName={isTabView ? "Forward" : "Down"}
                title={isTabView ? "Move Forward" : "Move Down"}
                onClick={() => this.onItemMove(index, 1)}
              />
            )}
          </div>
        </div>
        <div className={classNames.fields}>
          <DataFormContent
            field={field}
            fields={itemFields}
            context={item}
            parentDataContext={dataContext}
            onFieldValueChange={(fieldName, newValue) => this.onItemFieldValueChange(index, fieldName, newValue)}
            id={key}
          />
        </div>
      </div>
    );
  };

  onItemFieldValueChange = (itemIndex: number, fieldName: string, newValue: any) => {
    const { onFieldValueChange, fieldName: itemFieldName, items } = this.props;
    const newItems = items.map((item, index) => {
      if (index === itemIndex) {
        if (newValue === "" || newValue === null || newValue === undefined) {
          delete item[fieldName];
          return item;
        } else {
          return {
            ...item,
            [fieldName]: newValue,
          };
        }
      }

      return item;
    });

    onFieldValueChange(itemFieldName, newItems);
  };

  onItemMove = (itemIndex: number, indexChange?: number) => {
    const { onFieldValueChange, fieldName, items, itemTypeName } = this.props;
    const { itemDisplayView } = this.state;

    let newItems = items.slice(),
      targetItems = newItems.splice(itemIndex, 1),
      newIndex = itemIndex + indexChange;

    indexChange && newItems.splice(newIndex, null, targetItems[0]);

    onFieldValueChange(fieldName, newItems);

    if (itemDisplayView === ItemDisplayView.tab) {
      if (!indexChange && itemIndex > 0) {
        this.setState({ selectedKey: itemTypeName + (itemIndex - 1) });
      } else {
        this.setState({ selectedKey: itemTypeName + newIndex });
      }
    }
  };

  onItemAdd = () => {
    const { onFieldValueChange, fieldName, items } = this.props;

    let newItems = items ? items.slice() : [];

    newItems.push({});

    onFieldValueChange(fieldName, newItems);
    this.state.collapsed && this.setState({ collapsed: false });
  };

  onItemDelete = (itemIndex: number) => this.onItemMove(itemIndex);

  onTabSelect = (selectedItem) => {
    const selectedKey = selectedItem && selectedItem.props && selectedItem.props.itemKey;
    this.setSelectedKey(selectedKey);
  };

  setSelectedKey = (selectedKey: string) => {
    this.setState({ selectedKey });

    const { onItemSelected, fieldName, itemTypeName = "Item", items } = this.props;

    if (selectedKey && onItemSelected) {
      var itemIndexText = selectedKey.replace(itemTypeName, ""),
        itemIndex = itemIndexText && parseInt(itemIndexText);

      if (items && items.length > itemIndex) {
        onItemSelected(fieldName, items[itemIndex]);
      }
    }
  };
}

export default ItemContainer;

const updateItemFieldValues = (fields: IField[], item): IField[] => {
  return fields.map((field) => {
    if (field.fieldType === FieldType.container && field.fields && typeof field.fields === "object") {
      return {
        ...field,
        fields: updateItemFieldValues(field.fields, item),
      };
    }
    return {
      // Having it this order causes `value` to be overridden if specified in the field definition
      // However changing the order causes a regression in some dropdowns, including dataSourceType in report #5
      ...field,
      value: item[field.fieldName],
    };
  });
};
