import { IColumn, IDialogProps } from "@fluentui/react";
import React from "react";
import { getProHistory } from "../helpers/apiHelper";
import ItemListDialog from "./ItemListDialog";

export interface IProHistoryDialogProps extends IDialogProps {
  sourceOrgId: number;
}

export const ProHistoryDialog = (props: IProHistoryDialogProps) => {
  const { sourceOrgId } = props;

  return (
    <ItemListDialog
      dialogTitle="PRO History"
      dataTableName="proHistory"
      dataFieldName="sourceOrgId"
      dataFieldValue={sourceOrgId}
      getData={getProHistory}
      columns={columns}
      {...props}
    />
  );
};

export default ProHistoryDialog;

const columns: IColumn[] = [
  {
    fieldName: "StartDate",
    key: "StartDate",
    name: "Start Date",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    fieldName: "EndDate",
    key: "EndDate",
    name: "End Date",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    fieldName: "UpdateDate",
    key: "UpdateDate",
    name: "Updated Date",
    minWidth: 80,
  },
  {
    fieldName: "UpdateBy",
    key: "UpdateBy",
    name: "Updated By",
    minWidth: 100,
    maxWidth: 100,
  },
];
