import { useState } from "react";

export const useConfigChanges = (): [object, (fieldName: string, newValue: any) => void, () => void] => {
  const [configChanges, setConfigChanges] = useState({});
  let newConfigChanges = { ...configChanges };

  const onFieldValueChange = (fieldName: string, newValue: any) => {
    if (newValue === "" || newValue === undefined || (newValue === false && configChanges?.hasOwnProperty(fieldName))) {
      delete newConfigChanges[fieldName];
    } else {
      newConfigChanges[fieldName] = newValue;
    }

    setConfigChanges(newConfigChanges);
  };

  const onCancelChanges = () => setConfigChanges({});

  return [newConfigChanges, onFieldValueChange, onCancelChanges];
};

export {};
