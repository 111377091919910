import MDCSearch from "./MDCSearch";
import MDCFileExplorer from "./MDCFileExplorer";
import SearchFileContentApp from "../../shared/components/SearchFileContentApp/SearchFileContentApp";
import { useCallback, useState } from "react";
import { getSearchData } from "../helpers/apiHelper";
import { MDCContent } from "./MDCContent";
import { IApiError } from "../../shared/helpers/apiHelper";

export const MDC = () => {
  const [transmissionData, setTransmissionData] = useState([]);
  const [selectedTransmission, setSelectedTransmission] = useState<Object>();
  const [loadingTransmissions, setLoadingTransmissions] = useState<boolean>(false);
  const [error, setError] = useState<IApiError>();

  const onSearchChange = useCallback((filterData: {}) => {
    setLoadingTransmissions(true);
    setError(undefined);
    getSearchData({ filterData })
      .then((data: any) => setTransmissionData(data))
      .catch((error) => setError(error))
      .finally(() => setLoadingTransmissions(false));
  }, []);

  const onTransmissionSelect = useCallback((transmission: []) => {
    setSelectedTransmission(transmission);
  }, []);

  return (
    <SearchFileContentApp
      searchContent={<MDCSearch onSearchChange={onSearchChange} />}
      fileExplorer={
        <MDCFileExplorer
          loading={loadingTransmissions}
          transmissionData={transmissionData}
          onTransmissionSelect={onTransmissionSelect}
          error={error}
        />
      }
      appContent={<MDCContent selectedTransmission={selectedTransmission} />}
    />
  );
};

export default MDC;
