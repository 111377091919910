import { DetailsList, SelectionMode } from "@fluentui/react";
import classNames from "./MDCContent.module.scss";
import { DefaultButton } from "@fluentui/react";
import { useMemo, useCallback } from "react";
import { reprocessTransmissionFile } from "../helpers/apiHelper";
import MDCContentHeader from "./MDCContentHeader";

export interface IMDCContentProps {
  selectedTransmission: Object;
}

const flagDefinitions = [
  {
    label: "id:",
    note: "transmission id",
    example: "8a618817-07c9-4b63-b8d2-dcf9345072ef",
  },
  {
    label: "tid:",
    note: "template id",
    example: "PurchaseLineItem_CnE_V3",
  },
  {
    label: "sn:",
    note: "stream name",
    example: "PurchaseLineItem",
  },
  {
    label: "fn:",
    note: "file name",
    example: "D_PurchaseLineItem_V3_Delta_2022_07_13_19_33_19.csv",
  },
];

const transmissionColumns1 = [
  {
    key: "id",
    name: "ID",
    fieldName: "fileNumber",
    minWidth: 350,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "fileName",
    name: "File Name",
    fieldName: "fileName",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "streamName",
    name: "Stream Name",
    fieldName: "streamName",
    minWidth: 300,
    maxWidth: 400,
    isResizable: true,
  },
];

const transmissionColumns2 = [
  {
    key: "templateId",
    name: "Template ID",
    fieldName: "templateId",
    minWidth: 350,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "auditStatus",
    name: "Audit Status",
    fieldName: "auditStatus",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "mergeStatus",
    name: "Merge Status",
    fieldName: "mergeStatus",
    minWidth: 300,
    maxWidth: 400,
    isResizable: true,
  },
];

const transmissionColumns3 = [
  {
    key: "reconStatus",
    name: "Reconciliation Status",
    fieldName: "reconciliationStatus",
    minWidth: 350,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "createdDate",
    name: "Created Date",
    fieldName: "createdDate",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "updatedDate",
    name: "Updated Date",
    fieldName: "updatedDate",
    minWidth: 300,
    maxWidth: 400,
    isResizable: true,
  },
];

const transmissionColumns4 = [
  {
    key: "totalRows",
    name: "Total Rows",
    fieldName: "totalRows",
    minWidth: 350,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "goodRows",
    name: "Good Rows",
    fieldName: "goodRows",
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "badRows",
    name: "Bad Rows",
    fieldName: "badRows",
    minWidth: 300,
    maxWidth: 400,
    isResizable: true,
  },
];

export const MDCContent = (props: IMDCContentProps) => {
  const { selectedTransmission } = props;
  var transmissionMergeStatus = "";
  var transmissionAuditStatus = "";
  var transmissionSource = "";
  var transmissionContainerPath = "";
  var transmissionFilePath = "";
  var transmissionStream = "";
  var reconStatus = "";
  var transmissionGoodRows = 0;
  var transmissionBadRows = 0;
  if (selectedTransmission) {
    if (!selectedTransmission["mergeStatus"]?.length) {
      transmissionMergeStatus = "Merge Didn't Start";
    } else {
      transmissionMergeStatus =
        selectedTransmission["mergeStatus"][selectedTransmission["mergeStatus"].length - 1].description;
    }

    if (!selectedTransmission["auditStatus"]?.length) {
      transmissionAuditStatus = "No Audit";
    } else {
      let lastAuditStatus = selectedTransmission["auditStatus"][selectedTransmission["auditStatus"].length - 1];
      transmissionAuditStatus = Boolean(lastAuditStatus.certifiedState).toString();
      transmissionGoodRows = lastAuditStatus["rowCount"].goodRecords;
      transmissionBadRows = lastAuditStatus["rowCount"].badRecords;
    }

    if (!selectedTransmission["reconStatus"]?.length) {
      reconStatus = "No Reconcillation Status";
    } else {
      reconStatus = Boolean(selectedTransmission["reconStatus"].state).toString();
    }

    if (!selectedTransmission["source"]) {
      transmissionSource = "No Source";
    } else {
      transmissionSource = selectedTransmission["source"];
    }
    transmissionContainerPath = selectedTransmission["containerPath"];
    transmissionFilePath = selectedTransmission["filePath"];
    transmissionStream = selectedTransmission["streamName"];
  }

  const onReprocessButtonClick = useCallback(() => {
    const reprocessData = {
      fileNames: [transmissionFilePath],
      source: transmissionSource,
      stream: transmissionStream,
      containerPath: transmissionContainerPath,
    };
    reprocessTransmissionFile({ reprocessData });
  }, [transmissionFilePath, transmissionSource, transmissionStream, transmissionContainerPath]);

  const transmissionItems = useMemo(() => {
    if (selectedTransmission) {
      return [
        {
          fileNumber: selectedTransmission["fileNumber"],
          fileName: selectedTransmission["name"],
          streamName: selectedTransmission["streamName"],
          templateId: selectedTransmission["id"],
          auditStatus: transmissionAuditStatus,
          mergeStatus: transmissionMergeStatus,
          createdDate: selectedTransmission["createdAt"],
          updatedDate: selectedTransmission["updatedAt"],
          goodRows: transmissionGoodRows,
          badRows: transmissionBadRows,
          totalRows: transmissionGoodRows + transmissionBadRows,
          reconciliationStatus: reconStatus,
          source: transmissionSource,
        },
      ];
    } else {
      return [];
    }
  }, [
    selectedTransmission,
    reconStatus,
    transmissionAuditStatus,
    transmissionBadRows,
    transmissionGoodRows,
    transmissionMergeStatus,
    transmissionSource,
  ]);

  const getHeaderItems = useCallback(
    (selectedTransmission: Object): any[] => {
      var items = [];

      if (selectedTransmission) {
        items.push(
          {
            key: "Source",
            text: selectedTransmission["source"],
            iconName: "Org",
            title: `${selectedTransmission["source"]}`,
          },
          {
            key: "Template Id",
            text: selectedTransmission["id"],
            iconName: "FileTemplate",
            title: `${selectedTransmission["id"]}`,
          },
          {
            key: "File Path",
            text: transmissionFilePath,
            iconName: "OpenFile",
            title: `${transmissionFilePath}`,
          }
        );
      }
      return items;
    },
    [transmissionFilePath]
  );

  const headerItems: any[] = useMemo(
    () => getHeaderItems(selectedTransmission),
    [selectedTransmission, getHeaderItems]
  );

  return selectedTransmission ? (
    <div className={classNames.root}>
      <MDCContentHeader className={classNames.header} items={headerItems} />
      <div className={classNames.contentPane}>
        <div className={classNames.title}>{"Transmission File Details"}</div>
        <div className={classNames.actionPane}>
          <DefaultButton
            text="Re-Process"
            className={classNames.button}
            disabled={false}
            onClick={onReprocessButtonClick}
          />
        </div>
        <DetailsList
          className={classNames.table}
          items={transmissionItems}
          columns={transmissionColumns1}
          selectionMode={SelectionMode.none}
        />
        <DetailsList
          className={classNames.table}
          items={transmissionItems}
          columns={transmissionColumns2}
          selectionMode={SelectionMode.none}
        />
        <DetailsList
          className={classNames.table}
          items={transmissionItems}
          columns={transmissionColumns3}
          selectionMode={SelectionMode.none}
        />
        <DetailsList
          className={classNames.table}
          items={transmissionItems}
          columns={transmissionColumns4}
          selectionMode={SelectionMode.none}
        />
      </div>
    </div>
  ) : (
    <>
      <div className={classNames.noContentPane}>
        <span className={classNames.title}>Modern Data Contract</span>

        <div className={classNames.noContentText}>
          No source org or file is selected. Start typing in the Searchbox above to select search filters.
          <br />
          If not text is inputted, search will show the latest transmissions from the last 2 weeks.
          <br />
          You can view transmissions from certain dates by using the Date Range Picker.
          <br />
          Remember to click the <b>Search</b> button to initiate the search.
        </div>
        <div className={classNames.searchInfoPane}>
          <div className={classNames.searchInfoHeader}>Search Flag Hints:</div>
          {flagDefinitions.map((filterDef, index) => (
            <div key={`searchInfoItem-${index}`} className={classNames.searchInfoItem}>
              <div className={classNames.searchInfoItemLabel}>{filterDef.label}</div>
              <div className={classNames.searchInfoItemNote}>{filterDef.note}</div>
              <div className={classNames.searchInfoItemExample}>{filterDef.example}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
