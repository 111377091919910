import React, { useContext, useEffect, useState } from "react";
import classNames from "./Overview.module.scss";
import { AppContext } from "../app/App";
import { IDropdownOption } from "@fluentui/react";
import { formatDate, formatDateTime } from "../../shared/helpers/miscHelper";
import { useConfigChanges } from "./Config.hooks";
import { DataForm, FieldType, IField } from "../../shared/components/DataForm";
import ConfigForm from "./ConfigForm";
import { fixTransmissionLevelError } from "../helpers/apiHelper";
import PurgeTransmissionDialog from "./PurgeTransmissionDialog";

export const FileInfo = () => {
  const [configChanges, onFieldValueChange, onCancelChanges] = useConfigChanges();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showPurgeDialog, setShowPurgeDialog] = useState<boolean>(false);
  const { appState, onErrorChange, onTransmissionUpdate, changeAppState } = useContext(AppContext);
  const { transmissions, selectedTransmission, domains, userClass } = appState;
  const transmissionTypes = domains?.transmissionTypes;
  const transmissionStatuses = domains?.transmissionStatuses;
  const transmissionOriginalities = domains?.transmissionOriginalities;
  const isDirty = Object.keys(configChanges).length > 0;

  const transmission =
    transmissions?.allTransmissions?.find((item) => item["TransmissionID"] === selectedTransmission?.id) || {};

  const transmissionId = transmission["TransmissionID"],
    statusItem = transmissionStatuses?.find((s) => s["TransmissionStatusID"] === transmission["TransmissionStatusID"]),
    status = (statusItem && statusItem["TransmissionStatusCode"]) || transmission["TransmissionStatusID"],
    isEditable = status === "FixableReject" || status === 9,
    statusClassNames = [classNames.infoValue],
    transmissionTypeItem = transmissionTypes?.find(
      (t) => t["TransmissionTypeID"] === transmission["TransmissionTypeID"]
    ),
    transmissionType =
      (transmissionTypeItem && transmissionTypeItem["TransmissionTypeCode"]) || transmission["TransmissionTypeID"],
    originalityItem = transmissionOriginalities?.find(
      (o) => o["TransmissionOriginalityID"] === transmission["TransmissionOriginalityID"]
    ),
    originalityId = configChanges["originalityId"] || transmission["TransmissionOriginalityID"],
    originality = (originalityItem && originalityItem["TransmissionOriginalityCode"]) || originalityId,
    reportEndDate = new Date(configChanges["reportEndDate"] || transmission["ReportEndDate"]),
    reportEndDateText = formatDate(reportEndDate),
    receivedTime = formatDateTime(transmission["TransmissionDate"]),
    logText = transmission["LogText"],
    purgeDuplicateEnabled = !!transmission["PurgeDuplicateEnabled"],
    purgeDuplicateFlag = !!configChanges["purgeDuplicateFlag"],
    originalityOptions = domains?.transmissionOriginalities?.map((o) => ({
      key: o["TransmissionOriginalityID"],
      text: o["TransmissionOriginalityCode"],
    }));

  statusClassNames.push(
    status === "Ready" || status === 0
      ? classNames.readyStatus
      : status === "Reject" || status === -1
      ? classNames.rejectStatus
      : status === "Purged" || status === 13
      ? classNames.purgedStatus
      : classNames.otherStatus
  );

  const fileInfo = {
    status,
    transmissionType,
    transmissionId,
    receivedTime,
    reportEndDate,
    reportEndDateText,
    originalityId,
    originality,
    purgeDuplicateEnabled,
    purgeDuplicateFlag,
  };

  let formTitle = `Transmission Info for ${selectedTransmission?.name}`;

  useEffect(() => {
    changeAppState({ isDirty });
  }, [isDirty, changeAppState]);

  const onSaveButtonClick = () => {
    purgeDuplicateFlag ? setShowPurgeDialog(true) : onConfirmSaving();
  };

  const onConfirmSaving = (description = "") => {
    const parameters = {
      transmissionId,
      reportEndDate: reportEndDateText,
      originalityId,
      purgeDuplicateFlag,
      description,
      ...configChanges,
    };

    setIsSaving(true);
    fixTransmissionLevelError(parameters)
      .then(() => {
        // Remove the purge transmission after changes are made.
        if (purgeDuplicateFlag) {
          onTransmissionUpdate(transmissionId);
        }

        onCancelChanges();
      })
      .catch((error) => onErrorChange(error))
      .finally(() => setIsSaving(false));
  };

  return (
    <>
      <ConfigForm
        className={classNames.infoSection}
        title={formTitle}
        isDirty={true}
        isEditable={isEditable}
        isSaving={isSaving}
        onSaveButtonClick={onSaveButtonClick}
        onCancelButtonClick={onCancelChanges}
      >
        <DataForm
          context={fileInfo}
          fields={getFields(userClass, statusClassNames.join(" "), isEditable, originalityOptions)}
          onFieldValueChange={onFieldValueChange}
        />
        {logText && <div className={classNames.infoItemNote}>{logText}</div>}
      </ConfigForm>
      {showPurgeDialog && (
        <PurgeTransmissionDialog
          hidden={!showPurgeDialog}
          headerText="Purge Previous File"
          onCommit={onConfirmSaving}
          onDismiss={() => setShowPurgeDialog(false)}
        />
      )}
    </>
  );
};

const getFields = (
  userClass: string,
  statusClassNames: string,
  isEditable: boolean,
  originalityOptions: IDropdownOption[]
): IField[] => {
  let fields: IField[] = [
    {
      fieldName: "status",
      fieldType: FieldType.displayOnly,
      label: "Status",
      className: classNames.displayField,
      valueClassName: statusClassNames,
    },
    {
      fieldName: "transmissionType",
      fieldType: FieldType.displayOnly,
      label: "Transmission Type",
      className: classNames.displayField,
    },
    {
      fieldName: "transmissionId",
      fieldType: FieldType.displayOnly,
      label: "Transmission ID",
      className: classNames.displayField,
    },
    {
      fieldName: "receivedTime",
      fieldType: FieldType.displayOnly,
      label: "Received",
      className: classNames.displayField,
    },
  ];

  if (userClass === "C") {
    fields = fields.concat([
      {
        fieldName: isEditable ? "reportEndDate" : "reportEndDateText",
        fieldType: isEditable ? FieldType.date : FieldType.displayOnly,
        label: "Report Ending",
        className: classNames.displayField,
      },
      {
        fieldName: isEditable ? "originalityId" : "originality",
        fieldType: isEditable ? FieldType.enumeration : FieldType.displayOnly,
        label: "Originality",
        options: originalityOptions,
        className: classNames.displayField,
      },
      {
        fieldName: "purgeDuplicateFlag",
        fieldType: FieldType.boolean,
        label: "Purge Previous File",
        className: classNames.displayField,
        visible: "!!this.purgeDuplicateEnabled",
      },
    ]);
  }

  return fields;
};
