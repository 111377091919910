import React, { useContext } from "react";
import classNames from "./Overview.module.scss";
import { AppContext } from "../app/App";
import ErrorSummary, { IErrorSummaryProps } from "./ErrorSummary";
import { FileInfo } from "./FileInfo";
import FileStatistics from "./FileStatistics";
import { verifyStreamingFile } from "../helpers/miscHelper";

export interface IOverviewProps extends IErrorSummaryProps {}

export const Overview = (props: IOverviewProps) => {
  const { onErrorTypeSelect } = props;
  const { selectedTransmission } = useContext(AppContext).appState;
  const isStreamingFile = verifyStreamingFile(selectedTransmission);

  return (
    <div className={classNames.root}>
      {selectedTransmission && <FileInfo />}
      <ErrorSummary onErrorTypeSelect={onErrorTypeSelect} />
      {selectedTransmission && !isStreamingFile && <FileStatistics />}
    </div>
  );
};

export default Overview;
