import React, { useEffect, useMemo, useState } from "react";
import { TextField } from "@fluentui/react";
import { getDebounceMethod } from "./helper";
import { IStyle } from "@uifabric/merge-styles";
import { IField } from "./interfaces";

interface IProps {
  id: string;
  value: string;
  field: IField;
  fieldProps: object;
  fieldGroup: IStyle;
  onChange: (field: IField, newValue: any) => void;
}

export default function TextFieldContent(props: IProps) {
  const { id, value, field, fieldProps, fieldGroup, onChange } = props;
  const [getText, setText] = useState(value);
  const onChangeDebounced = useMemo(() => getDebounceMethod(onChange, 500), [onChange]);

  useEffect(() => {
    setText(value);
  }, [value]);

  const onChangeHandler = (ev, value: string) => {
    setText(value);
    onChangeDebounced && onChangeDebounced(field, value);
  };

  return (
    <TextField
      id={id}
      value={getText}
      disabled={field?.disabled}
      styles={{ fieldGroup }}
      onChange={onChangeHandler}
      {...fieldProps}
    />
  );
}
