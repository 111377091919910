import { SearchMode } from "../app/App.helper";

export interface IFilterDefinition {
  label: string;
  tableName?: string;
  filterType: FilterType;
  keyName?: string;
  textNames?: string[];
  textNameDelimiter?: string;
  searchFlag?: string;
  searchFlagExample?: string;
  useAsAdvancedFilter?: boolean;
  useAsSuggestion?: boolean;
  dropdownItemLimit?: number;
  dropdownWidth?: number;
  useInSearchModes?: string[];
}

export enum FilterType {
  activeStatus = "activeStatus",
  channel = "channel",
  contact = "contact",
  date = "date",
  fileId = "fileId",
  fileType = "fileType",
  freq = "freq",
  orgType = "orgType",
  originality = "originality",
  region = "region",
  source = "source",
  sourceOrgId = "sourceOrgId",
  status = "status",
  subsidiary = "subsidiary",
}

export const channelFilterDefinitions: IFilterDefinition[] = [
  {
    label: "Source",
    tableName: "sourceOrganizations",
    keyName: "SourceOrgID",
    textNames: ["Name"],
    filterType: FilterType.source,
    searchFlag: "s:",
    searchFlagExample: "s:costco",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 220,
    useInSearchModes: [SearchMode.wip, SearchMode.all, SearchMode.source],
  },
  {
    label: "Source Org ID",
    tableName: "sourceOrganizations",
    keyName: "SourceOrgID",
    textNames: ["SourceOrgID"],
    filterType: FilterType.sourceOrgId,
    searchFlag: "si:",
    searchFlagExample: "si:12345",
    useAsSuggestion: true,
  },
  {
    label: "File ID",
    filterType: FilterType.fileId,
    searchFlag: "f:",
    searchFlagExample: "f:12345",
    useAsSuggestion: true,
  },
  {
    label: "Region",
    tableName: "wwRegions",
    keyName: "WWRegionID",
    textNames: ["Name"],
    filterType: FilterType.region,
    searchFlag: "r:",
    searchFlagExample: "r:canada",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 200,
    useInSearchModes: [SearchMode.wip, SearchMode.all, SearchMode.source],
  },
  {
    label: "Subsidiary",
    tableName: "subsidiaries",
    keyName: "SubsidiaryID",
    textNames: ["Name"],
    filterType: FilterType.subsidiary,
    searchFlag: "ss:",
    searchFlagExample: "ss:canada",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 160,
    useInSearchModes: [SearchMode.wip, SearchMode.all, SearchMode.source],
  },
  {
    label: "Contact",
    tableName: "contacts",
    keyName: "ContactID",
    textNames: ["FirstName", "LastName"],
    textNameDelimiter: " ",
    filterType: FilterType.contact,
    searchFlag: "ct:",
    searchFlagExample: "ct:caren",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 140,
    useInSearchModes: [SearchMode.wip, SearchMode.all, SearchMode.source],
  },
  {
    label: "Org Type",
    tableName: "organizationTypes",
    keyName: "OrganizationTypeID",
    textNames: ["Description"],
    filterType: FilterType.orgType,
    searchFlag: "ot:",
    searchFlagExample: "ot:reseller",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 140,
    useInSearchModes: [SearchMode.wip, SearchMode.all, SearchMode.source],
  },
  {
    label: "Channel",
    tableName: "channels",
    keyName: "ChannelID",
    textNames: ["Description"],
    filterType: FilterType.channel,
    searchFlag: "ch:",
    searchFlagExample: "ch:oem",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 140,
    useInSearchModes: [SearchMode.wip, SearchMode.all, SearchMode.source],
  },
  {
    label: "Status",
    tableName: "transmissionStatuses",
    keyName: "TransmissionStatusID",
    textNames: ["TransmissionStatusCode"],
    filterType: FilterType.status,
    searchFlag: "st:",
    searchFlagExample: "st:reject",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 140,
    useInSearchModes: [SearchMode.wip, SearchMode.all],
  },
  {
    label: "File Type",
    tableName: "transmissionTypes",
    keyName: "TransmissionTypeID",
    textNames: ["TransmissionTypeCode"],
    filterType: FilterType.fileType,
    searchFlag: "ft:",
    searchFlagExample: "ft:sales",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 100,
    useInSearchModes: [SearchMode.wip, SearchMode.all, SearchMode.template],
  },
  {
    label: "Originality",
    tableName: "transmissionOriginalities",
    keyName: "TransmissionOriginalityID",
    textNames: ["TransmissionOriginalityCode"],
    filterType: FilterType.originality,
    searchFlag: "or:",
    searchFlagExample: "or:resend",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 100,
    useInSearchModes: [SearchMode.wip, SearchMode.all],
  },
  {
    label: "Frequency",
    tableName: "reportingFreqs",
    keyName: "ReportingFreqID",
    textNames: ["Description"],
    filterType: FilterType.freq,
    searchFlag: "fq:",
    searchFlagExample: "fq:daily",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 100,
    useInSearchModes: [SearchMode.wip, SearchMode.all],
  },
];

export const sellinFilterDefinitions: IFilterDefinition[] = [
  {
    label: "Source",
    tableName: "sourceOrganizations",
    keyName: "SourceOrgID",
    textNames: ["Name"],
    filterType: FilterType.source,
    searchFlag: "s:",
    searchFlagExample: "s:sap",
    dropdownItemLimit: 200,
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 220,
    useInSearchModes: [SearchMode.wip, SearchMode.all],
  },
  {
    label: "Source Org ID",
    tableName: "sourceOrganizations",
    keyName: "SourceOrgID",
    textNames: ["SourceOrgID"],
    filterType: FilterType.sourceOrgId,
    searchFlag: "si:",
    searchFlagExample: "si:12345",
    useAsSuggestion: true,
  },
  {
    label: "File ID",
    filterType: FilterType.fileId,
    searchFlag: "f:",
    searchFlagExample: "f:12345",
    useAsSuggestion: true,
  },
  {
    label: "File Type",
    tableName: "transmissionTypes",
    keyName: "TransmissionTypeID",
    textNames: ["TransmissionTypeCode"],
    filterType: FilterType.fileType,
    searchFlag: "ft:",
    searchFlagExample: "ft:sales",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 120,
    useInSearchModes: [SearchMode.wip, SearchMode.all, SearchMode.template],
  },
  {
    label: "Status",
    tableName: "transmissionStatuses",
    keyName: "TransmissionStatusID",
    textNames: ["TransmissionStatusCode"],
    filterType: FilterType.status,
    searchFlag: "st:",
    searchFlagExample: "st:reject",
    useAsAdvancedFilter: true,
    useAsSuggestion: true,
    dropdownWidth: 160,
    useInSearchModes: [SearchMode.wip, SearchMode.all],
  },
];

export {};
