import { apiHostUrl, fetchData, getApiData, postApiData, ResponseDataType } from "../../shared/helpers/apiHelper";

const apiUrlBase = `${apiHostUrl}cmods/`;

//------------------------------------------------
// CMODS API calls
//------------------------------------------------
export const getGlobalDomains = (userClass: string = "C"): Promise<Object> =>
  getApiData(`${apiUrlBase}globaldomains/${userClass}`, "Fail to get global domains data.");

export const getMatchCode = (): Promise<Object> => getApiData(`${apiUrlBase}matchcode`, "Fail to get match code data.");

export const getTransmissions = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}transmissions`, "Fail to get Transmissions data.");

export const getChannelErrors = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}channelerrors`, "Fail to get Channel Errors data.");

export const getChannelErrorRow = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}channelerrorrow`, "Fail to get Channel Error Row data.");

export const getSellinErrors = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}sellinerrors`, "Fail to get Sellin Errors data.");

export const getSourceAndFiles = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}sourcefiles`, "Fail to get Source and Files data.");

export const getProducts = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}products`, "Fail to get Products data.");

export const getFileTemplate = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}filetemplate`, "Fail to get File Template data.");

export const getProHistory = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}prohistory`, "Fail to get PRO history data.");

export const getTransmissionMethodHistory = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}transmissionmethodhistory`, "Fail to get transmission method history data.");

export const getReportingFreqHistory = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}reportingfreqhistory`, "Fail to get reporting freq history data.");

export const getOrganizations = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}organizations`, "Fail to get organizations data.");

export const fixChannel = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}fixchannel`, "Fail to fix Channel Errors data.");

export const fixSellin = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}fixsellin`, "Fail to fix Sellin Errors data.");

export const fixErrors = (queryParams, userClass: string): Promise<Object> =>
  userClass === "C" ? fixChannel(queryParams) : fixSellin(queryParams);

export const updateSourceOrg = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}updatesourceorg`, "Fail to update Source Org data.");

export const fixTransmissionLevelError = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}fixtransmissionlevelerror`, "Fail to fix Transmission Level error.");

export const updateSourceFile = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}updatesourcefile`, "Fail to update Source File data.");

export const updateFileTemplate = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}updatefiletemplate`, "Fail to update File Template data.");

export const deleteFileTemplate = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}deletefiletemplate`, "Fail to delete File Template data.");

export const getFileTemplateSources = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}filetemplatesources`, "Fail to get File Template Sources data.");

export const checkCrossBorderAlloc = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}checkcrossborderalloc`, "Fail to check cross border allocation.");

export const purgeTransmission = (queryParams, signal?): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}purgetransmission`, "Fail to purge transmission.", undefined, { signal });

export const removeTransmission = (queryParams): Promise<Object> =>
  postApiData(queryParams, `${apiUrlBase}removetransmission`, "Fail to remove transmission.");

export const getExcelFile = (userClass: string, fileId: number): Promise<Object> =>
  fetchData(
    `${apiUrlBase}excelfile/${userClass}/${fileId}`,
    undefined,
    ResponseDataType.file,
    undefined,
    "Fail to download the Excel file."
  );

export const getRawDataFile = (userClass: string, fileId: number): Promise<Object> =>
  fetchData(
    `${apiUrlBase}rawdatafile/${userClass}/${fileId}`,
    undefined,
    ResponseDataType.file,
    undefined,
    "Fail to download the raw data file."
  );

export const getLogFile = (userClass: string, fileId: number): Promise<Object> =>
  fetchData(
    `${apiUrlBase}logfile/${userClass}/${fileId}`,
    undefined,
    ResponseDataType.file,
    undefined,
    "Fail to download the log file."
  );
