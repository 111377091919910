import React, { CSSProperties } from "react";
import { useResizeBarY } from "../../helpers/hooks";
import classNames from "./SearchFileContentApp.module.scss";

const defaultResizeBarPosX = 335;
const minResizeBarPosX = 1;
const maxResizeBarPosX = 600;

export interface ISearchFileContentAppProps {
  searchContent: JSX.Element;
  fileExplorer: JSX.Element;
  appContent: JSX.Element;
  style?: CSSProperties;
  className?: string;
}

export const SearchFileContentApp = (props: ISearchFileContentAppProps) => {
  const { searchContent, fileExplorer, appContent, style, className } = props;
  const { resizeBarPosX, onMouseDown, onMouseUp, onMouseMove } = useResizeBarY({
    defaultPosX: defaultResizeBarPosX,
    minPosX: minResizeBarPosX,
    maxPosX: maxResizeBarPosX,
  });

  return (
    <div className={`${classNames.root} ${className}`} style={style}>
      <div className={classNames.search}>
        <div className={classNames.searchContent}>{searchContent}</div>
      </div>
      <div className={classNames.main} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
        <div className={classNames.mainContent}>
          {fileExplorer && (
            <div className={classNames.fileExplorer} style={{ width: resizeBarPosX }}>
              {fileExplorer}
            </div>
          )}
          <div className={classNames.appContent} style={{ left: fileExplorer ? resizeBarPosX : 0 }}>
            {appContent}
          </div>
          {fileExplorer && (
            <div className={classNames.resizeBar} style={{ left: resizeBarPosX }} onMouseDown={onMouseDown} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchFileContentApp;
