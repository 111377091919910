import { DefaultButton, Spinner } from "@fluentui/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../app/App";
import { getTransmissionTypeName } from "../app/App.helper";
import { IFileTemplate } from "../app/App.types";
import { DataForm, FieldType, IField } from "../../shared/components/DataForm";
import { ItemDisplayView } from "../../shared/components/DataForm/ItemContainer";
import { AppContentContext } from "./AppContent";
import ConfigForm from "./ConfigForm";
import classNames from "./AppContent.module.scss";
import { useConfigChanges } from "./Config.hooks";
import { isShallowEqual } from "../../shared/helpers/miscHelper";
import { deleteFileTemplate, getFileTemplate, updateFileTemplate } from "../helpers/apiHelper";
import FileSelector from "./FileSelector";
import TemplateSelectorDialog from "./TemplateSelectorDialog";
import { processFileTemplateData } from "./AppContent.helper";
import ConfirmDialog from "../../shared/components/CommonDialog/ConfirmDialog";
import TemplateSourcesDialog from "./TemplateSourcesDialog";

export interface ITemplateConfigProps {
  fileTemplateData: IFileTemplate;
  loadingFileTemplate: boolean;
  onFileChange: (fileId: number) => void;
}

export const TemplateConfig = (props: ITemplateConfigProps) => {
  const { fileTemplateData, loadingFileTemplate, onFileChange } = props;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showTemplateSelector, setShowTemplateSelector] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showTemplateSourcesDialog, setShowTemplateSourcesDialog] = useState<boolean>(false);
  const [lastFileTemplateData, setLastFileTemplateData] = useState<object>();
  const { appState, onErrorChange, changeAppState } = useContext(AppContext);
  const { userClass, domains, creatingNewTemplate, selectedTemplate } = appState;
  const { selectedFile, updateFileTemplateInfo, updateFileTemplateData } = useContext(AppContentContext);
  const [configChanges, onFieldValueChange, onCancelChanges] = useConfigChanges();
  const templateInfo = useMemo(
    () => (fileTemplateData?.templateInfo?.length ? fileTemplateData.templateInfo[0] : {}),
    [fileTemplateData]
  );
  const fileTemplate = useMemo(
    () => ({ ...templateInfo, columns: sortColumns(fileTemplateData?.templateColumns) }),
    [templateInfo, fileTemplateData]
  );
  const isNewTemplate = fileTemplate.IsNew;
  const finalConfig = useMemo(() => ({ ...fileTemplate, ...configChanges }), [fileTemplate, configChanges]);
  const isDirty = !isShallowEqual(fileTemplate, finalConfig);
  const editingInTemplateMode = !isNewTemplate && !!selectedTemplate;

  useEffect(() => {
    changeAppState({ isDirty });
  }, [isDirty, changeAppState]);

  useEffect(() => {
    if (creatingNewTemplate) {
      setShowTemplateSelector(true);
    }
  }, [creatingNewTemplate]);

  const onChangeComplete = useCallback(() => {
    onCancelChanges();
    changeAppState({ creatingNewTemplate: false, error: undefined });
  }, [onCancelChanges, changeAppState]);

  const onSaveButtonClick = () => {
    if (creatingNewTemplate) {
      onConfirmSaving();
    } else {
      setShowConfirmDialog(true);
    }
  };

  const onConfirmSaving = useCallback(() => {
    setIsSaving(true);
    onErrorChange(undefined);
    setShowConfirmDialog(false);
    setTimeout(
      // Use timer delay to make sure saving spinner is displayed first.
      () =>
        updateFileTemplate(finalConfig)
          .then((result) => {
            let fileTemplateId =
              result && result["fileTemplate"]?.length && result["fileTemplate"][0]["FileTemplateID"];

            if (fileTemplateId) {
              finalConfig["FileTemplateID"] = fileTemplateId;

              if (isNewTemplate) {
                domains?.fileTemplates.push(finalConfig);
                changeAppState({ domains: { ...domains } });
              }
            }

            updateFileTemplateInfo(finalConfig);
            onCancelChanges();
          })
          .catch((error) => onErrorChange(error))
          .finally(() => {
            setIsSaving(false);
          }),
      100
    );
  }, [onErrorChange, updateFileTemplateInfo, onCancelChanges, changeAppState, domains, finalConfig, isNewTemplate]);

  const onCancelButtonClick = useCallback(() => {
    if (isNewTemplate) {
      if (lastFileTemplateData) {
        updateFileTemplateData(lastFileTemplateData);
      }
    }

    onChangeComplete();
  }, [isNewTemplate, lastFileTemplateData, updateFileTemplateData, onChangeComplete]);

  const onDeleteButtonClick = useCallback(() => {
    if (selectedTemplate) {
      deleteFileTemplate({ FileTemplateID: selectedTemplate.FileTemplateID })
        .then(() => {
          let deletedTemplateIndex = domains?.fileTemplates?.findIndex(
            (template) => template["FileTemplateID"] === selectedTemplate.FileTemplateID
          );
          domains?.fileTemplates?.splice(deletedTemplateIndex, 1);
          changeAppState({ selectedTemplate: undefined, domains: { ...domains } });
        })
        .catch((error) => onErrorChange(error));
    }
  }, [selectedTemplate, domains, changeAppState, onErrorChange]);

  const onTemplateUsersButtonClick = useCallback(() => {
    if (selectedTemplate) {
      setShowTemplateSourcesDialog(true);
    }
  }, [selectedTemplate]);

  // Handler for creating new template.
  const onTemplateSelect = useCallback(
    (transmissionTypeId, fileTemplateId) => {
      setLastFileTemplateData(fileTemplateData);
      onErrorChange(undefined);

      if (fileTemplateId) {
        getFileTemplate({ userClass, transmissionTypeId, fileTemplateId })
          .then((result: any) => {
            processFileTemplateData(result, userClass);
            updateFileTemplateData({ ...result, templateInfo: [{ ...result.templateInfo[0], IsNew: true }] });
          })
          .catch((error) => onErrorChange(error));
      } else {
        updateFileTemplateData({
          templateColumns: [],
          templateInfo: [{ IsNew: true, TransmissionTypeID: transmissionTypeId }],
        });
      }
    },
    [fileTemplateData, userClass, updateFileTemplateData, onErrorChange]
  );

  const onTemplateRowDelete = useCallback(
    (row) => {
      let columnId = row["ColumnID"],
        targetColumn = finalConfig?.columns?.find((column) => column["ColumnID"] === columnId);

      if (targetColumn) {
        let newColumns = finalConfig.columns
          .filter((column) => !isNewTemplate || column !== targetColumn)
          .map((column) => (column === targetColumn ? { ...column, RowDeleted: true } : column));

        onFieldValueChange("columns", newColumns);
      }
    },
    [isNewTemplate, finalConfig?.columns, onFieldValueChange]
  );

  let formTitle = "Template Configuration";

  if (selectedFile && !creatingNewTemplate) {
    formTitle += ` for File ${selectedFile["FileNameUniqueIdentifier"]}`;
  }

  let customButtons;

  if (editingInTemplateMode) {
    customButtons = (
      <DefaultButton text="Template Users" className={classNames.button} onClick={onTemplateUsersButtonClick} />
    );
  }

  let finalDisplayConfig = { ...finalConfig, columns: finalConfig?.columns?.filter((column) => !column["RowDeleted"]) };

  return (
    <>
      <ConfigForm
        title={formTitle}
        isDirty={isDirty || isNewTemplate}
        isSaving={isSaving}
        showDeleteButton={editingInTemplateMode}
        enableCancelButton={creatingNewTemplate}
        customButtons={customButtons}
        onSaveButtonClick={onSaveButtonClick}
        onCancelButtonClick={onCancelButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
      >
        {loadingFileTemplate && <Spinner label="Loading template data..." />}
        {!loadingFileTemplate && !isNewTemplate && !creatingNewTemplate && <FileSelector onFileChange={onFileChange} />}
        {!loadingFileTemplate &&
          ((!selectedTemplate && !selectedFile && !creatingNewTemplate) ||
          (!selectedTemplate && !isNewTemplate && !(fileTemplate && fileTemplate["FileTemplateID"])) ? (
            <div className={classNames.noContentPane}>No template is selected. Please select a target file first.</div>
          ) : (
            <DataForm
              context={finalDisplayConfig}
              fields={getFields(finalDisplayConfig, domains, isNewTemplate, onTemplateRowDelete)}
              onFieldValueChange={onFieldValueChange}
            />
          ))}
      </ConfigForm>
      <TemplateSelectorDialog
        hidden={!showTemplateSelector}
        onTemplateSelect={onTemplateSelect}
        onDismiss={() => setShowTemplateSelector(false)}
      />
      <ConfirmDialog
        headerText="Confirm Template Changes"
        hidden={!showConfirmDialog}
        onConfirm={onConfirmSaving}
        onDismiss={() => setShowConfirmDialog(false)}
      >
        Are you sure you want to alter this template? This will alter the template for EVERY account using this same
        template.
      </ConfirmDialog>
      <TemplateSourcesDialog
        hidden={!showTemplateSourcesDialog}
        fileTemplateId={selectedTemplate?.FileTemplateID}
        onDismiss={() => setShowTemplateSourcesDialog(false)}
      />
    </>
  );
};

export default TemplateConfig;

const getFields = (config, domains, isNewTemplate, onTemplateRowDelete): IField[] => {
  const transmissionType = getTransmissionTypeName(config["TransmissionTypeID"], domains);
  const rejectLevelOptions = domains?.rejectLevels?.map((r) => ({
    key: r["RejectLevelID"],
    text: r["RejectLevelCode"],
  }));

  rejectLevelOptions.unshift({ key: undefined, text: "" });

  const fieldOptions = domains?.transmissionTypeColumns
    ?.filter((r) => !config["TransmissionTypeID"] || config["TransmissionTypeID"] === r["TransmissionTypeID"])
    ?.map((c) => ({
      key: c["ColumnID"],
      text: c["ColumnName"],
    }));

  // Add more columns if they are missing from transmissionTypeColumns.
  config?.columns?.forEach((column) => {
    let fieldOption = fieldOptions?.find((option) => option.key === column["ColumnID"]);

    if (!fieldOption) {
      fieldOptions.push({ key: column["ColumnID"], text: column["ColumnName"] });
    }
  });

  return [
    {
      fieldName: "FileTemplateID",
      fieldType: FieldType.displayOnly,
      label: "ID",
      width: "60px",
      visible: !isNewTemplate,
    },
    {
      fieldName: "TransmissionType",
      fieldType: FieldType.displayOnly,
      label: "Transmission Type",
      value: transmissionType,
      visible: !!transmissionType,
      className: classNames.displayField,
    },
    {
      fieldName: "Description",
      fieldType: isNewTemplate ? FieldType.text : FieldType.displayOnly,
      label: "Name",
      lastInRow: true,
    },
    {
      fieldName: "FileTypeID",
      fieldType: FieldType.enumeration,
      label: "File Type",
      options: [
        { key: 1, text: "Fixed" },
        { key: 2, text: "Delimited" },
      ],
      placeholder: "",
      defaultValue: 1,
      width: 100,
    },
    {
      fieldName: "FileDelimiterASCIIValue",
      fieldType: FieldType.enumeration,
      label: "Delimiter",
      options: [
        { key: 44, text: "Comma" },
        { key: 124, text: "Pipe" },
        { key: 9, text: "Tab" },
      ],
      visible: config && config["FileTypeID"] === 2,
      placeholder: "",
      width: 100,
    },
    {
      fieldName: "columns",
      fieldType: FieldType.items,
      label: "Fields",
      itemDisplayView: ItemDisplayView.table,
      doNotAllowItemViewSwitch: true,
      itemTypeName: "Field",
      collapsed: false,
      fields: [
        {
          fieldType: FieldType.iconButton,
          width: 20,
          fieldProps: { iconProps: { iconName: "Delete" }, title: "Delete this template row" },
          onClick: (dataContext) => {
            if (dataContext?.context) {
              onTemplateRowDelete(dataContext?.context);
            }
          },
        },
        { fieldName: "Position", fieldType: FieldType.number, label: "Position", width: 50 },
        { fieldName: "Length", fieldType: FieldType.number, label: "Length", width: 50 },
        {
          fieldName: "ColumnID",
          fieldType: FieldType.enumeration,
          label: "Field",
          options: fieldOptions,
          placeholder: "",
          width: 240,
        },
        { fieldName: "RequiredFlag", fieldType: FieldType.boolean, label: "Req", width: 30 },
        {
          fieldName: "RequiredRejectLevelID",
          fieldType: FieldType.enumeration,
          label: "Action",
          options: rejectLevelOptions,
          placeholder: "",
          width: 140,
        },
        { fieldName: "Mask", label: "Mask", width: 120 },
        { fieldName: "ValueLessThan", fieldType: FieldType.number, label: "Less Than", width: 80 },
        { fieldName: "ValueGreaterThan", fieldType: FieldType.number, label: "Greater Than", width: 80 },
        {
          fieldName: "RuleRejectLevelID",
          fieldType: FieldType.enumeration,
          label: "Action",
          options: rejectLevelOptions,
          placeholder: "",
          width: 140,
        },
      ],
    },
  ];
};

const sortColumns = (columns: any[]): any[] =>
  columns?.sort((a, b) => (a["Position"] > b["Position"] ? 1 : a["Position"] < b["Position"] ? -1 : 0));
