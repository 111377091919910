export const getValueOrDefault = (value, defaultValue, convertFalseToString = false, parentDataContext = {}) => {
  if (typeof value === "function") {
    return value(parentDataContext);
  } else if (value === undefined || value === "") {
    return defaultValue;
  } else if (convertFalseToString && value === false) {
    return "false";
  } else {
    return value;
  }
};

export const evalCondition = (condition: string | boolean | ((arg) => boolean), object: object, arg?: any): boolean => {
  try {
    if (condition === undefined) return undefined;

    if (typeof condition === "boolean") {
      return condition === true;
    }
    if (typeof condition === "function") {
      return condition(arg);
    }
    // TODO: Evaluate is this the right way to do this if eslint flags it?
    // eslint-disable-next-line no-new-func
    return new Function("return `${" + condition + "}` === 'true';").call(object);
  } catch {
    return undefined;
  }
};

export const caseInsensitiveCompare = (a, b) => {
  return (
    typeof a === "string" && typeof b === "string" && a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
  );
};

/**
 * This is a special debounce that you call the first time to get a wrapped function and save that function.
 * Then when you want to call the actual debounced function you call that saved one.
 * This way it fully supports arguments.
 */
export const getDebounceMethod = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
