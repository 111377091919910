import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DataForm, FieldType, IDataContext, IField } from "../../shared/components/DataForm";
import ConfigForm from "./ConfigForm";
import classNames from "./AppContent.module.scss";
import { AppContentContext } from "./AppContent";
import { IDropdownOption, Spinner } from "@fluentui/react";
import { AppContext } from "../app/App";
import { IDomains } from "../app/App.types";
import { formatDate, isShallowEqual } from "../../shared/helpers/miscHelper";
import { updateSourceOrg } from "../helpers/apiHelper";
import { useConfigChanges } from "./Config.hooks";
import FileConfig from "./FileConfig";
import { ItemDisplayView } from "../../shared/components/DataForm/ItemContainer";
import ProHistoryDialog from "./ProHistoryDialog";
import { getBooleanFlagText } from "./AppContent.helper";
import OrgSearchDialog from "./OrgSearchDialog";
import { verifyStreamingFile } from "../helpers/miscHelper";
import { useWindowSize } from "../../shared/helpers/hooks";
import moment from "moment";

export interface ISourceFileConfigProps {
  loadingSourceFiles: boolean;
  onFileChange: (fileId: number) => void;
}

export const SourceFileConfig = (props: ISourceFileConfigProps) => {
  const { loadingSourceFiles, onFileChange } = props;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showProHistory, setShowProHistory] = useState<boolean>(false);
  const [showOrgSearch, setShowOrgSearch] = useState<boolean>(false);
  const [configChanges, onFieldValueChange, onCancelChanges] = useConfigChanges();
  const { appState, onErrorChange, changeAppState } = useContext(AppContext);
  const {
    userClass,
    selectedSource,
    selectedTransmission,
    domains,
    transmissions,
    creatingNewSource,
    searchPanelHeight,
  } = appState;
  const { selectedSourceFiles, updateSourceConfig, updateSelectedSourceFiles, resetAllSelections } =
    useContext(AppContentContext);
  const sourceConfig = selectedSourceFiles?.sources?.length && selectedSourceFiles.sources[0];
  const isNew = sourceConfig?.IsNew;
  const { revSumDivisions, sourceOrgInfo, sourceOrgParents } = selectedSourceFiles || {};
  const isMultiLevelText = getBooleanFlagText(sourceOrgInfo, "IsMultiLevel");
  const isProcessedByZymeText = getBooleanFlagText(sourceOrgInfo, "IsProcessedByZyme");
  const country = sourceConfig && domains?.countries.find((x) => x["CountryID"] === sourceConfig["CountryID"]);
  const countryName = country && country["Name"];
  const sourceOrgId = sourceConfig && sourceConfig["SourceOrgID"];
  const org = domains?.sourceOrganizations.find((x) => x["SourceOrgID"] === sourceOrgId);
  const subID = org && org["SubsidiaryID"];
  const sub = domains?.subsidiaries.find((x) => x["SubsidiaryID"] === subID);
  const subName = sub && sub["Name"];
  const flagString = getBooleanFlagText(sourceOrgInfo, "ActiveFlag");
  const globalParent = sourceOrgParents?.find((x) => x["ParentLevelId"] === 1);
  const topParent = sourceOrgParents?.find((x) => x["ParentLevelId"] === 2);
  const globalParentName = globalParent && globalParent["Name"];
  const globalParentId = globalParent && globalParent["OrganizationId"];
  const topParentName = topParent && topParent["Name"];
  const topParentId = topParent && topParent["OrganizationId"];
  const orgType =
    sourceConfig &&
    domains?.organizationTypes.find((o) => o["OrganizationTypeID"] === sourceConfig["OrganizationTypeID"]);
  const orgTypeName = orgType && orgType["Description"];
  const origConfig = useMemo(
    () => ({
      ...sourceConfig,
      revSumDivisions,
      sourceOrgInfo: {
        ...sourceOrgInfo,
        IsMultiLevelText: isMultiLevelText,
        IsProcessedByZymeText: isProcessedByZymeText,
      },
      GlobalName: selectedSource?.name || sourceConfig?.GlobalName,
      LocalName: (sourceOrgInfo?.length && sourceOrgInfo[0].LocalName) || sourceConfig?.LocalName,
      CountryName: countryName,
      ActiveFlag: flagString,
      SubsidiaryName: subName,
      TopParentId: topParentId,
      TopParentName: topParentName,
      GlobalParentId: globalParentId,
      GlobalParentName: globalParentName,
      OrganizationTypeName: orgTypeName,
    }),
    [
      sourceConfig,
      revSumDivisions,
      sourceOrgInfo,
      isMultiLevelText,
      isProcessedByZymeText,
      countryName,
      flagString,
      subName,
      topParentId,
      topParentName,
      globalParentId,
      globalParentName,
      orgTypeName,
      selectedSource?.name,
    ]
  );
  const finalConfig = useMemo(() => ({ ...origConfig, ...configChanges }), [origConfig, configChanges]);
  const isDirty = !isShallowEqual(origConfig, finalConfig);
  const isStreamingFile = verifyStreamingFile(selectedTransmission, selectedSource, transmissions);
  const windowSize = useWindowSize();
  const contentHeight = windowSize.height - searchPanelHeight - 200;

  useEffect(() => {
    changeAppState({ isDirty });
  }, [isDirty, changeAppState]);

  const onCancelButtonClick = useCallback(() => {
    if (isNew) {
      resetAllSelections();
    } else {
      onCancelChanges();
    }
    changeAppState({ creatingNewSource: false });
  }, [isNew, onCancelChanges, resetAllSelections, changeAppState]);

  const onSaveButtonClick = useCallback(() => {
    onErrorChange(undefined);
    setIsSaving(true);
    setTimeout(
      // Use timer delay to make sure saving spinner is displayed first.
      () =>
        updateSourceOrg(finalConfig)
          .then(() => {
            updateSourceConfig({ ...finalConfig, Name: finalConfig["GlobalName"], IsNew: false });
            onCancelChanges();
          })
          .catch((error) => onErrorChange(error))
          .finally(() => setIsSaving(false)),
      100
    );
  }, [finalConfig, onErrorChange, onCancelChanges, updateSourceConfig]);

  useEffect(() => {
    if (creatingNewSource) {
      setShowOrgSearch(true);
    }
  }, [creatingNewSource]);

  const onProHistoryButtonClick = useCallback(() => {
    setShowProHistory(true);
  }, []);

  const onOrgSelect = useCallback(
    (selectedOrg) => {
      let newSourceOrg = {
        ...selectedOrg,
        SourceOrgID: selectedOrg["OrganizationID"],
        IsNew: true,
      };
      setShowOrgSearch(false);
      updateSelectedSourceFiles({ sources: [newSourceOrg] });
    },
    [updateSelectedSourceFiles]
  );

  const onOrgSearchDismiss = useCallback(() => {
    setShowOrgSearch(false);
    changeAppState({ creatingNewSource: false });
  }, [changeAppState]);

  const handleFieldValueChange = (fieldName: string, newValue) => {
    if (newValue) {
      if (fieldName === "PROStartDate") {
        let newDate = new Date(newValue);
        newDate.setDate(1);
        newDate.setHours(0);
        newDate.setMinutes(0);
        newValue = moment(newDate).format("MMM DD YYYY HH:mm");
      } else if (fieldName === "PROEndDate") {
        let newDate = new Date(newValue);
        newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
        newDate.setHours(23);
        newDate.setMinutes(59);
        newValue = moment(newDate).format("MMM DD YYYY HH:mm");
      }
    }
    onFieldValueChange(fieldName, newValue);
  };

  let formTitle = "Source Configuration";

  if (selectedSource) {
    formTitle += ` for ${selectedSource.name}`;
  }

  return (
    <div className={classNames.fixedHeightContent} style={{ maxHeight: contentHeight }}>
      <ConfigForm
        title={formTitle}
        isDirty={isDirty || isNew}
        isSaving={isSaving}
        enableCancelButton={creatingNewSource}
        onSaveButtonClick={onSaveButtonClick}
        onCancelButtonClick={onCancelButtonClick}
      >
        {loadingSourceFiles && <Spinner label="Loading data..." />}
        {!loadingSourceFiles && (
          <DataForm
            context={finalConfig}
            fields={getSourceFields(domains, userClass, isNew, onProHistoryButtonClick)}
            onFieldValueChange={handleFieldValueChange}
          />
        )}
      </ConfigForm>
      {!isStreamingFile && <FileConfig loadingSourceFiles={loadingSourceFiles} onFileChange={onFileChange} />}
      <ProHistoryDialog sourceOrgId={sourceOrgId} hidden={!showProHistory} onDismiss={() => setShowProHistory(false)} />
      <OrgSearchDialog hidden={!showOrgSearch} onDismiss={onOrgSearchDismiss} onOrgSelect={onOrgSelect} />
    </div>
  );
};

export default SourceFileConfig;

const getSourceFields = (domains: IDomains, userClass: string, isNew: boolean, onProHistoryButtonClick): IField[] => {
  var isChannel = userClass === "C";

  return [
    {
      fieldType: FieldType.container,
      label: "General",
      collapsed: false,
      collapsible: true,
      className: classNames.fieldContainer,
      fields: [
        { fieldName: "SourceOrgID", fieldType: FieldType.displayOnly, label: "Source Org ID" },
        {
          fieldName: "ParentLevelId",
          fieldType: FieldType.displayOnly,
          label: "Parent Level ID",
          visible: "!!this.ParentLevelId",
        },
        { fieldName: "GlobalName", fieldType: FieldType.displayOnly, label: "Name" },
        {
          fieldName: "LocalName",
          fieldType: FieldType.displayOnly,
          label: "Local Name",
          visible: "!!this.LocalName",
        },
        {
          fieldName: "CountryName",
          fieldType: FieldType.displayOnly,
          label: "Country",
        },
        {
          fieldName: "SubsidiaryName",
          fieldType: FieldType.displayOnly,
          label: "Subsidiary Name",
          visible: "!!this.SubsidiaryName",
        },
        {
          fieldName: "ActiveFlag",
          fieldType: FieldType.displayOnly,
          label: "Active Flag",
        },
        {
          fieldName: "TopParentId",
          fieldType: FieldType.displayOnly,
          label: "Top Parent ID",
          visible: "!!this.TopParentId",
        },
        {
          fieldName: "TopParentName",
          fieldType: FieldType.displayOnly,
          label: "Top Parent Name",
          visible: "!!this.TopParentName",
        },
        {
          fieldName: "GlobalParentId",
          fieldType: FieldType.displayOnly,
          label: "Global Parent ID",
          visible: "!!this.GlobalParentId",
        },
        {
          fieldName: "GlobalParentName",
          fieldType: FieldType.displayOnly,
          label: "Global Parent Name",
          visible: "!!this.GlobalParentName",
        },
        {
          fieldName: "sourceOrgInfo",
          fieldType: FieldType.context,
          fields: [
            { fieldName: "IsMultiLevelText", label: "Is Multi-Level", fieldType: FieldType.displayOnly },
            { fieldName: "IsProcessedByZymeText", label: "Is Processed By Zyme", fieldType: FieldType.displayOnly },
          ],
        },
        {
          fieldName: "OrganizationTypeName",
          fieldType: FieldType.displayOnly,
          label: "Org Type",
          firstInRow: true,
        },
        {
          fieldName: "BuyingOrganizationTypeID",
          fieldType: FieldType.enumeration,
          label: "Buyer Type",
          options: addNoSelectionOption(
            domains?.organizationTypes?.map((i) => ({ key: i["OrganizationTypeID"], text: i["Description"] }))
          ),
          placeholder: "<N/A>",
          width: "160px",
        },
        {
          fieldName: "ChannelID",
          fieldType: FieldType.enumeration,
          label: "Channel",
          options: addNoSelectionOption(
            domains?.channels?.map((i) => ({ key: i["ChannelID"], text: i["Description"] }))
          ),
          placeholder: "<N/A>",
          width: "140px",
        },
        {
          fieldName: "TrustedSourceFlag",
          fieldType: FieldType.boolean,
          label: "Trusted",
        },
        {
          fieldName: "ApprovalRequiredFlag",
          fieldType: FieldType.boolean,
          label: "Approval Required",
        },
        {
          fieldName: "AutoParentToSource",
          fieldType: FieldType.boolean,
          label: "Auto Parent to Source",
          visible: isChannel,
        },
        {
          fieldName: "DefaultCurrencyCode",
          fieldType: FieldType.enumeration,
          label: "Currency",
          options: addNoSelectionOption(
            domains?.currencies?.map((i) => ({
              key: i["CurrencyCode"],
              text: `${i["CurrencyCode"]}-${i["CurrencyName"]}`,
            }))
          ),
          placeholder: "<N/A>",
          width: "280px",
        },
        {
          fieldName: "ContactID",
          fieldType: FieldType.enumeration,
          label: "Contact",
          options: addNoSelectionOption(
            domains?.contacts?.map((i) => ({
              key: i["ContactID"],
              text: `${i["FirstName"]} ${i["LastName"]}`,
            }))
          ),
          placeholder: "<N/A>",
          width: "180px",
          visible: isChannel,
        },
      ],
    },
    {
      fieldType: FieldType.container,
      label: "End Customer",
      collapsed: false,
      collapsible: true,
      className: classNames.fieldContainer,
      visible: isChannel,
      fields: [
        {
          fieldName: "EndUserGeographyID",
          fieldType: FieldType.enumeration,
          label: "Geography",
          options: addNoSelectionOption(
            domains?.endUserGeographies?.map((i) => ({
              key: i["EndUserGeographyID"],
              text: i["EndUserGeographyName"],
            }))
          ),
          placeholder: "<N/A>",
          width: "180px",
        },
        {
          fieldName: "PROFlag",
          fieldType: FieldType.boolean,
          label: "Partial Reporting Org",
        },
        {
          fieldName: "PROStartDate",
          fieldType: FieldType.month,
          label: "Start",
          width: 160,
          visible: "!!this.PROFlag",
        },
        {
          fieldName: "PROEndDate",
          fieldType: FieldType.month,
          label: "End",
          width: 160,
          visible: "!!this.PROFlag",
        },
        {
          fieldType: FieldType.button,
          value: "PRO History",
          onClick: onProHistoryButtonClick,
          width: 120,
          visible: !isNew,
        },
      ],
    },
    {
      fieldName: "revSumDivisions",
      fieldType: FieldType.items,
      label: "Product Specific Reporting",
      itemDisplayView: ItemDisplayView.table,
      doNotAllowNewItemCreation: true,
      doNotAllowItemViewSwitch: true,
      collapsible: true,
      collapsed: true,
      visible: `${isChannel} && ${!isNew} && !!this.revSumDivisions?.length`,
      width: 500,
      fields: [
        {
          fieldName: "RevSumDivisionName",
          label: "Rev Sum Division",
          fieldType: FieldType.displayOnly,
          width: 200,
        },
        {
          fieldName: "StartDate",
          label: "Start Date",
          fieldType: FieldType.displayOnly,
          value: (parentContext: IDataContext) => formatDate(parentContext?.context?.StartDate),
          width: 100,
        },
        {
          fieldName: "EndDate",
          label: "End Date",
          fieldType: FieldType.displayOnly,
          value: (parentContext: IDataContext) => formatDate(parentContext?.context?.EndDate),
          width: 100,
        },
      ],
    },
  ];
};

const addNoSelectionOption = (options: IDropdownOption[]): IDropdownOption[] => {
  options?.unshift({ key: null, text: "<N/A>" });
  return options;
};
