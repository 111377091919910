import { IColumn, IDialogProps } from "@fluentui/react";
import React from "react";
import { getTransmissionMethodHistory } from "../helpers/apiHelper";
import ItemListDialog from "./ItemListDialog";

export interface ITransMethodHistoryDialogProps extends IDialogProps {
  sourceFileId: number;
}

export const TransMethodHistoryDialog = (props: ITransMethodHistoryDialogProps) => {
  const { sourceFileId } = props;

  return (
    <ItemListDialog
      dialogTitle="Transmission Method History"
      dataTableName="transmissionMethodHistory"
      dataFieldName="sourceFileId"
      dataFieldValue={sourceFileId}
      getData={getTransmissionMethodHistory}
      columns={columns}
      {...props}
      minWidth={640}
    />
  );
};

export default TransMethodHistoryDialog;

const columns: IColumn[] = [
  {
    fieldName: "Method",
    key: "Method",
    name: "Method",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    fieldName: "StartDate",
    key: "StartDate",
    name: "Start Date",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    fieldName: "EndDate",
    key: "EndDate",
    name: "End Date",
    minWidth: 80,
    maxWidth: 80,
  },
  {
    fieldName: "UpdateDate",
    key: "UpdateDate",
    name: "Updated Date",
    minWidth: 120,
  },
  {
    fieldName: "UpdateBy",
    key: "UpdateBy",
    name: "Updated By",
    minWidth: 100,
    maxWidth: 100,
  },
];
