import React, { createContext, useCallback, useState } from "react";
import classNames from "./Portal.module.scss";
import PortalHeader from "./PortalHeader";
import { IMSSalesApp } from "./Portal.types";
import { msSalesApps } from "./Portal.apps";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

export const appFieldName = "appIndex";

export interface IPortalContext {
  selectedApp?: IMSSalesApp;
  userOptions?: object;
  portalError?: string;
  onAppChange?: (newApp: IMSSalesApp) => void;
  onUserOptionChange?: (propName: string, value: any) => void;
  onPortalErrorChange?: (error: string) => void;
  onShowUserCalloutChange?: (showCallout: boolean) => void;
}

export const PortalContext = createContext<IPortalContext>({});

export const Portal = () => {
  const defaultAppIndex = Math.min(localStorage[appFieldName] || 0, msSalesApps.length - 1);
  const defaultApp = msSalesApps[defaultAppIndex];
  const [userOptions, setUserOptions] = useState<object>(defaultApp.defaultUserOptions);
  const [selectedApp, setSelectedApp] = useState<IMSSalesApp>(defaultApp);
  const [portalError, setPortalError] = useState<string>();
  const [showUserCallout, setShowUserCallout] = useState<boolean>();

  const onAppChange = useCallback((newApp: IMSSalesApp) => {
    setUserOptions(newApp.defaultUserOptions);
    setSelectedApp(newApp);
  }, []);

  const onUserOptionChange = useCallback((propName: string, value: any) => {
    setUserOptions((userOptions) => ({ ...userOptions, [propName]: value }));
    setShowUserCallout(false);
  }, []);

  const onPortalErrorChange = useCallback((error: string) => {
    setPortalError(error);
  }, []);

  const onShowUserCalloutChange = useCallback((showCallout: boolean) => {
    setShowUserCallout(showCallout);
  }, []);

  return (
    <PortalContext.Provider
      value={{
        selectedApp,
        userOptions,
        portalError,
        onUserOptionChange,
        onPortalErrorChange,
        onAppChange,
        onShowUserCalloutChange,
      }}
    >
      <div className={classNames.root}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <PortalHeader showUserCallout={showUserCallout} onShowUserCalloutChange={onShowUserCalloutChange} />
          <div className={classNames.portalContent}>{selectedApp?.appComponent}</div>
        </MsalAuthenticationTemplate>
      </div>
    </PortalContext.Provider>
  );
};

export default Portal;
