import { DefaultButton, Dialog, DialogFooter, IDialogProps, PrimaryButton } from "@fluentui/react";

export interface IConfirmDialogProps extends IDialogProps {
  headerText?: string;
  subHeaderText?: string;
  onConfirm?: () => void;
  onDismiss?: () => void;
}

export const ConfirmDialog = (props: React.PropsWithChildren<IConfirmDialogProps>) => {
  const { headerText, subHeaderText, children, hidden = false, onDismiss, onConfirm } = props;

  return (
    <Dialog hidden={hidden} dialogContentProps={{ title: headerText, subText: subHeaderText }} onDismiss={onDismiss}>
      {children}
      <DialogFooter>
        <PrimaryButton onClick={onConfirm} text="Ok" />
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmDialog;
