import { Icon } from "@fluentui/react";
import classNames from "./MDCContent.module.scss";

export interface IAppContentHeaderProps {
  items: any[];
  className?: string;
}

export const AppContentHeader = (props: IAppContentHeaderProps) => {
  const { className, items } = props;

  return (
    <div className={className}>
      {items?.map((item, index) => {
        const headerItemClassNames = [classNames.headerItem];

        return (
          <div className={classNames.headerItemPane} key={`appContentHeader-${index}`}>
            {index > 0 && <Icon iconName="ChevronRight" className={classNames.headerChevron} />}
            <div className={headerItemClassNames.join(" ")} title={item.title}>
              {item.key && <div className={classNames.headerKey}>{item.key}</div>}
              <div className={classNames.headerTextPane}>
                {item.iconName && <Icon iconName={item.iconName} className={classNames.headerIcon} />}
                <span className={`${classNames.headerText} ${index === items.length - 1 && classNames.lastHeaderText}`}>
                  {item.text}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AppContentHeader;
