import { DefaultButton, Dialog, DialogFooter, DialogType, IColumn, IDialogProps, PrimaryButton } from "@fluentui/react";
import React from "react";
import { startCase } from "../../../shared/helpers/miscHelper";
import { DataForm, FieldType, IField } from "../../../shared/components/DataForm";
import classNames from "./ErrorCorrection.module.scss";

export interface IErrorCorrectionDialogProps extends IDialogProps {
  selectedErrorRecord?: object;
  targetColumnFieldName?: string;
  columns?: IColumn[];
  additionalFields?: IField[];
  isValueSelect?: boolean;
  onSelect?: () => void;
  onRecordFieldValueChange?: (fieldName: string, value: string | number) => void;
}

export const ErrorCorrectionDialog = (props: React.PropsWithChildren<IErrorCorrectionDialogProps>) => {
  const {
    onDismiss,
    onSelect,
    onRecordFieldValueChange,
    children,
    selectedErrorRecord,
    targetColumnFieldName,
    columns,
    additionalFields,
    isValueSelect,
  } = props;

  if (!selectedErrorRecord) {
    return null;
  }

  return (
    <Dialog
      dialogContentProps={{
        type: DialogType.normal,
        title: "Select Correction Value",
        closeButtonAriaLabel: "Close",
      }}
      minWidth={600}
      modalProps={{ isBlocking: true, className: classNames.errorDialog }}
      {...props}
    >
      <div className={classNames.recordInfoPane}>
        <DataForm
          context={selectedErrorRecord}
          fields={getRecordFields(selectedErrorRecord, columns, targetColumnFieldName, additionalFields)}
          onFieldValueChange={onRecordFieldValueChange}
        />
      </div>
      {children}
      <DialogFooter>
        <PrimaryButton text="Select" disabled={!isValueSelect} onClick={onSelect} />
        <DefaultButton text="Cancel" onClick={onDismiss} />
      </DialogFooter>
    </Dialog>
  );
};

export default ErrorCorrectionDialog;

const getRecordFields = (
  selectedErrorRecord: object,
  columns: IColumn[],
  targetColumnFieldName: string,
  additionalFields: IField[]
) => {
  const getField = (fieldName: string, className: string) => ({
    fieldName,
    fieldType: FieldType.displayOnly,
    label: startCase(fieldName),
    value: selectedErrorRecord[fieldName],
    className,
  });

  let fields = [],
    defaultTranslationTypeId = additionalFields?.length && additionalFields[0].defaultValue,
    productTranslationTypeId = selectedErrorRecord["ProductTranslationTypeID"] || defaultTranslationTypeId;

  additionalFields?.length && (fields = fields.concat(additionalFields));

  if (productTranslationTypeId === 1) {
    fields.push(getField("SourceProductID", classNames.targetColumnField));
  } else if (productTranslationTypeId === 2) {
    fields.push(getField("SourceSKU", classNames.targetColumnField));
  } else if (productTranslationTypeId === 3) {
    fields.push(getField("SourceUPC", classNames.targetColumnField));
  } else {
    fields.push(getField(targetColumnFieldName, classNames.targetColumnField));
  }

  if (!fields.find((field) => field.fieldName === targetColumnFieldName)) {
    fields.push(getField(targetColumnFieldName, undefined));
  }

  columns?.forEach((column) => {
    let fieldName = column.fieldName;
    if (!fields.find((field) => field.fieldName === fieldName) && selectedErrorRecord[fieldName]) {
      fields.push(getField(fieldName, classNames.columnField));
    }
  });

  return fields;
};
