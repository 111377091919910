import React, { useContext, useMemo } from "react";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import classNames from "./Overview.module.scss";
import { AppContext } from "../app/App";
import { formatCurrency, formatNumber } from "../../shared/helpers/miscHelper";

export const FileStatistics = () => {
  const { appState } = useContext(AppContext);
  const { transmissions, selectedTransmission, userClass } = appState;

  const statsItems = useMemo(
    () => getStatsItems(transmissions, selectedTransmission),
    [transmissions, selectedTransmission]
  );

  const statsColumns = useMemo(() => getStatsColumns(userClass), [userClass]);

  return (
    <div className={classNames.section}>
      <div className={classNames.header}>Statistics Breakdown</div>
      <DetailsList
        className={classNames.table}
        items={statsItems}
        columns={statsColumns}
        selectionMode={SelectionMode.none}
        compact={true}
      />
    </div>
  );
};

export default FileStatistics;

const getStatsItems = (transmissions, selectedTransmission) => {
  var statsItems = [];
  const transmission =
    transmissions?.allTransmissions?.find((item) => item["TransmissionID"] === selectedTransmission?.id) || {};

  statsItems.push({
    type: "Record",
    received: formatNumber(transmission["TotalRecordCount"]),
    complete: formatNumber(transmission["GoodRecordCount"]),
    stage: formatNumber(transmission["StageRecordCount"]),
    reject: formatNumber(transmission["RejectRecordCount"]),
    discard: formatNumber(transmission["DiscardRecordCount"]),
    unfixable: formatNumber(transmission["UnfixableRecordCount"]),
  });

  statsItems.push({
    type: "Quantity",
    received: formatNumber(transmission["TotalQtyNet"]),
    complete: formatNumber(transmission["GoodQtyNet"]),
    stage: formatNumber(transmission["StageQtyNet"]),
    reject: formatNumber(transmission["RejectQtyNet"]),
    discard: formatNumber(transmission["DiscardQtyNet"]),
    unfixable: formatNumber(transmission["UnfixableQtyNet"]),
  });

  statsItems.push({
    type: "Revenue",
    received: formatCurrency(transmission["TotalRevenue"]),
    complete: formatCurrency(transmission["GoodRevenue"]),
    stage: formatCurrency(transmission["StageRevenue"]),
    reject: formatCurrency(transmission["RejectRevenue"]),
    discard: formatCurrency(transmission["DiscardRevenue"]),
    unfixable: formatCurrency(transmission["UnfixableRevenue"]),
  });

  return statsItems;
};

const getStatsColumns = (userClass: "C" | "S"): IColumn[] => {
  var columns = [
    {
      fieldName: "type",
      key: "type",
      name: "Type",
      minWidth: 80,
      maxWidth: 100,
    },
    {
      fieldName: "received",
      key: "received",
      name: "Received",
      minWidth: 80,
      maxWidth: 100,
    },
    {
      fieldName: "complete",
      key: "complete",
      name: "Complete",
      minWidth: 80,
      maxWidth: 100,
    },
    {
      fieldName: "stage",
      key: "stage",
      name: "Stage",
      minWidth: 80,
      maxWidth: 100,
    },
    {
      fieldName: "reject",
      key: "reject",
      name: "Reject",
      minWidth: 80,
      maxWidth: 100,
    },
    {
      fieldName: "discard",
      key: "discard",
      name: "Discard",
      minWidth: 80,
      maxWidth: 100,
    },
  ];

  userClass === "C" &&
    columns.push({
      fieldName: "unfixable",
      key: "unfixable",
      name: "Unfixable",
      minWidth: 80,
      maxWidth: 100,
    });

  return columns;
};
