import React, { CSSProperties } from "react";
import classNames from "./Portal.module.scss";

export interface IHeaderTagsProps {
  items: string[];
  tagClassName?: string;
  tagStyle?: CSSProperties;
}

export const HeaderTags = (props: IHeaderTagsProps) => {
  const { items, tagClassName, tagStyle } = props;

  if (!items?.length) {
    return null;
  }

  return (
    <div className={classNames.headerTags}>
      {items.map((i, index) => (
        <div key={`headertags-${index}`} className={`${classNames.item} ${tagClassName}`} style={tagStyle}>
          {i}
        </div>
      ))}
    </div>
  );
};
