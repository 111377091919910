import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DataForm, FieldType, IField } from "../../shared/components/DataForm";
import ConfigForm from "./ConfigForm";
import classNames from "./AppContent.module.scss";
import { IDomains } from "../app/App.types";
import { AppContext } from "../app/App";
import { AppContentContext } from "./AppContent";
import { formatDate, isShallowEqual, sortList } from "../../shared/helpers/miscHelper";
import { Spinner } from "@fluentui/react";
import { updateSourceFile } from "../helpers/apiHelper";
import { useConfigChanges } from "./Config.hooks";
import { getTransmissionTypeName } from "../app/App.helper";
import { ItemDisplayView } from "../../shared/components/DataForm/ItemContainer";
import TransMethodHistoryDialog from "./TransMethodHistoryDialog";
import ReportingFreqHistoryDialog from "./ReportingFreqHistoryDialog";
import FileSelector from "./FileSelector";

export interface IFileConfigProps {
  loadingSourceFiles: boolean;
  onFileChange: (fileId: number) => void;
}

export const FileConfig = (props: IFileConfigProps) => {
  const { loadingSourceFiles, onFileChange } = props;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showTransMethodHistoryDialog, setShowTransMethodHistoryDialog] = useState<boolean>(false);
  const [showReportingFreqHistoryDialog, setShowReportingFreqHistoryDialog] = useState<boolean>(false);
  const [lastSelectedFile, setLastSelectedFile] = useState<object>();
  const { appState, onErrorChange, changeAppState } = useContext(AppContext);
  const { userClass, selectedSource, domains, selectedTransmission } = appState;
  const { selectedSourceFiles, selectedFile, updateSelectedFile } = useContext(AppContentContext);
  const [configChanges, onFieldValueChange, onCancelChanges] = useConfigChanges();
  const sourceFileId = selectedFile && selectedFile["SourceFileID"];
  const reportingDeadlines = selectedFile && getReportingDeadlines(selectedSourceFiles, selectedFile);
  const transmissionMethod =
    selectedFile &&
    domains?.transmissionMethods.find((m) => m["TransmissionMethodID"] === selectedFile["TransmissionMethodID"]);
  const transmissionMethodCode = transmissionMethod && transmissionMethod["TransmissionMethodCode"];
  const reportingFreq =
    selectedFile && domains?.reportingFreqs.find((f) => f["ReportingFreqID"] === selectedFile["ReportingFreqID"]);
  const reportingFreqDesc = reportingFreq && reportingFreq["Description"];
  const updatedConfig = useMemo(() => ({ ...selectedFile, ...configChanges }), [selectedFile, configChanges]);
  const isDirty = selectedFile && !isShallowEqual(selectedFile, updatedConfig);
  const sourceConfig = selectedSourceFiles?.sources?.length && selectedSourceFiles.sources[0];
  const sourceOrgId = sourceConfig?.SourceOrgID;
  const isNewSource = sourceConfig?.IsNew;
  const isNewFile = selectedFile && selectedFile["IsNew"];
  const pricingGroups = selectedSourceFiles?.pricingGroups;
  const priceListCountries = selectedSourceFiles?.priceListCountries;
  const currencies = selectedSourceFiles?.currencies;
  const finalConfig = useMemo(
    () => ({
      ...updatedConfig,
      pricingGroups,
      priceListCountries,
      currencies,
      reportingDeadlines,
      TransmissionMethodCode: transmissionMethodCode,
      TransmissionMethodStartDate: formatDate(selectedFile && selectedFile["TransmissionMethodStartDate"]),
      ReportingFreqDesc: reportingFreqDesc,
      ReportingStartDate: formatDate(selectedFile && selectedFile["ReportingStartDate"]),
    }),
    [
      updatedConfig,
      reportingDeadlines,
      transmissionMethodCode,
      selectedFile,
      reportingFreqDesc,
      pricingGroups,
      priceListCountries,
      currencies,
    ]
  );

  useEffect(() => {
    changeAppState({ isDirty });
  }, [isDirty, changeAppState]);

  const onSaveButtonClick = useCallback(() => {
    onErrorChange(undefined);
    setIsSaving(true);
    setTimeout(
      // Use timer delay to make sure saving spinner is displayed first.
      () =>
        updateSourceFile(finalConfig)
          .then(() => {
            let newFileConfig = !isNewFile
              ? finalConfig
              : {
                  ...finalConfig,
                  IsNew: false,
                  ReportingFreqID: finalConfig["NewReportingFreqID"],
                  ReportingStartDate: finalConfig["NewReportingStartDate"],
                  TransmissionMethodID: finalConfig["NewTransmissionMethodID"],
                };
            updateSelectedFile(newFileConfig);
            onCancelChanges();
          })
          .catch((error) => onErrorChange(error))
          .finally(() => setIsSaving(false)),
      100
    );
  }, [updateSelectedFile, onErrorChange, onCancelChanges, finalConfig, isNewFile]);

  const onCancelButtonClick = useCallback(() => {
    if (isNewFile) {
      if (lastSelectedFile) {
        updateSelectedFile(lastSelectedFile);
      } else if (selectedSourceFiles?.files?.length) {
        updateSelectedFile(selectedSourceFiles.files[0]);
      } else {
        updateSelectedFile(undefined);
      }
    }

    onCancelChanges();
  }, [isNewFile, lastSelectedFile, selectedSourceFiles, onCancelChanges, updateSelectedFile]);

  const onTransmissionMethodHistoryClick = useCallback(() => {
    setShowTransMethodHistoryDialog(true);
  }, []);

  const onReportingFreqHistoryClick = useCallback(() => {
    setShowReportingFreqHistoryDialog(true);
  }, []);

  const onNewButtonClick = useCallback(() => {
    setLastSelectedFile(selectedFile);
    updateSelectedFile({ SourceOrgID: sourceOrgId, IsNew: true });
  }, [selectedFile, sourceOrgId, updateSelectedFile]);

  const fileConfigFields = useMemo(
    () =>
      getfields(
        finalConfig,
        domains,
        userClass,
        isNewFile,
        onTransmissionMethodHistoryClick,
        onReportingFreqHistoryClick
      ),
    [finalConfig, domains, userClass, isNewFile, onTransmissionMethodHistoryClick, onReportingFreqHistoryClick]
  );

  let formTitle = "File Configuration";

  if (selectedTransmission) {
    formTitle += ` for ${selectedTransmission.name}`;
  } else if (selectedSource) {
    formTitle += ` for ${selectedSource.name}`;
  }

  if (isNewSource) {
    return null;
  }

  return (
    <>
      <ConfigForm
        title={formTitle}
        isDirty={isDirty || isNewFile}
        isSaving={isSaving}
        showNewButton={!isNewFile}
        onSaveButtonClick={onSaveButtonClick}
        onCancelButtonClick={onCancelButtonClick}
        onNewButtonClick={onNewButtonClick}
      >
        {loadingSourceFiles && <Spinner label="Loading data..." />}
        {!loadingSourceFiles && <FileSelector onFileChange={onFileChange} />}
        {!loadingSourceFiles && selectedFile && (
          <DataForm context={finalConfig} fields={fileConfigFields} onFieldValueChange={onFieldValueChange} />
        )}
        {!loadingSourceFiles && !selectedFile && (
          <div className={classNames.noContentPane}>No file info is found for the selected file.</div>
        )}
      </ConfigForm>
      <TransMethodHistoryDialog
        sourceFileId={sourceFileId}
        hidden={!showTransMethodHistoryDialog}
        onDismiss={() => setShowTransMethodHistoryDialog(false)}
      />
      <ReportingFreqHistoryDialog
        sourceFileId={sourceFileId}
        hidden={!showReportingFreqHistoryDialog}
        onDismiss={() => setShowReportingFreqHistoryDialog(false)}
      />
    </>
  );
};

export default FileConfig;

const getfields = (
  config,
  domains: IDomains,
  userClass: string,
  isNewFile: boolean,
  onTransmissionMethodHistoryClick,
  onReportingFreqHistoryClick
): IField[] => {
  const transmissionType = getTransmissionTypeName(config["TransmissionTypeID"], domains);
  var isChannel = userClass === "C";

  return [
    {
      fieldType: FieldType.container,
      label: "General",
      collapsed: false,
      collapsible: true,
      className: classNames.fieldContainer,
      fields: [
        { fieldName: "FileNameUniqueIdentifier", label: "Mnemonic", width: "100px" },
        {
          fieldName: "TransmissionTypeID",
          fieldType: isNewFile ? FieldType.enumeration : FieldType.displayOnly,
          label: "Transmission Type",
          value: isNewFile ? config["TransmissionTypeID"] : transmissionType,
          options: domains?.transmissionTypes?.map((t) => ({
            key: t["TransmissionTypeID"],
            text: t["TransmissionTypeCode"],
          })),
          placeholder: "",
        },
        {
          fieldName: "RejectThresholdPercent",
          fieldType: FieldType.number,
          label: "Reject Threshold %",
          valueWidth: "60px",
        },
        {
          fieldName: "FileTemplateID",
          fieldType: FieldType.comboBox,
          label: "Template",
          options: domains?.fileTemplates
            ?.filter((t) => t["TransmissionTypeID"] === config["TransmissionTypeID"])
            ?.sort(sortList("Description"))
            ?.map((t) => ({ key: t["FileTemplateID"], text: t["Description"] })),
          placeholder: "",
          valueWidth: 500,
          lastInRow: true,
        },
      ],
    },
    {
      fieldType: FieldType.container,
      label: "Reporting Frequency",
      collapsed: false,
      collapsible: true,
      className: classNames.fieldContainer,
      fields: [
        {
          fieldName: "ReportingFreqDesc",
          fieldType: FieldType.displayOnly,
          label: "Frequency",
          width: "100px",
          visible: !isNewFile,
        },
        {
          fieldName: "ReportingStartDate",
          fieldType: FieldType.displayOnly,
          label: "Start",
          width: "160px",
          visible: !isNewFile,
        },
        {
          fieldName: "ReportingEndDate",
          fieldType: FieldType.date,
          label: "End",
          width: "160px",
          visible: "this['SetReportingFreqEndDate']",
        },
        {
          fieldName: "SetReportingFreqEndDate",
          fieldType: FieldType.boolean,
          label: "Set End Date",
          visible: !isNewFile,
        },
        {
          fieldType: FieldType.button,
          label: "Reporting Freqeuncy",
          value: "History",
          onClick: onReportingFreqHistoryClick,
          visible: !isNewFile,
          lastInRow: true,
        },
        {
          fieldName: "NewReportingFreqID",
          fieldType: FieldType.enumeration,
          label: isNewFile ? "Frequency" : "New Frequency",
          options: domains?.reportingFreqs?.map((f) => ({ key: f["ReportingFreqID"], text: f["Description"] })),
          placeholder: "",
          visible: "this['SetReportingFreqEndDate'] || this['IsNew']",
          width: "100px",
        },
        {
          fieldName: "NewReportingStartDate",
          fieldType: FieldType.date,
          label: isNewFile ? "Start Date" : "New Start Date",
          width: "160px",
          visible: "this['SetReportingFreqEndDate'] || this['IsNew']",
        },
      ],
    },
    {
      fieldType: FieldType.container,
      label: "Transmission Method",
      collapsed: false,
      collapsible: true,
      className: classNames.fieldContainer,
      fields: [
        {
          fieldName: "TransmissionMethodCode",
          fieldType: FieldType.displayOnly,
          label: "Type",
          width: "200px",
          visible: !isNewFile,
        },
        {
          fieldName: "TransmissionMethodStartDate",
          fieldType: FieldType.displayOnly,
          label: "Start Date",
          width: "160px",
          visible: !isNewFile,
        },
        {
          fieldName: "TransmissionMethodEndDate",
          fieldType: FieldType.date,
          label: "End Date",
          width: "160px",
          visible: "this['SetTransmissionMethodEndDate']",
        },
        {
          fieldName: "SetTransmissionMethodEndDate",
          fieldType: FieldType.boolean,
          label: "Set End Date",
          visible: !isNewFile,
        },
        {
          fieldType: FieldType.button,
          label: "Transmission Method",
          value: "History",
          onClick: onTransmissionMethodHistoryClick,
          visible: !isNewFile,
          lastInRow: true,
        },
        {
          fieldName: "NewTransmissionMethodID",
          fieldType: FieldType.enumeration,
          label: isNewFile ? "Transmission Method" : "New Type",
          options: domains?.transmissionMethods?.map((m) => ({
            key: m["TransmissionMethodID"],
            text: m["TransmissionMethodCode"],
          })),
          visible: "this['SetTransmissionMethodEndDate'] || this['IsNew']",
          placeholder: "",
          width: "200px",
        },
        {
          fieldName: "NewTransmissionMethodStartDate",
          fieldType: FieldType.date,
          label: isNewFile ? "Start Date" : "New Start Date",
          width: "160px",
          visible: "this['SetTransmissionMethodEndDate']",
        },
      ],
    },
    {
      fieldType: FieldType.container,
      label: "Pricing Control",
      collapsed: false,
      collapsible: true,
      className: classNames.fieldContainer,
      visible: isChannel,
      fields: [
        {
          fieldName: "SuppressPricingFlag",
          fieldType: FieldType.boolean,
          label: "Override Price List",
          lastInRow: true,
        },
        {
          fieldName: "pricingGroups",
          fieldType: FieldType.items,
          label: "Price By Source Org Groups",
          itemDisplayView: ItemDisplayView.table,
          doNotAllowNewItemCreation: true,
          doNotAllowItemViewSwitch: true,
          visible: "!!this.pricingGroups?.length",
          collapsed: false,
          collapsible: false,
          compact: true,
          valueWidth: 540,
          lastInRow: true,
          fields: [
            {
              fieldName: "PriceList",
              label: "Price List",
              fieldType: FieldType.displayOnly,
              width: 120,
            },
            {
              fieldName: "CustomerType",
              label: "Customer Type",
              fieldType: FieldType.displayOnly,
              width: 100,
            },
            {
              fieldName: "PriceGroup",
              label: "Price Group",
              fieldType: FieldType.displayOnly,
            },
            {
              fieldName: "CurrencyCode",
              label: "Currency",
              fieldType: FieldType.displayOnly,
              width: 60,
            },
          ],
        },
        {
          fieldName: "PriceListCountries",
          fieldType: FieldType.sortedItems,
          label: "Price List",
          options: config?.priceListCountries?.map((c) => ({ key: c["PriceList"], text: c["PriceList"] })),
          value: config?.priceListCountries?.map((c) => c["PriceList"]).join(","),
          visible: "!this['SuppressPricingFlag']",
          fieldProps: { hideAction: true },
        },
        {
          fieldName: "CustomerTypeCodeSKID",
          fieldType: FieldType.sortedItems,
          label: "Customer Types",
          options: domains?.customerTypes?.map((c) => ({
            key: c["CustomerTypeCodeSKID"],
            text: `${c["CodeName"]} - ${c["CustomerTypeCodeSKID"]}`,
          })),
          visible: "!this['SuppressPricingFlag']",
          fieldProps: { hideListActions: true },
        },
        {
          fieldName: "PriceGroupID",
          fieldType: FieldType.sortedItems,
          label: "Price Groups",
          options: domains?.priceGroups?.map((c) => ({
            key: c["PriceGroupID"],
            text: `${c["PriceGroupDesc"]} - ${c["PriceGroupID"]}`,
          })),
          visible: "!this['SuppressPricingFlag']",
          fieldProps: { hideListActions: true },
        },
        {
          fieldName: "PriceListCurrencies",
          fieldType: FieldType.sortedItems,
          label: "Currency",
          options: config?.currencies?.map((c) => ({ key: c["CurrencyCode"], text: c["CurrencyCode"] })),
          value: config?.currencies?.map((c) => c["CurrencyCode"]).join(","),
          visible: "!this['SuppressPricingFlag']",
          fieldProps: { hideAction: true },
        },
      ],
    },
    {
      fieldType: FieldType.container,
      label: "Problem Notification",
      collapsed: false,
      collapsible: true,
      className: classNames.fieldContainer,
      fields: [
        {
          fieldName: "ProblemNotificationEMailAlias",
          label: "Alias",
        },
        {
          fieldName: "NotifyOnEmptyFlag",
          fieldType: FieldType.boolean,
          label: "Notify if file empty",
        },
        {
          fieldName: "NotifyOnSuccessFlag",
          fieldType: FieldType.boolean,
          label: "Notify if file Ok",
        },
        {
          fieldName: "NotifyOnRejectFlag",
          fieldType: FieldType.boolean,
          label: "Notify if file rejects",
        },
      ],
    },
    {
      fieldName: "reportingDeadlines",
      fieldType: FieldType.items,
      label: "Reporting Deadline",
      collapsed: true,
      collapsible: true,
      itemDisplayView: ItemDisplayView.table,
      className: classNames.fieldContainer,
      style: { marginTop: 10 },
      doNotAllowNewItemCreation: true,
      doNotAllowItemViewSwitch: true,
      width: "400px",
      fields: [
        {
          fieldName: "DayofWeekExpectedText",
          fieldType: FieldType.displayOnly,
          label: "Day (12AM)",
        },
        {
          fieldName: "DayExpected",
          fieldType: FieldType.number,
          label: "Grace (Days)",
          valueWidth: "60px",
        },
        {
          fieldName: "HourExpected",
          fieldType: FieldType.number,
          label: "Grace (Hours)",
          valueWidth: "60px",
        },
      ],
    },
  ];
};

const weekdays = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

const getReportingDeadlines = (selectedSourceFiles, fileConfig) => {
  var result = [];
  var deadlines = selectedSourceFiles?.freqSchedules?.filter((f) => f["SourceFileID"] === fileConfig["SourceFileID"]);

  for (let i = 0; i < weekdays.length; i++) {
    var weekday = { DayofWeekExpectedText: weekdays[i] };
    var deadline = deadlines?.find((d) => d["DayofWeekExpected"] === i);

    if (deadline) {
      weekday = { ...weekday, ...deadline };
    }

    result.push(weekday);
  }

  return result;
};
