import React, { useEffect, useState } from "react";
import AlertDialog, { IAlertDialogProps } from "../../shared/components/CommonDialog/AlertDialog";
import { getMatchCode } from "../helpers/apiHelper";
import { ConstrainMode, DetailsList, IColumn, SearchBox, SelectionMode, Spinner } from "@fluentui/react";
import classNames from "./App.module.scss";

export interface IMatchCodeLookupDialogProps extends IAlertDialogProps {}

export const MatchCodeLookupDialog = (props: IMatchCodeLookupDialogProps) => {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [matchCodeData, setMatchCodeData] = useState<any[]>();
  const [error, setError] = useState<string>();
  const [searchText, setSearchText] = useState<string>();

  useEffect(() => {
    setLoadingData(true);
    getMatchCode()
      .then((result: any) => result?.matchCode && setMatchCodeData(result.matchCode))
      .catch((error) => setError(error))
      .finally(() => setLoadingData(false));
  }, []);

  var items = matchCodeData;

  if (matchCodeData?.length && searchText) {
    items = matchCodeData.filter(
      (item) =>
        item["Subsidiary"]?.toLowerCase().indexOf(searchText) >= 0 ||
        item["Tier"]?.toLowerCase().indexOf(searchText) >= 0 ||
        item["Name"]?.toLowerCase().indexOf(searchText) >= 0 ||
        item["MatchColumn"]?.toLowerCase().indexOf(searchText) >= 0
    );
  }

  return (
    <AlertDialog {...props} headerText="Match Code Lookup" hidden={false} minWidth={880}>
      {loadingData && <Spinner label="Loading data..." />}
      {!loadingData && error && <div className={classNames.error}>{error}</div>}
      {!loadingData && !error && matchCodeData?.length && (
        <SearchBox
          className={classNames.searchBox}
          value={searchText}
          placeholder="Search match code here"
          onChange={(ev, text) => setSearchText(text?.toLowerCase())}
        />
      )}
      {!loadingData && !error && !!items?.length && (
        <div className={classNames.listPane} data-is-scrollable="true">
          <DetailsList
            compact={true}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained}
            columns={columns}
            items={items}
            className={classNames.matchCodeList}
          />
        </div>
      )}
      {!loadingData && !error && !!!items?.length && <div className={classNames.infoText}>No item is found.</div>}
    </AlertDialog>
  );
};

export default MatchCodeLookupDialog;

const columns: IColumn[] = [
  { key: "Subsidiary", fieldName: "Subsidiary", name: "Subsidiary", minWidth: 100 },
  { key: "Tier", fieldName: "Tier", name: "Tier", minWidth: 40 },
  { key: "Name", fieldName: "Name", name: "Name", minWidth: 100 },
  { key: "MatchColumn", fieldName: "MatchColumn", name: "Match Column", minWidth: 140 },
  { key: "Length", fieldName: "Length", name: "Length", minWidth: 60 },
  { key: "Required", fieldName: "Required", name: "Required", minWidth: 60 },
  { key: "Removenonalpha", fieldName: "Removenonalpha", name: "Remove Non-Alpha", minWidth: 160 },
];
