import { Icon } from "@fluentui/react";
import React, { useContext } from "react";
import { AppContext } from "../app/App";
import { IHeaderItem } from "../app/App.types";
import classNames from "./AppContent.module.scss";
import { SearchMode } from "../app/App.helper";

export interface IAppContentHeaderProps {
  items: IHeaderItem[];
  className?: string;
}

export const AppContentHeader = (props: IAppContentHeaderProps) => {
  const { className, items } = props;
  const { appState, changeAppState } = useContext(AppContext);
  const { selectedMode } = appState;

  const onHeaderItemClick = (itemIndex: number) => {
    if (itemIndex === 0 && selectedMode !== SearchMode.source && selectedMode !== SearchMode.template) {
      changeAppState({ selectedSource: undefined, selectedTransmission: undefined });
    } else if (itemIndex === 1) {
      changeAppState({ selectedTransmission: undefined });
    }
  };

  return (
    <div className={className}>
      {items?.map((item, index) => {
        const headerTextPaneClassNames = [classNames.headerTextPane];

        if (index !== items.length - 1) {
          headerTextPaneClassNames.push(classNames.selectable);
        }

        return (
          <div className={classNames.headerItemPane} key={`appContentHeader-${index}`}>
            {index > 0 && <Icon iconName="ChevronRight" className={classNames.headerChevron} />}
            <div className={classNames.headerItem} title={item.title}>
              {item.key && <div className={classNames.headerKey}>{item.key}</div>}
              <div className={headerTextPaneClassNames.join(" ")} onClick={() => onHeaderItemClick(index)}>
                {item.iconName && <Icon iconName={item.iconName} className={classNames.headerIcon} />}
                <span className={`${classNames.headerText} ${index === items.length - 1 && classNames.lastHeaderText}`}>
                  {item.text}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AppContentHeader;
