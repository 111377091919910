import React, { useEffect, createContext, useCallback, useContext, useReducer } from "react";
import AppSearch from "../search/AppSearch";
import AppContent from "../contents/AppContent";
import FileExplorer from "./FileExplorer";
import { ISelectedSource, ISelectedTransmission, IFilterItem, IDomains, ITransmissions, ITemplate } from "./App.types";
import { getGlobalDomains, getTransmissions } from "../helpers/apiHelper";
import {
  channelFilterDefinitions,
  FilterType,
  IFilterDefinition,
  sellinFilterDefinitions,
} from "../search/AppSearch.helper";
import { useIsAuthenticated } from "@azure/msal-react";
import { getTransmissionsBySource, getTransmissionsQueryParams, SearchMode } from "./App.helper";
import { PortalContext } from "../../portal/Portal";
import { showLogsOptionName, userClassOptionName } from "./UserOptions.helper";
import { IApiError } from "../../shared/helpers/apiHelper";
import SearchFileContentApp from "../../shared/components/SearchFileContentApp/SearchFileContentApp";
import { ICommandBarProps } from "@fluentui/react";
import classNames from "./App.module.scss";
import LogsPanel from "./LogsPanel";
import { useResizeBarY, useWindowSize } from "../../shared/helpers/hooks";
import { getFilterOptions } from "../search/SearchFilters";

export interface IAppContext {
  appState?: IAppState;
  onErrorChange?: (newError) => void;
  onTransmissionUpdate?: (transmissionId: number, newTransmission?: any) => void;
  updateSelectedSource?: (source: ISelectedSource) => void;
  updateSelectedTransmission?: (transmission: ISelectedTransmission) => void;
  reloadTransmissions?: () => void;
  changeAppState?: (newState) => void;
}

export const AppContext = createContext<IAppContext>({});

export interface IAppState {
  userClass?: "C" | "S";
  domains?: IDomains;
  transmissions?: ITransmissions;
  sources?: ISelectedSource[];
  templates?: ITemplate[];
  selectedSource?: ISelectedSource;
  selectedTransmission?: ISelectedTransmission;
  selectedTemplate?: ITemplate;
  error?: IApiError;
  filterDefinitions?: IFilterDefinition[];
  filterItems?: IFilterItem[];
  loadingDomains?: boolean;
  loadingTransmissions?: boolean;
  creatingNewSource?: boolean;
  creatingNewTemplate?: boolean;
  selectedMode?: SearchMode;
  isDirty?: boolean;
  searchPanelHeight?: number;
}

const defaultAppState = {
  selectedSource: undefined,
  selectedTransmission: undefined,
  selectedTemplate: undefined,
  error: undefined,
  domains: undefined,
  transmissions: undefined,
  sources: undefined,
  templates: undefined,
  filterItems: [],
  loadingDomains: false,
  loadingTransmissions: false,
  creatingNewSource: false,
  creatingNewTemplate: false,
  selectedMode: SearchMode.wip,
  isDirty: false,
};

export const App = () => {
  const { userOptions, onUserOptionChange } = useContext(PortalContext);
  const userClass = (userOptions && userOptions[userClassOptionName]) || "C";
  const showLogsPanel = (userOptions && userOptions[showLogsOptionName]) || false;
  const [appState, setAppState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    ...defaultAppState,
    userClass,
  });
  const { domains, transmissions, loadingTransmissions, filterItems, selectedMode, filterDefinitions } = appState;
  const isAuthenticated = useIsAuthenticated();
  const hideMainFeatures = appState.creatingNewSource || appState.creatingNewTemplate;
  const { width: winWidth } = useWindowSize();
  const { resizeBarPosX, onMouseDown, onMouseUp, onMouseMove } = useResizeBarY({
    defaultPosX: winWidth - 300,
    minPosX: 600,
    maxPosX: winWidth,
  });

  // Reset app state if userClass gets changed.
  useEffect(() => {
    if (isAuthenticated) {
      const filterDefinitions = userClass === "S" ? sellinFilterDefinitions : channelFilterDefinitions;
      setAppState({ ...defaultAppState, userClass, loadingDomains: true, filterDefinitions });
      getGlobalDomains(userClass)
        .then((data: any) => setAppState({ domains: data }))
        .catch((error) => setAppState({ error }))
        .finally(() => setAppState({ loadingDomains: false }));
    }
  }, [isAuthenticated, userClass]);

  // If only one source org is returned for all transmissions, select that specific source org by default.
  useEffect(() => {
    const transmissionsBySource = getTransmissionsBySource(transmissions, domains),
      sourceDictIds = Object.keys(transmissionsBySource);

    if (sourceDictIds.length === 1) {
      var source = transmissionsBySource[sourceDictIds[0]];
      setAppState({ selectedSource: { id: source.sourceOrgId, name: source.sourceOrgName } });
    }
  }, [transmissions, domains]);

  const onNewSourceItemClick = useCallback(() => {
    setAppState({ creatingNewSource: true, selectedSource: undefined, selectedTransmission: undefined });
  }, []);

  const onNewTemplateItemClick = useCallback(() => {
    setAppState({ creatingNewTemplate: true });
  }, []);

  const onTransmissionUpdate = useCallback(
    (transmissionId, newTransmission?) => {
      const targetIndex = transmissions?.allTransmissions.findIndex((t) => t.TransmissionID === transmissionId);

      if (newTransmission) {
        transmissions?.allTransmissions.splice(targetIndex, 1, newTransmission);
      } else {
        transmissions?.allTransmissions.splice(targetIndex, 1);
      }

      setAppState({
        transmissions: { ...transmissions },
        error: undefined,
        selectedTransmission: newTransmission ? appState.selectedTransmission : undefined,
        selectedSource: newTransmission ? appState.selectedSource : undefined,
      });
    },
    [transmissions, appState.selectedTransmission, appState.selectedSource]
  );

  // useCallback is needed because this function is used as context value in AppContext.
  const onErrorChange = useCallback((newError) => {
    setAppState({ error: newError });
  }, []);

  const loadTransmissions = useCallback(
    (items: IFilterItem[] = filterItems) => {
      setAppState({
        error: undefined,
        transmissions: undefined,
        sources: undefined,
        templates: undefined,
        selectedTransmission: undefined,
        selectedSource: undefined,
        loadingTransmissions: true,
      });
      getTransmissions(getTransmissionsQueryParams(items, userClass))
        .then((result: any) => {
          if (result.allTransmissions) {
            // One or more transmissions are found.
            if (result.allTransmissions?.length) {
              setAppState({ transmissions: result });
            } else {
              // If source filter is defined, select that source item by default.
              let sourceFilter = items?.find((fi) => fi.filterType === FilterType.source);

              if (sourceFilter?.filterValues?.length) {
                setAppState({
                  selectedSource: {
                    id: Number(sourceFilter.filterValues[0].key),
                    name: sourceFilter.filterValues[0].text,
                  },
                });
              }
            }
          } else if (result.sourceOrgInfo?.length) {
            // If source org info is found, select that source item by default.
            setAppState({
              selectedSource: {
                id: result.sourceOrgInfo[0].SourceOrgID,
                name: result.sourceOrgInfo[0].Name,
              },
            });
          }
        })
        .catch((error) => setAppState({ error }))
        .finally(() => setAppState({ loadingTransmissions: false }));
    },
    [userClass, filterItems]
  );

  const loadSources = useCallback(
    (newFilterItems: IFilterItem[] = filterItems) => {
      let filterDef = filterDefinitions?.find((filter) => filter.filterType === FilterType.source),
        sources = getFilterOptions(domains, newFilterItems, filterDef)
          ?.filter((option) => option.key)
          ?.map((option) => ({
            id: option.key,
            name: option.text,
            inactive: option["inactive"],
          }));

      setAppState({
        error: undefined,
        transmissions: undefined,
        sources,
        templates: undefined,
        selectedTransmission: undefined,
        selectedSource: undefined,
        selectedTemplate: undefined,
      });
    },
    [filterDefinitions, domains, filterItems]
  );

  const loadTemplates = useCallback(
    (newFilterItems: IFilterItem[] = filterItems) => {
      let templates = domains?.fileTemplates,
        fileTypeFilter = newFilterItems?.find((filterItem) => filterItem.filterType === FilterType.fileType);

      if (templates?.length && fileTypeFilter?.filterValues?.length) {
        templates = templates.filter((template) =>
          fileTypeFilter.filterValues.find((value) => value.key === template.TransmissionTypeID.toString())
        );
      }

      templates.sort((a, b) =>
        a.Description?.toLowerCase() > b.Description?.toLowerCase()
          ? 1
          : a.Description?.toLowerCase() < b.Description?.toLowerCase()
          ? -1
          : 0
      );

      setAppState({
        error: undefined,
        transmissions: undefined,
        sources: undefined,
        templates,
        selectedTransmission: undefined,
        selectedSource: undefined,
        selectedTemplate: undefined,
      });
    },
    [domains, filterItems]
  );

  const reloadTransmissions = useCallback(() => {
    loadTransmissions(filterItems);
  }, [loadTransmissions, filterItems]);

  const onSearchChange = useCallback(
    (newFilterItems: IFilterItem[]) => {
      setAppState({ filterItems: newFilterItems });

      if (selectedMode === SearchMode.source) {
        loadSources(newFilterItems);
      } else if (selectedMode === SearchMode.template) {
        loadTemplates(newFilterItems);
      } else {
        loadTransmissions(newFilterItems);
      }
    },
    [selectedMode, loadTransmissions, loadSources, loadTemplates]
  );

  const updateSelectedSource = useCallback(
    (newSelectedSource) => setAppState({ selectedSource: newSelectedSource }),
    []
  );

  const updateSelectedTransmission = useCallback(
    (newSelectedTransmission) => setAppState({ selectedTransmission: newSelectedTransmission }),
    []
  );

  const changeAppState = useCallback((newState) => setAppState(newState), []);

  const commandBarProps: ICommandBarProps = {
    items: [],
    overflowItems: [
      { key: "newSource", name: "New Source", iconProps: { iconName: "PageAdd" }, onClick: onNewSourceItemClick },
      {
        key: "newTemplate",
        name: "New Template",
        iconProps: { iconName: "PageAdd" },
        onClick: onNewTemplateItemClick,
      },
    ],
    styles: {
      root: {
        padding: 0,
      },
    },
  };

  return (
    <AppContext.Provider
      value={{
        appState,
        onErrorChange,
        onTransmissionUpdate,
        updateSelectedSource,
        updateSelectedTransmission,
        reloadTransmissions,
        changeAppState,
      }}
    >
      <div className={classNames.appRoot} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
        <SearchFileContentApp
          className={classNames.content}
          style={{ width: showLogsPanel ? resizeBarPosX : "100%" }}
          searchContent={
            <AppSearch
              onSearchChange={onSearchChange}
              commandBarProps={commandBarProps}
              hideSearch={hideMainFeatures}
            />
          }
          fileExplorer={
            !hideMainFeatures && <FileExplorer transmissions={transmissions} loading={loadingTransmissions} />
          }
          appContent={<AppContent />}
        />
        {showLogsPanel && (
          <LogsPanel
            className={classNames.logsPanel}
            style={{ left: resizeBarPosX }}
            onDismiss={() => onUserOptionChange(showLogsOptionName, false)}
          />
        )}
        {showLogsPanel && (
          <div className={classNames.resizeBar} style={{ left: resizeBarPosX }} onMouseDown={onMouseDown} />
        )}
      </div>
    </AppContext.Provider>
  );
};

export default App;
