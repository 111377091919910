export const startCase = (text: string): string => {
  let result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1).replace(/([A-Z])\s(?=[A-Z])/g, "$1");
};

export const getIsoDateString = (date: Date): string => {
  date?.setHours(0, 0, 0, 0);
  return date?.toISOString().substring(0, 10);
};

export const isShallowEqual = (object1, object2): boolean => {
  const keys1 = object1 && Object.keys(object1);
  const keys2 = object2 && Object.keys(object2);
  if (keys1?.length !== keys2?.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1 && object2 && object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

export const sortList =
  (sortFieldName: string = "name") =>
  (a, b) =>
    a[sortFieldName].toLowerCase() < b[sortFieldName].toLowerCase()
      ? -1
      : a[sortFieldName].toLowerCase() > b[sortFieldName].toLowerCase()
      ? 1
      : 0;

export const formatCurrency = (value, itemLocale = "en-us", itemCurrency = "USD", itemDecimal = 2) =>
  Number(value).toLocaleString(itemLocale, {
    style: "currency",
    currency: itemCurrency,
    minimumFractionDigits: itemDecimal,
    maximumFractionDigits: itemDecimal,
  });

export const formatNumber = (value, itemLocale = "en-us", itemDecimal = 2) =>
  Number(Number(value).toFixed(itemDecimal)).toLocaleString(itemLocale);

export const formatDate = (value, itemLocale = "en-us") => value && new Date(value).toLocaleDateString(itemLocale);

export const formatDateTime = (value, itemLocale = "en-us") => value && new Date(value).toLocaleString(itemLocale);

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
};
