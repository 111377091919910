import React from "react";
import DateRangeCalloutContent, { IDateRangeCalloutContentProps } from "./DateRangeCalloutContent";
import { ICalloutButtonProps, IconCalloutButton } from "../CalloutButton/IconCalloutButton";

export interface IDateRangePickerProps extends ICalloutButtonProps, IDateRangeCalloutContentProps {}

export const DateRangePicker = (props: React.PropsWithChildren<IDateRangePickerProps>) => {
  return (
    <IconCalloutButton iconName="Calendar" title="Select date range" {...props}>
      <>
        {props.children}
        <DateRangeCalloutContent {...props} />
      </>
    </IconCalloutButton>
  );
};
