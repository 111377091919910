import { DefaultButton, Dialog, DialogFooter, IDialogProps, PrimaryButton, TextField } from "@fluentui/react";
import { useState } from "react";

export interface IPurgeTransmissionDialogProps extends IDialogProps {
  headerText?: string;
  onCommit: (description: string) => void;
  onDismiss: () => void;
}

export const PurgeTransmissionDialog = (props: IPurgeTransmissionDialogProps) => {
  const { onCommit, onDismiss, headerText = "Purge Transmission" } = props;
  const [description, setDescription] = useState<string>();

  const onCommitClick = () => {
    onCommit(description);
    onDismiss();
  };

  const onTextKeyDown = (ev: React.KeyboardEvent) => {
    if (description && ev.key === "Enter") {
      onCommitClick();
    }
  };

  return (
    <Dialog
      {...props}
      dialogContentProps={{ title: headerText, subText: "Please enter reason for purging." }}
      minWidth={640}
    >
      <TextField onChange={(e, newValue) => setDescription(newValue)} onKeyDown={onTextKeyDown} />
      <DialogFooter>
        <PrimaryButton onClick={onCommitClick} text="Ok" disabled={!description?.length} />
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default PurgeTransmissionDialog;
